
import { User } from '@/store/user'
import { Component, Prop, Vue } from 'vue-property-decorator'

import Datepicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import 'vue2-datepicker/locale/ru'

import WPInputLabel from './WPInputLabel.vue'

@Component({
  components: {
    Datepicker,
    WPInputLabel,
  },
})
export default class WPDatetimepicker extends Vue {
  @Prop({ default: null })
  public readonly value?: string | string[] | null

  @Prop({ default: false })
  public readonly disabled?: boolean

  private get computedValue() {
    return this.value
  }

  private set computedValue(value) {
    this.$emit('input', value)
  }

  private get lang() {
    return User.settings.language === 'ru-ru'
      ? {
          lang: 'ru',
          title: 'DD.MM.YYYY',
          format: 'DD.MM.YYYY HH:mm:ss',
        }
      : {
          lang: 'en',
          title: 'YYYY-MM-DD',
          format: 'MM-DD-YYYY HH:mm:ss',
        }
  }
}
