
import { Component, Vue } from 'vue-property-decorator'
import { WPCard, WPButtonMenu, WPButtonIcon, WPTextfield } from '@/components'
import { Field } from '@/store/field'

@Component({
  components: {
    WPCard,
    WPButtonMenu,
    WPButtonIcon,
    WPTextfield,
  },
})
export default class CalculatorsEmpty extends Vue {
  private get wells() {
    return !!Field.wellsFull.length
  }

  private cancel() {
    void this.$router.push({
      name: 'Calculators',
    })
  }

  private toFields() {
    void this.$router.push({
      name: 'Fields',
    })
  }

  private async mounted() {
    await Field.GET_WELLS_FULL({})
    if (this.wells)
      void this.$router.push({
        name: 'Calculators',
      })
  }
}
