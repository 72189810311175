import { eventBus } from '@/helpers/eventBus'
import { Calculator } from '@/store/calculators'
import { Field } from '@/store/field'
import { User } from '@/store/user'
import { ICalculatorShared, TChartResults } from '@/types'
import { Component, Vue } from 'vue-property-decorator'

@Component
class CalcResultActionsMixin extends Vue {
  public chartLoading = false

  public reportLoading = false

  public shareLoading = false

  public calcMeasure = 0

  public calcCode = 0

  public chart: TChartResults | null = null

  public id: string | number | null = null

  public get calcData() {
    const loaded = Calculator.calculationsLoaded.find(
      (item) => Number(item.id) === Number(this.id)
    )
    return loaded ? loaded : null
  }

  public get code() {
    return this.calcData?.code || 0
  }

  public get chartAllowedCalcs() {
    return [2].includes(this.code)
  }

  public get vizualizationAllowedCalcs() {
    return [2].includes(this.code)
  }

  private get isShared() {
    return this.calcData
      ? this.calcData.shared
      : this.id
      ? Calculator.calculations.find((item) => item.id === this.id)?.shared
      : false
  }

  private async setShared() {
    this.shareLoading = true
    if (this.calcData) {
      await Calculator.TOGGLE_LOADED_SHARED({
        ...this.calcData,
        shared: !this.isShared,
      })
    } else if (this.id) {
      const data = { id: this.id } as ICalculatorShared
      await Calculator.TOGGLE_SHARED({
        ...data,
        shared: !this.isShared,
      })
    }
    this.shareLoading = false
  }

  public async downloadReport() {
    const acceptedCodes = [1, 2]
    this.reportLoading = true
    const code = this.calcData ? Number(this.calcData?.code) : this.calcCode
    if (acceptedCodes.includes(code)) {
      const id = Number(this.id)
      const file = await Calculator.GET_REPORT_FILE(id)
      if (file) {
        const url = window.URL.createObjectURL(new Blob([file]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute(
          'download',
          String(this.$t('calculatorsLayout.fileName'))
        )
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }
    } else {
      eventBus.$emit('showError', 'calcReportNotSupported')
    }
    this.reportLoading = false
  }

  public showScheme() {
    const routeData = this.$router.resolve({
      name: 'TheWellboreVisualizationGradient',
      query: {
        calculation: String(this.id),
        units: this.calcData
          ? String(this.calcData?.measure_type)
          : String(this.calcMeasure),
      },
    })
    window.open(routeData.href, '_blank')
  }
}

export default CalcResultActionsMixin
