
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'

@Component
export default class WPExpPanel extends Vue {
  @Prop({ default: '' })
  private readonly title?: string

  @Prop({ default: false })
  private readonly opened?: boolean

  @Prop({ default: false })
  private readonly large?: boolean

  @Prop({ default: false })
  private readonly nopad?: boolean

  @Prop({ default: false })
  private readonly disabled?: boolean

  @Prop({ default: false })
  private readonly alwaysActive?: boolean

  private isOpened: number | undefined | null = null

  private onClick() {
    if (!this.alwaysActive) this.$emit('click')
  }

  @Watch('opened', { immediate: true })
  private onChangeState(val: boolean) {
    if (this.alwaysActive) {
      this.isOpened = 0
      return
    } else {
      this.$nextTick(() => {
        this.isOpened = val ? 0 : undefined
      })
    }
  }

  private mounted() {
    if (this.opened) this.isOpened = 0
  }
}
