
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import {
  WPLayoutBorder,
  WPButtonIcon,
  WPRadioGroup,
  WPCheckbox,
  WPGroupMultiline,
  WPSwitch,
  WPButton,
  WPRadioSwitch,
  WPExpPanel,
  WPTextfieldMeasure,
  WPTextfield,
  WPDivider,
  WPBlockHint,
  WPCombobox,
} from '@/components'

import { Wellbore } from '@/store/wellbore'

import {
  IEquipConnection,
  TLockTypes,
  TCarvingTypes,
  TCustomConnectionTypes,
  TProfileShapes,
  ICustomConnectionType,
  ILockType,
  ICarvingType,
} from '@/types'
import { User } from '@/store/user'
import { isObjectEmpty } from '@/services/common'

interface IFormKeys {
  max_force_tensile_full_uc: number
  max_torque_permissible_full_uc: number
  max_force_tensile_up_uc: number
  max_torque_permissible_up_uc: number
  max_force_tensile_down_uc: number
  max_torque_permissible_down_uc: number
}

interface ICustomConnectionTypeKeys {
  newCustomTypeFull: ICustomConnectionType
  newCustomTypeUp: ICustomConnectionType
  newCustomTypeDown: ICustomConnectionType
}

interface ILockTypeKeys {
  newLockTypeFull: ILockType
  newLockTypeUp: ILockType
  newLockTypeDown: ILockType
}

interface ICarvingTypeKeys {
  newCarvingTypeFull: ICarvingType
  newCarvingTypeUp: ICarvingType
  newCarvingTypeDown: ICarvingType
}

interface ILockKeys {
  carving: number | null
  inner_diameter: number | null
  outer_diameter: number | null
  parameter_butt_muff: number | null
  parameter_carving_nippele: number | null
  parameter_lambda: number | null
  relative_stiffness_muff: number | null
  relative_stiffness_nippele: number | null
  ultimate_load_axial: number | null
  ultimate_load_muff: number | null
  ultimate_load_nippele: number | null
}

interface ILockUcKeys {
  inner_diameter_uc: number
  outer_diameter_uc: number
  parameter_butt_muff_uc: number
  parameter_carving_nippele_uc: number
  parameter_lambda_uc: number
  ultimate_load_axial_uc: number
  ultimate_load_muff_uc: number
  ultimate_load_nippele_uc: number
}

interface ICarvingKeys {
  number_turns_thread: number | null
  carving_step: number | null
  carving_taper: number | null
  diameter_calibration_point_step: number | null
  nippele_lenght: number | null
  diameter_countersink: number | null
  carving_height_not_truncated: number | null
  thread_depth: number | null
  profile_shape: number | null
}

interface ICarvingUcKeys {
  carving_step_uc: number
  diameter_calibration_point_step_uc: number
  nippele_lenght_uc: number
  diameter_countersink_uc: number
  carving_height_not_truncated_uc: number
  thread_depth_uc: number
}

interface ICustomConnectionKeys {
  max_torque_permissible: number | null
  max_force_tensile: number | null
}

interface ICustomConnectionUcKeys {
  max_torque_permissible_uc: number
  max_force_tensile_uc: number
}

interface IKeyObject {
  [key: string]: any
}

const connectionMock: IEquipConnection = {
  equipe_element: 0,
  connection_full: true,
  connection_type_full: 1,
  carving_type_full: null,
  lock_type_full: null,
  custom_type_full: null,
  disembarkation_lack_full: false,
  max_torque_permissible_full: 0,
  max_torque_permissible_full_uc: 400,
  max_force_tensile_full: 0,
  max_force_tensile_full_uc: 600,
  connection_up: true,
  connection_type_up: 1,
  carving_type_up: null,
  lock_type_up: null,
  custom_type_up: null,
  muff_nippele_up: 2,
  disembarkation_lack_up: false,
  max_torque_permissible_up: 0,
  max_torque_permissible_up_uc: 400,
  max_force_tensile_up: 0,
  max_force_tensile_up_uc: 600,
  connection_down: true,
  connection_type_down: 1,
  carving_type_down: null,
  lock_type_down: null,
  custom_type_down: null,
  muff_nippele_down: 2,
  disembarkation_lack_down: false,
  max_torque_permissible_down: 0,
  max_torque_permissible_down_uc: 400,
  max_force_tensile_down: 0,
  max_force_tensile_down_uc: 600,
}

const newCustomTypeMock: ICustomConnectionType = {
  name: null,
  max_torque_permissible: null,
  max_torque_permissible_uc: 400,
  max_force_tensile: null,
  max_force_tensile_uc: 605,
}

const newCarvingTypeMock: ICarvingType = {
  name: null,
  name_api: null,
  number_turns_thread: null,
  carving_step: null,
  carving_step_uc: 101,
  carving_taper: null,
  diameter_calibration_point_step: null,
  diameter_calibration_point_step_uc: 101,
  nippele_lenght: 0,
  nippele_lenght_uc: 101,
  diameter_countersink: null,
  diameter_countersink_uc: 101,
  carving_height_not_truncated: null,
  carving_height_not_truncated_uc: 101,
  thread_depth: null,
  thread_depth_uc: 101,
  profile_shape: null,
}

const newLockTypeMock: ILockType = {
  name: null,
  outer_diameter: null,
  outer_diameter_uc: 101,
  inner_diameter: null,
  inner_diameter_uc: 101,
  relative_stiffness_nippele: 0,
  relative_stiffness_muff: 0,
  ultimate_load_axial: null,
  ultimate_load_axial_uc: 605,
  ultimate_load_muff: 0,
  ultimate_load_muff_uc: 605,
  ultimate_load_nippele: 0,
  ultimate_load_nippele_uc: 605,
  parameter_carving_nippele: 0,
  parameter_carving_nippele_uc: 101,
  parameter_butt_muff: 0,
  parameter_butt_muff_uc: 101,
  parameter_lambda: null,
  parameter_lambda_uc: 101,
  carving: null,
}

@Component({
  components: {
    WPButtonIcon,
    WPLayoutBorder,
    WPRadioGroup,
    WPCheckbox,
    WPGroupMultiline,
    WPSwitch,
    WPButton,
    WPRadioSwitch,
    WPExpPanel,
    WPTextfieldMeasure,
    WPTextfield,
    WPDivider,
    WPBlockHint,
    WPCombobox,
  },
})
export default class TheWellboreEquipElementsLock extends Vue {
  @Prop({ type: [Number, String], default: '' })
  private id?: number | string

  @Prop({ type: Boolean, default: false })
  private connect?: boolean

  @Prop({ type: Boolean, default: false })
  private disabled?: boolean

  private isOpened = false

  private valid = false

  private loading = false

  private typesLoading = false

  private equipConnect: IEquipConnection = {
    ...connectionMock,
  }

  // Новый объект пользовательского соединения

  private newCustomTypeFull: ICustomConnectionType = {
    name: null,
    max_torque_permissible: null,
    max_torque_permissible_uc: 400,
    max_force_tensile: null,
    max_force_tensile_uc: 605,
  }

  private newCustomTypeUp: ICustomConnectionType = {
    name: null,
    max_torque_permissible: null,
    max_torque_permissible_uc: 400,
    max_force_tensile: null,
    max_force_tensile_uc: 605,
  }

  private newCustomTypeDown: ICustomConnectionType = {
    name: null,
    max_torque_permissible: null,
    max_torque_permissible_uc: 400,
    max_force_tensile: null,
    max_force_tensile_uc: 605,
  }

  // Новый объект замка

  private newLockTypeDown: ILockType = {
    name: null,
    outer_diameter: null,
    outer_diameter_uc: 101,
    inner_diameter: null,
    inner_diameter_uc: 101,
    relative_stiffness_nippele: 0,
    relative_stiffness_muff: 0,
    ultimate_load_axial: null,
    ultimate_load_axial_uc: 605,
    ultimate_load_muff: 0,
    ultimate_load_muff_uc: 605,
    ultimate_load_nippele: 0,
    ultimate_load_nippele_uc: 605,
    parameter_carving_nippele: 0,
    parameter_carving_nippele_uc: 101,
    parameter_butt_muff: 0,
    parameter_butt_muff_uc: 101,
    parameter_lambda: null,
    parameter_lambda_uc: 101,
    carving: null,
  }

  private newLockTypeUp: ILockType = {
    name: null,
    outer_diameter: null,
    outer_diameter_uc: 101,
    inner_diameter: null,
    inner_diameter_uc: 101,
    relative_stiffness_nippele: 0,
    relative_stiffness_muff: 0,
    ultimate_load_axial: null,
    ultimate_load_axial_uc: 605,
    ultimate_load_muff: 0,
    ultimate_load_muff_uc: 605,
    ultimate_load_nippele: 0,
    ultimate_load_nippele_uc: 605,
    parameter_carving_nippele: 0,
    parameter_carving_nippele_uc: 101,
    parameter_butt_muff: 0,
    parameter_butt_muff_uc: 101,
    parameter_lambda: null,
    parameter_lambda_uc: 101,
    carving: null,
  }

  private newLockTypeFull: ILockType = {
    name: null,
    outer_diameter: null,
    outer_diameter_uc: 101,
    inner_diameter: null,
    inner_diameter_uc: 101,
    relative_stiffness_nippele: 0,
    relative_stiffness_muff: 0,
    ultimate_load_axial: null,
    ultimate_load_axial_uc: 605,
    ultimate_load_muff: 0,
    ultimate_load_muff_uc: 605,
    ultimate_load_nippele: 0,
    ultimate_load_nippele_uc: 605,
    parameter_carving_nippele: 0,
    parameter_carving_nippele_uc: 101,
    parameter_butt_muff: 0,
    parameter_butt_muff_uc: 101,
    parameter_lambda: null,
    parameter_lambda_uc: 101,
    carving: null,
  }

  // Новый объект резьбы

  private newCarvingTypeFull: ICarvingType = {
    name: null,
    name_api: null,
    number_turns_thread: null,
    carving_step: null,
    carving_step_uc: 101,
    carving_taper: null,
    diameter_calibration_point_step: null,
    diameter_calibration_point_step_uc: 101,
    nippele_lenght: 0,
    nippele_lenght_uc: 101,
    diameter_countersink: null,
    diameter_countersink_uc: 101,
    carving_height_not_truncated: null,
    carving_height_not_truncated_uc: 101,
    thread_depth: null,
    thread_depth_uc: 101,
    profile_shape: null,
  }

  private newCarvingTypeUp: ICarvingType = {
    name: null,
    name_api: null,
    number_turns_thread: null,
    carving_step: null,
    carving_step_uc: 101,
    carving_taper: null,
    diameter_calibration_point_step: null,
    diameter_calibration_point_step_uc: 101,
    nippele_lenght: 0,
    nippele_lenght_uc: 101,
    diameter_countersink: null,
    diameter_countersink_uc: 101,
    carving_height_not_truncated: null,
    carving_height_not_truncated_uc: 101,
    thread_depth: null,
    thread_depth_uc: 101,
    profile_shape: null,
  }

  private newCarvingTypeDown: ICarvingType = {
    name: null,
    name_api: null,
    number_turns_thread: null,
    carving_step: null,
    carving_step_uc: 101,
    carving_taper: null,
    diameter_calibration_point_step: null,
    diameter_calibration_point_step_uc: 101,
    nippele_lenght: 0,
    nippele_lenght_uc: 101,
    diameter_countersink: null,
    diameter_countersink_uc: 101,
    carving_height_not_truncated: null,
    carving_height_not_truncated_uc: 101,
    thread_depth: null,
    thread_depth_uc: 101,
    profile_shape: null,
  }

  // Свитчи

  private get connectionFull() {
    return [
      {
        text: this.$t('connections.selects.together'),
        value: true,
      },
      {
        text: this.$t('connections.selects.apart'),
        value: false,
      },
    ]
  }

  private get muffNippeleItems() {
    return [
      {
        text: this.$t('connections.selects.nippele'),
        value: 2,
      },
      {
        text: this.$t('connections.selects.muft'),
        value: 1,
      },
    ]
  }

  private get connectionTypes() {
    return [
      {
        text: this.$t('connections.selects.lock'),
        value: 1,
      },
      {
        text: this.$t('connections.selects.carving'),
        value: 2,
      },
      {
        text: this.$t('connections.selects.custom'),
        value: 3,
      },
    ]
  }

  // Списки соединений

  private get lockTypes(): TLockTypes {
    return Wellbore.lockTypes
  }

  private get carvingTypes(): TCarvingTypes {
    return Wellbore.carvingTypes
  }

  private get customConnectionTypes(): TCustomConnectionTypes {
    return Wellbore.customConnectionTypes
  }

  private get profileShapes(): TProfileShapes {
    return Wellbore.profileShapes
  }

  // Проверка на новое пользовательское соединение

  private get isNewCustomConnectionFull() {
    return (
      this.equipConnect.custom_type_full === null ||
      typeof this.equipConnect.custom_type_full === 'string'
    )
  }

  private get isNewCustomConnectionUp() {
    return (
      this.equipConnect.custom_type_up === null ||
      typeof this.equipConnect.custom_type_up === 'string'
    )
  }

  private get isNewCustomConnectionDown() {
    return (
      this.equipConnect.custom_type_down === null ||
      typeof this.equipConnect.custom_type_down === 'string'
    )
  }

  // Проверка на новый замок

  private get isNewLockConnectionFull() {
    return (
      this.equipConnect.lock_type_full === null ||
      typeof this.equipConnect.lock_type_full === 'string'
    )
  }

  private get isNewLockConnectionUp() {
    return (
      this.equipConnect.lock_type_up === null ||
      typeof this.equipConnect.lock_type_up === 'string'
    )
  }

  private get isNewLockConnectionDown() {
    return (
      this.equipConnect.lock_type_down === null ||
      typeof this.equipConnect.lock_type_down === 'string'
    )
  }

  // Проверка на новую резьбу

  private get isNewCarvingConnectionFull() {
    return (
      this.equipConnect.carving_type_full === null ||
      typeof this.equipConnect.carving_type_full === 'string'
    )
  }

  private get isNewCarvingConnectionUp() {
    return (
      this.equipConnect.carving_type_up === null ||
      typeof this.equipConnect.carving_type_up === 'string'
    )
  }

  private get isNewCarvingConnectionDown() {
    return (
      this.equipConnect.carving_type_down === null ||
      typeof this.equipConnect.carving_type_down === 'string'
    )
  }

  // Проверка на редактирование пользовательского соединения

  private get isSaveCustomConnectionFull() {
    return (
      this.equipConnect.connection_full &&
      typeof this.selectedCustomTypeFull.user === 'number'
    )
  }

  private get isSaveCustomConnectionUp() {
    return (
      !this.equipConnect.connection_full &&
      this.equipConnect.connection_up &&
      typeof this.selectedCustomTypeUp.user === 'number'
    )
  }

  private get isSaveCustomConnectionDown() {
    return (
      !this.equipConnect.connection_full &&
      this.equipConnect.connection_down &&
      typeof this.selectedCustomTypeDown.user === 'number'
    )
  }

  // Проверки на редактирование замка

  private get isSaveLockConnectionFull() {
    return (
      this.equipConnect.connection_full &&
      typeof this.selectedLockTypeFull.user === 'number'
    )
  }

  private get isSaveLockConnectionUp() {
    return (
      !this.equipConnect.connection_full &&
      this.equipConnect.connection_up &&
      typeof this.selectedLockTypeUp.user === 'number'
    )
  }

  private get isSaveLockConnectionDown() {
    return (
      !this.equipConnect.connection_full &&
      this.equipConnect.connection_down &&
      typeof this.selectedLockTypeDown.user === 'number'
    )
  }

  // Проверки на редактирование резьбы

  private get isSaveCarvingConnectionFull() {
    return (
      this.equipConnect.connection_full &&
      typeof this.selectedCarvingTypeFull.user === 'number'
    )
  }

  private get isSaveCarvingConnectionUp() {
    return (
      !this.equipConnect.connection_full &&
      this.equipConnect.connection_up &&
      typeof this.selectedCarvingTypeUp.user === 'number'
    )
  }

  private get isSaveCarvingConnectionDown() {
    return (
      !this.equipConnect.connection_full &&
      this.equipConnect.connection_down &&
      typeof this.selectedCarvingTypeDown.user === 'number'
    )
  }

  // Проверка на возможность редактирования newConnection

  private get isEditLockConnectionFull() {
    return this.isNewLockConnectionFull || this.isSaveLockConnectionFull
  }

  private get isEditLockConnectionUp() {
    return this.isNewLockConnectionUp || this.isSaveLockConnectionUp
  }

  private get isEditLockConnectionDown() {
    return this.isNewLockConnectionDown || this.isSaveLockConnectionDown
  }

  private get isEditCustomConnectionFull() {
    return this.isNewCustomConnectionFull || this.isSaveCustomConnectionFull
  }

  private get isEditCustomConnectionUp() {
    return this.isNewCustomConnectionUp || this.isSaveCustomConnectionUp
  }

  private get isEditCustomConnectionDown() {
    return this.isNewCustomConnectionDown || this.isSaveCustomConnectionDown
  }

  private get isEditCarvingConnectionFull() {
    return this.isNewCarvingConnectionFull || this.isSaveCarvingConnectionFull
  }

  private get isEditCarvingConnectionUp() {
    return this.isNewCarvingConnectionUp || this.isSaveCarvingConnectionUp
  }

  private get isEditCarvingConnectionDown() {
    return this.isNewCarvingConnectionDown || this.isSaveCarvingConnectionDown
  }

  // Выбранные замки

  private get selectedLockTypeFull(): ILockType | IKeyObject {
    if (
      this.equipConnect.lock_type_full &&
      typeof this.equipConnect.lock_type_full === 'object'
    ) {
      return this.equipConnect.lock_type_full
    } else
      return { ...this.newLockTypeFull, name: this.equipConnect.lock_type_full }
  }

  private get selectedLockTypeUp(): ILockType | IKeyObject {
    if (
      this.equipConnect.lock_type_up &&
      typeof this.equipConnect.lock_type_up === 'object'
    ) {
      return this.equipConnect.lock_type_up
    } else return { name: this.equipConnect.lock_type_up }
  }

  private get selectedLockTypeDown(): ILockType | IKeyObject {
    if (
      this.equipConnect.lock_type_down &&
      typeof this.equipConnect.lock_type_down === 'object'
    ) {
      return this.equipConnect.lock_type_down
    } else return { name: this.equipConnect.lock_type_down }
  }

  // Выбранная резьба

  private get selectedCarvingTypeFull(): ICarvingType | IKeyObject {
    if (
      this.equipConnect.carving_type_full &&
      typeof this.equipConnect.carving_type_full === 'object'
    ) {
      const id = this.equipConnect.carving_type_full?.id
      return (
        this.carvingTypes.find((item) => item.id === Number(id)) ||
        this.equipConnect.carving_type_full
      )
    } else return { name: this.equipConnect.carving_type_full }
  }

  private get selectedCarvingTypeUp(): ICarvingType | IKeyObject {
    if (
      this.equipConnect.carving_type_up &&
      typeof this.equipConnect.carving_type_up === 'object'
    ) {
      const id = this.equipConnect.carving_type_up?.id
      return (
        this.carvingTypes.find((item) => item.id === Number(id)) ||
        this.equipConnect.carving_type_up
      )
    } else return { name: this.equipConnect.carving_type_up }
  }

  private get selectedCarvingTypeDown(): ICarvingType | IKeyObject {
    if (
      this.equipConnect.carving_type_down &&
      typeof this.equipConnect.carving_type_down === 'object'
    ) {
      const id = this.equipConnect.carving_type_down?.id
      return (
        this.carvingTypes.find((item) => item.id === Number(id)) ||
        this.equipConnect.carving_type_down
      )
    } else return { name: this.equipConnect.carving_type_down }
  }

  // Выбранное пользовательское соединение

  private get selectedCustomTypeFull(): ICustomConnectionType | IKeyObject {
    if (
      this.equipConnect.custom_type_full &&
      typeof this.equipConnect.custom_type_full === 'object'
    ) {
      return this.equipConnect.custom_type_full
    } else return { name: this.equipConnect.custom_type_full }
  }

  private get selectedCustomTypeUp(): ICustomConnectionType | IKeyObject {
    if (
      this.equipConnect.custom_type_up &&
      typeof this.equipConnect.custom_type_up === 'object'
    ) {
      return this.equipConnect.custom_type_up
    } else return { name: this.equipConnect.custom_type_up }
  }

  private get selectedCustomTypeDown(): ICustomConnectionType | IKeyObject {
    if (
      this.equipConnect.custom_type_down &&
      typeof this.equipConnect.custom_type_down === 'object'
    ) {
      return this.equipConnect.custom_type_down
    } else return { name: this.equipConnect.custom_type_down }
  }

  // Получение символов соединения

  private get getConnectionSymbols() {
    const typeSymbols = [
      String(this.$t('connections.symbols.lock')),
      String(this.$t('connections.symbols.carving')),
      String(this.$t('connections.symbols.custom')),
    ]
    const muffSymbols = [
      String(this.$t('connections.symbols.muft')),
      String(this.$t('connections.symbols.nippele')),
    ]
    const upType = this.equipConnect.connection_full
      ? typeSymbols[this.equipConnect.connection_type_full - 1]
      : typeSymbols[this.equipConnect.connection_type_up - 1]
    const downType = this.equipConnect.connection_full
      ? typeSymbols[this.equipConnect.connection_type_full - 1]
      : typeSymbols[this.equipConnect.connection_type_down - 1]

    const connUp = this.equipConnect.connection_full
      ? this.equipConnect.connection_type_full
      : this.equipConnect.connection_type_up
    const connDown = this.equipConnect.connection_full
      ? this.equipConnect.connection_type_full
      : this.equipConnect.connection_type_down

    const upMuff =
      connUp !== 3 ? muffSymbols[this.equipConnect.muff_nippele_up - 1] : ''
    const downMuff =
      connDown !== 3 ? muffSymbols[this.equipConnect.muff_nippele_down - 1] : ''
    let result = {
      up: this.equipConnect.connection_up ? upType + upMuff : '',
      down: this.equipConnect.connection_down ? downType + downMuff : '',
      id: this.id ? this.id : undefined,
    }
    return result
  }

  private toggleBlock() {
    this.isOpened = !this.isOpened
    this.$emit('formToggle', this.isOpened)
  }

  private async openBlock() {
    if (
      !this.lockTypes.length ||
      !this.customConnectionTypes.length ||
      !this.carvingTypes.length
    ) {
      const promises = [
        this.onLoadCustomType(),
        this.onLoadLockTypes(),
        this.onLoadCarvingTypes(),
        this.onLoadProfileShapes(),
      ]
      await Promise.all(promises)
    }

    await this.loadAllTypesById()
    void this.toggleBlock()
  }

  private changeMeasure(val: number, key: keyof IFormKeys) {
    this.equipConnect[key] = val
  }

  // Методы обновления замка

  private changeLCMeasure(
    val: number,
    type: keyof ILockTypeKeys,
    key: keyof ILockUcKeys
  ) {
    this[type][key] = val
  }

  private changeLCValue(
    val: number,
    type: keyof ILockTypeKeys,
    key: keyof ILockKeys
  ) {
    this[type][key] = val
  }

  // Методы обновления пользовательского соединения

  private changeCCMeasure(
    val: number,
    type: keyof ICustomConnectionTypeKeys,
    key: keyof ICustomConnectionUcKeys
  ) {
    this[type][key] = val
  }

  private changeCCValue(
    val: number,
    type: keyof ICustomConnectionTypeKeys,
    key: keyof ICustomConnectionKeys
  ) {
    this[type][key] = val
  }

  // Методы обновления резьбы

  private changeRCMeasure(
    val: number,
    type: keyof ICarvingTypeKeys,
    key: keyof ICarvingUcKeys
  ) {
    this[type][key] = val
  }

  private changeRCValue(
    val: number,
    type: keyof ICarvingTypeKeys,
    key: keyof ICarvingKeys
  ) {
    this[type][key] = val
  }

  private changeRCText(val: string, type: keyof ICarvingTypeKeys) {
    this[type].name_api = val
  }

  // Апи метода создания новых типов

  private async createCustomType(
    item: ICustomConnectionType,
    name: string | number
  ) {
    const data = {
      ...item,
      name: String(name),
    }
    try {
      const result = await Wellbore.ADD_CUSTOM_CONNECTION_TYPE(data)
      if (result) return result.id
    } catch (error) {}
  }

  private async createLockType(item: ILockType, name: string | number) {
    const data = {
      ...item,
      name: String(name),
    }
    try {
      const result = await Wellbore.ADD_LOCK_TYPE(data)
      if (result) return result.id
    } catch (error) {}
  }

  private async createCarvingType(item: ICarvingType, name: string | number) {
    const data = {
      ...item,
      name: String(name),
    }
    try {
      const result = await Wellbore.ADD_CARVING_TYPE(data)
      if (result) return result.id
    } catch (error) {}
  }

  // Апи метода сохранения типов соединения

  private async saveCustomType(item: ICustomConnectionType) {
    try {
      const result = await Wellbore.SAVE_CUSTOM_CONNECTION_TYPE(item)
      if (result) return result.id
    } catch (error) {}
  }

  private async saveLockType(item: ILockType) {
    try {
      const result = await Wellbore.SAVE_LOCK_TYPE(item)
      if (result) return result.id
    } catch (error) {}
  }

  private async saveCarvingType(item: ICarvingType) {
    try {
      const result = await Wellbore.SAVE_CARVING_TYPE(item)
      if (result) return result.id
    } catch (error) {}
  }

  // Проверка на создание новых соединений

  private async checkNewCustomConnections() {
    if (
      this.isNewCustomConnectionFull &&
      this.equipConnect.connection_type_full === 3 &&
      this.equipConnect.custom_type_full
    ) {
      const id = await this.createCustomType(
        this.newCustomTypeFull,
        String(this.equipConnect.custom_type_full)
      )
      if (id) {
        this.equipConnect.custom_type_full = id
      } else return
    }

    if (
      this.isNewCustomConnectionUp &&
      this.equipConnect.connection_type_up === 3 &&
      this.equipConnect.custom_type_up
    ) {
      const id = await this.createCustomType(
        this.newCustomTypeUp,
        String(this.equipConnect.custom_type_up)
      )
      if (id) {
        this.equipConnect.custom_type_up = id
      } else return
    }

    if (
      this.isNewCustomConnectionDown &&
      this.equipConnect.connection_type_down === 3 &&
      this.equipConnect.custom_type_down
    ) {
      const id = await this.createCustomType(
        this.newCustomTypeDown,
        String(this.equipConnect.custom_type_down)
      )
      if (id) {
        this.equipConnect.custom_type_down = id
      } else return
    }
  }

  private async checkNewLockConnections() {
    if (
      this.isNewLockConnectionFull &&
      this.equipConnect.connection_type_full === 1 &&
      this.equipConnect.lock_type_full
    ) {
      const id = await this.createLockType(
        this.newLockTypeFull,
        String(this.equipConnect.lock_type_full)
      )
      if (id) {
        this.equipConnect.lock_type_full = id
      } else return
    }

    if (
      this.isNewLockConnectionUp &&
      this.equipConnect.connection_type_up === 1 &&
      this.equipConnect.lock_type_up
    ) {
      const id = await this.createLockType(
        this.newLockTypeUp,
        String(this.equipConnect.lock_type_up)
      )
      if (id) {
        this.equipConnect.lock_type_up = id
      } else return
    }

    if (
      this.isNewLockConnectionDown &&
      this.equipConnect.connection_type_down === 1 &&
      this.equipConnect.lock_type_down
    ) {
      const id = await this.createLockType(
        this.newLockTypeDown,
        String(this.equipConnect.lock_type_down)
      )
      if (id) {
        this.equipConnect.lock_type_down = id
      } else return
    }
  }

  private async checkNewCarvingConnections() {
    void this.checkObjectTypeNewProfileShape()

    if (
      this.isNewCarvingConnectionFull &&
      this.equipConnect.connection_type_full === 2 &&
      this.equipConnect.carving_type_full
    ) {
      const id = await this.createCarvingType(
        this.newCarvingTypeFull,
        String(this.equipConnect.carving_type_full)
      )
      if (id) {
        this.equipConnect.carving_type_full = id
      }
    }

    if (
      this.isNewCarvingConnectionUp &&
      this.equipConnect.connection_type_up === 2 &&
      this.equipConnect.carving_type_up
    ) {
      const id = await this.createCarvingType(
        this.newCarvingTypeUp,
        String(this.equipConnect.carving_type_up)
      )
      if (id) {
        this.equipConnect.carving_type_up = id
      }
    }

    if (
      this.isNewCarvingConnectionDown &&
      this.equipConnect.connection_type_down === 2 &&
      this.equipConnect.carving_type_down
    ) {
      const id = await this.createCarvingType(
        this.newCarvingTypeDown,
        String(this.equipConnect.carving_type_down)
      )
      if (id) {
        this.equipConnect.carving_type_down = id
      }
    }
  }

  private async checkNewProfileShape() {
    if (
      this.equipConnect.carving_type_full &&
      typeof this.newCarvingTypeFull.profile_shape === 'string'
    ) {
      const result = await Wellbore.ADD_PROFILE_SHAPE(
        this.newCarvingTypeFull.profile_shape
      )

      if (result) this.newCarvingTypeFull.profile_shape = Number(result.id)
    }

    if (
      this.equipConnect.carving_type_up &&
      typeof this.newCarvingTypeUp.profile_shape === 'string'
    ) {
      const result = await Wellbore.ADD_PROFILE_SHAPE(
        this.newCarvingTypeUp.profile_shape
      )

      if (result) this.newCarvingTypeUp.profile_shape = Number(result.id)
    }

    if (
      this.equipConnect.carving_type_down &&
      typeof this.newCarvingTypeDown.profile_shape === 'string'
    ) {
      const result = await Wellbore.ADD_PROFILE_SHAPE(
        this.newCarvingTypeDown.profile_shape
      )

      if (result) this.newCarvingTypeDown.profile_shape = Number(result.id)
    }
  }

  private checkObjectTypeNewProfileShape() {
    if (this.equipConnect.connection_full) {
      if (
        this.equipConnect.carving_type_full &&
        this.newCarvingTypeFull.profile_shape &&
        typeof this.newCarvingTypeFull.profile_shape === 'object'
      ) {
        this.newCarvingTypeFull.profile_shape = Number(
          this.newCarvingTypeFull.profile_shape.id
        )
      }
    } else {
      if (
        this.equipConnect.connection_down &&
        this.equipConnect.carving_type_down &&
        this.newCarvingTypeDown.profile_shape &&
        typeof this.newCarvingTypeDown.profile_shape === 'object'
      ) {
        this.newCarvingTypeDown.profile_shape = Number(
          this.newCarvingTypeDown.profile_shape.id
        )
      }

      if (
        this.equipConnect.connection_up &&
        this.equipConnect.carving_type_up &&
        this.newCarvingTypeUp.profile_shape &&
        typeof this.newCarvingTypeUp.profile_shape === 'object'
      ) {
        this.newCarvingTypeUp.profile_shape = Number(
          this.newCarvingTypeUp.profile_shape.id
        )
      }
    }
  }

  // Проверка на сохранение соединений

  private async checkSaveCustomConnections() {
    if (
      this.isSaveCustomConnectionFull &&
      this.equipConnect.connection_type_full === 3 &&
      this.equipConnect.custom_type_full
    ) {
      const id = await this.saveCustomType(this.newCustomTypeFull)
      if (id) {
        this.equipConnect.custom_type_full = id
      } else return
    }

    if (
      this.isSaveCustomConnectionUp &&
      this.equipConnect.connection_type_up === 3 &&
      this.equipConnect.custom_type_up
    ) {
      const id = await this.saveCustomType(this.newCustomTypeUp)
      if (id) {
        this.equipConnect.custom_type_up = id
      } else return
    }

    if (
      this.isSaveCustomConnectionDown &&
      this.equipConnect.connection_type_down === 3 &&
      this.equipConnect.custom_type_down
    ) {
      const id = await this.saveCustomType(this.newCustomTypeDown)
      if (id) {
        this.equipConnect.custom_type_down = id
      } else return
    }
  }

  private async checkSaveLockConnections() {
    if (
      this.isSaveLockConnectionFull &&
      this.equipConnect.connection_type_full === 1 &&
      this.equipConnect.lock_type_full
    ) {
      const id = await this.saveLockType(this.newLockTypeFull)
      if (id) {
        this.equipConnect.lock_type_full = id
      } else return
    }

    if (
      this.isSaveLockConnectionUp &&
      this.equipConnect.connection_type_up === 1 &&
      this.equipConnect.lock_type_up
    ) {
      const id = await this.saveLockType(this.newLockTypeUp)
      if (id) {
        this.equipConnect.lock_type_up = id
      } else return
    }

    if (
      this.isSaveLockConnectionDown &&
      this.equipConnect.connection_type_down === 1 &&
      this.equipConnect.lock_type_down
    ) {
      const id = await this.saveLockType(this.newLockTypeDown)
      if (id) {
        this.equipConnect.lock_type_down = id
      } else return
    }
  }

  private async checkSaveCarvingConnections() {
    void this.checkObjectTypeNewProfileShape()

    if (
      this.isSaveCarvingConnectionFull &&
      this.equipConnect.connection_type_full === 2 &&
      this.equipConnect.carving_type_full
    ) {
      const id = await this.saveCarvingType(this.newCarvingTypeFull)
      if (id) {
        this.equipConnect.carving_type_full = id
      } else return
    }

    if (
      this.isSaveCarvingConnectionUp &&
      this.equipConnect.connection_type_up === 2 &&
      this.equipConnect.carving_type_up
    ) {
      const id = await this.saveCarvingType(this.newCarvingTypeUp)
      if (id) {
        this.equipConnect.carving_type_up = id
      } else return
    }

    if (
      this.isSaveCarvingConnectionDown &&
      this.equipConnect.connection_type_down === 2 &&
      this.equipConnect.carving_type_down
    ) {
      const id = await this.saveCarvingType(this.newCarvingTypeDown)
      if (id) {
        this.equipConnect.carving_type_down = id
      } else return
    }
  }

  // Сохранение id в equipConnect, если выбран объект

  private checkObjectTypeNewConnection() {
    if (
      this.equipConnect.lock_type_down &&
      typeof this.equipConnect.lock_type_down === 'object'
    )
      this.equipConnect.lock_type_down = Number(
        this.equipConnect.lock_type_down.id
      )

    if (
      this.equipConnect.lock_type_full &&
      typeof this.equipConnect.lock_type_full === 'object'
    )
      this.equipConnect.lock_type_full = Number(
        this.equipConnect.lock_type_full.id
      )

    if (
      this.equipConnect.lock_type_up &&
      typeof this.equipConnect.lock_type_up === 'object'
    )
      this.equipConnect.lock_type_up = Number(this.equipConnect.lock_type_up.id)

    if (
      this.equipConnect.carving_type_down &&
      typeof this.equipConnect.carving_type_down === 'object'
    )
      this.equipConnect.carving_type_down = Number(
        this.equipConnect.carving_type_down.id
      )

    if (
      this.equipConnect.carving_type_full &&
      typeof this.equipConnect.carving_type_full === 'object'
    )
      this.equipConnect.carving_type_full = Number(
        this.equipConnect.carving_type_full.id
      )

    if (
      this.equipConnect.carving_type_up &&
      typeof this.equipConnect.carving_type_up === 'object'
    )
      this.equipConnect.carving_type_up = Number(
        this.equipConnect.carving_type_up.id
      )

    if (
      this.equipConnect.custom_type_down &&
      typeof this.equipConnect.custom_type_down === 'object'
    )
      this.equipConnect.custom_type_down = Number(
        this.equipConnect.custom_type_down.id
      )

    if (
      this.equipConnect.custom_type_full &&
      typeof this.equipConnect.custom_type_full === 'object'
    )
      this.equipConnect.custom_type_full = Number(
        this.equipConnect.custom_type_full.id
      )

    if (
      this.equipConnect.custom_type_up &&
      typeof this.equipConnect.custom_type_up === 'object'
    )
      this.equipConnect.custom_type_up = Number(
        this.equipConnect.custom_type_up.id
      )
  }

  // Итоговый submit

  private async save() {
    this.loading = true
    try {
      await this.checkNewProfileShape()

      await this.checkNewCustomConnections()
      await this.checkNewLockConnections()
      await this.checkNewCarvingConnections()

      await this.checkSaveCustomConnections()
      await this.checkSaveLockConnections()
      await this.checkSaveCarvingConnections()

      void this.checkObjectTypeNewConnection()

      if (this.connect) {
        await Wellbore.SAVE_EQUIP_ELEMENT_CONNECTION(this.equipConnect)
      } else {
        await Wellbore.ADD_EQUIP_ELEMENT_CONNECTION(this.equipConnect)
        this.$emit('update')
      }
      this.$emit('symbols', this.getConnectionSymbols)
      this.loading = false
      this.toggleBlock()
    } catch (e) {
      this.loading = false
    }
    this.loading = false
  }

  private async remove() {
    try {
      await Wellbore.DELETE_EQUIP_ELEMENT_CONNECTION(Number(this.id))
      this.$emit('update')
      this.equipConnect = { ...connectionMock, equipe_element: Number(this.id) }
      this.$emit('symbols', this.getConnectionSymbols)
      this.toggleBlock()
    } catch (e) {}
  }

  // Подгрузка типов замков, если их нет на первой странице списка

  private async loadAllTypesById() {
    this.typesLoading = true

    const {
      carving_type_down,
      carving_type_full,
      carving_type_up,
      lock_type_down,
      lock_type_full,
      lock_type_up,
      custom_type_down,
      custom_type_full,
      custom_type_up,
    } = this.equipConnect

    if (
      carving_type_down &&
      typeof carving_type_down === 'number' &&
      !this.carvingTypes.find((item) => item.id === carving_type_down)
    ) {
      let response = await Wellbore.GET_CARVING_TYPE_BY_ID(carving_type_down)
      if (
        response?.profile_shape &&
        typeof response?.profile_shape === 'number'
      ) {
        const profileShapeResponse = await Wellbore.GET_PROFILE_SHAPE_BY_ID(
          response?.profile_shape
        )
        response.profile_shape = profileShapeResponse
      }
      this.equipConnect.carving_type_down = response
    }

    if (
      carving_type_up &&
      typeof carving_type_up === 'number' &&
      !this.carvingTypes.find((item) => item.id === carving_type_up)
    ) {
      let response = await Wellbore.GET_CARVING_TYPE_BY_ID(carving_type_up)
      if (
        response?.profile_shape &&
        typeof response?.profile_shape === 'number'
      ) {
        const profileShapeResponse = await Wellbore.GET_PROFILE_SHAPE_BY_ID(
          response?.profile_shape
        )
        response.profile_shape = profileShapeResponse
      }
      this.equipConnect.carving_type_up = response
    }

    if (
      carving_type_full &&
      typeof carving_type_full === 'number' &&
      !this.carvingTypes.find((item) => item.id === carving_type_full)
    ) {
      let response = await Wellbore.GET_CARVING_TYPE_BY_ID(carving_type_full)
      if (
        response?.profile_shape &&
        typeof response?.profile_shape === 'number'
      ) {
        const profileShapeResponse = await Wellbore.GET_PROFILE_SHAPE_BY_ID(
          response?.profile_shape
        )
        response.profile_shape = profileShapeResponse
      }
      this.equipConnect.carving_type_full = response
    }

    if (
      lock_type_down &&
      typeof lock_type_down === 'number' &&
      !this.lockTypes.find((item) => item.id === lock_type_down)
    ) {
      let response = await Wellbore.GET_LOCK_TYPE_BY_ID(lock_type_down)
      if (response?.carving && typeof response?.carving === 'number') {
        const carvingResponse = await Wellbore.GET_CARVING_TYPE_BY_ID(
          response?.carving
        )
        response.carving = carvingResponse
      }
      this.equipConnect.lock_type_down = response
    }

    if (
      lock_type_up &&
      typeof lock_type_up === 'number' &&
      !this.lockTypes.find((item) => item.id === lock_type_up)
    ) {
      let response = await Wellbore.GET_LOCK_TYPE_BY_ID(lock_type_up)
      if (response?.carving && typeof response?.carving === 'number') {
        const carvingResponse = await Wellbore.GET_CARVING_TYPE_BY_ID(
          response?.carving
        )
        response.carving = carvingResponse
      }
      this.equipConnect.lock_type_up = response
    }

    if (
      lock_type_full &&
      typeof lock_type_full === 'number' &&
      !this.lockTypes.find((item) => item.id === lock_type_full)
    ) {
      let response = await Wellbore.GET_LOCK_TYPE_BY_ID(lock_type_full)
      if (response?.carving && typeof response?.carving === 'number') {
        const carvingResponse = await Wellbore.GET_CARVING_TYPE_BY_ID(
          response?.carving
        )
        response.carving = carvingResponse
      }

      this.equipConnect.lock_type_full = response
    }

    if (
      custom_type_down &&
      typeof custom_type_down === 'number' &&
      !this.customConnectionTypes.find((item) => item.id === custom_type_down)
    ) {
      const response = await Wellbore.GET_CUSTOM_TYPE_BY_ID(custom_type_down)
      this.equipConnect.custom_type_down = response
    }

    if (
      custom_type_up &&
      typeof custom_type_up === 'number' &&
      !this.customConnectionTypes.find((item) => item.id === custom_type_up)
    ) {
      const response = await Wellbore.GET_CUSTOM_TYPE_BY_ID(custom_type_up)
      this.equipConnect.custom_type_up = response
    }

    if (
      custom_type_full &&
      typeof custom_type_full === 'number' &&
      !this.customConnectionTypes.find((item) => item.id === custom_type_full)
    ) {
      const response = await Wellbore.GET_CUSTOM_TYPE_BY_ID(custom_type_full)
      this.equipConnect.custom_type_full = response
    }

    this.typesLoading = false
  }

  private async loadSecondaryTypesById(
    type: 'lock' | 'carving',
    direction: 'full' | 'up' | 'down'
  ) {
    switch (type) {
      case 'lock':
        switch (direction) {
          case 'full':
            if (
              typeof this.equipConnect.lock_type_full === 'object' &&
              this.equipConnect.lock_type_full?.carving &&
              typeof this.equipConnect.lock_type_full?.carving === 'number'
            ) {
              const lock = this.equipConnect.lock_type_full
              if (!this.carvingTypes.find((item) => item.id === lock.carving)) {
                const carvingResponse = await Wellbore.GET_CARVING_TYPE_BY_ID(
                  this.equipConnect.lock_type_full?.carving
                )
                this.equipConnect.lock_type_full.carving = carvingResponse
              }
            }
            break

          case 'up':
            if (
              typeof this.equipConnect.lock_type_up === 'object' &&
              this.equipConnect.lock_type_up?.carving &&
              typeof this.equipConnect.lock_type_up?.carving === 'number'
            ) {
              const lock = this.equipConnect.lock_type_up
              if (!this.carvingTypes.find((item) => item.id === lock.carving)) {
                const carvingResponse = await Wellbore.GET_CARVING_TYPE_BY_ID(
                  this.equipConnect.lock_type_up?.carving
                )
                this.equipConnect.lock_type_up.carving = carvingResponse
              }
            }
            break

          case 'down':
            if (
              typeof this.equipConnect.lock_type_down === 'object' &&
              this.equipConnect.lock_type_down?.carving &&
              typeof this.equipConnect.lock_type_down?.carving === 'number'
            ) {
              const lock = this.equipConnect.lock_type_down
              if (!this.carvingTypes.find((item) => item.id === lock.carving)) {
                const carvingResponse = await Wellbore.GET_CARVING_TYPE_BY_ID(
                  this.equipConnect.lock_type_down?.carving
                )
                this.equipConnect.lock_type_down.carving = carvingResponse
              }
            }
            break
        }
        break

      case 'carving':
        switch (direction) {
          case 'full':
            if (
              typeof this.equipConnect.carving_type_full === 'object' &&
              this.equipConnect.carving_type_full?.profile_shape &&
              typeof this.equipConnect.carving_type_full?.profile_shape ===
                'number'
            ) {
              const carving = this.equipConnect.carving_type_full
              if (
                !this.profileShapes.find(
                  (item) => item.id === carving.profile_shape
                )
              ) {
                const profileShapeResponse =
                  await Wellbore.GET_PROFILE_SHAPE_BY_ID(
                    this.equipConnect.carving_type_full?.profile_shape
                  )
                this.equipConnect.carving_type_full.profile_shape =
                  profileShapeResponse
              }
            }
            break

          case 'up':
            if (
              typeof this.equipConnect.carving_type_up === 'object' &&
              this.equipConnect.carving_type_up?.profile_shape &&
              typeof this.equipConnect.carving_type_up?.profile_shape ===
                'number'
            ) {
              const carving = this.equipConnect.carving_type_up
              if (
                !this.profileShapes.find(
                  (item) => item.id === carving.profile_shape
                )
              ) {
                const profileShapeResponse =
                  await Wellbore.GET_PROFILE_SHAPE_BY_ID(
                    this.equipConnect.carving_type_up?.profile_shape
                  )
                this.equipConnect.carving_type_up.profile_shape =
                  profileShapeResponse
              }
            }
            break

          case 'down':
            if (
              typeof this.equipConnect.carving_type_down === 'object' &&
              this.equipConnect.carving_type_down?.profile_shape &&
              typeof this.equipConnect.carving_type_down?.profile_shape ===
                'number'
            ) {
              const carving = this.equipConnect.carving_type_down
              if (
                !this.profileShapes.find(
                  (item) => item.id === carving.profile_shape
                )
              ) {
                const profileShapeResponse =
                  await Wellbore.GET_PROFILE_SHAPE_BY_ID(
                    this.equipConnect.carving_type_down?.profile_shape
                  )
                this.equipConnect.carving_type_down.profile_shape =
                  profileShapeResponse
              }
            }
            break
        }
        break
    }
  }

  // Подгрузка списков соединений

  private async onLoadCustomType() {
    await Wellbore.GET_CUSTOM_CONNECTION_TYPES()
  }

  private async onLoadLockTypes() {
    await Wellbore.GET_LOCK_TYPES()
  }

  private async onLoadCarvingTypes() {
    await Wellbore.GET_CARVING_TYPES()
  }

  private async onLoadProfileShapes() {
    await Wellbore.GET_PROFILE_SHAPES()
  }

  // Добавление данных соединений при выборе редактируемого соединения

  @Watch('equipConnect.lock_type_full', { deep: true })
  private async onChangeSaveLockConnectionFull(item: ILockType) {
    if (typeof item === 'object') {
      let result = { ...item }
      if (isObjectEmpty(item)) {
        result = { ...newLockTypeMock }
      }
      delete result.user
      this.newLockTypeFull = { ...result }
    } else if (typeof item === 'string') {
      let result = { ...this.newLockTypeFull }
      delete result.id
      delete result.user
      this.newLockTypeFull = { ...result, name: item }
    }

    await this.loadSecondaryTypesById('lock', 'full')
  }

  @Watch('equipConnect.lock_type_down', { deep: true })
  private async onChangeSaveLockConnectionDown(item: ILockType) {
    if (typeof item === 'object') {
      let result = { ...item }
      if (isObjectEmpty(item)) {
        result = { ...newLockTypeMock }
      }
      delete result.user
      this.newLockTypeDown = { ...result }
    } else if (typeof item === 'string') {
      let result = { ...this.newLockTypeDown }
      delete result.id
      delete result.user
      this.newLockTypeDown = { ...result, name: item }
    }

    await this.loadSecondaryTypesById('lock', 'down')
  }

  @Watch('equipConnect.lock_type_up', { deep: true })
  private async onChangeSaveLockConnectionUp(item: ILockType) {
    if (typeof item === 'object') {
      let result = { ...item }
      if (isObjectEmpty(item)) {
        result = { ...newLockTypeMock }
      }
      delete result.user
      this.newLockTypeUp = { ...result }
    } else if (typeof item === 'string') {
      let result = { ...this.newLockTypeUp }
      delete result.id
      delete result.user
      this.newLockTypeUp = { ...result, name: item }
    }

    await this.loadSecondaryTypesById('lock', 'up')
  }

  @Watch('equipConnect.custom_type_full', { deep: true })
  private onChangeSaveCustomConnectionFull(item: ICustomConnectionType) {
    if (typeof item === 'object') {
      let result = { ...item }
      if (isObjectEmpty(item)) {
        result = { ...newCustomTypeMock }
      }
      delete result.user
      this.newCustomTypeFull = { ...result }
    } else if (typeof item === 'string') {
      let result = { ...this.newCustomTypeFull }
      delete result.id
      delete result.user
      this.newCustomTypeFull = { ...result, name: item }
    }
  }

  @Watch('equipConnect.custom_type_down', { deep: true })
  private onChangeSaveCustomConnectionDown(item: ICustomConnectionType) {
    if (typeof item === 'object') {
      let result = { ...item }
      if (isObjectEmpty(item)) {
        result = { ...newCustomTypeMock }
      }
      delete result.user
      this.newCustomTypeDown = { ...result }
    } else if (typeof item === 'string') {
      let result = { ...this.newCustomTypeDown }
      delete result.id
      delete result.user
      this.newCustomTypeDown = { ...result, name: item }
    }
  }

  @Watch('equipConnect.custom_type_up', { deep: true })
  private onChangeSaveCustomConnectionUp(item: ICustomConnectionType) {
    if (typeof item === 'object') {
      let result = { ...item }
      if (isObjectEmpty(item)) {
        result = { ...newCustomTypeMock }
      }
      delete result.user
      this.newCustomTypeUp = { ...result }
    } else if (typeof item === 'string') {
      let result = { ...this.newCustomTypeUp }
      delete result.id
      delete result.user
      this.newCustomTypeUp = { ...result, name: item }
    }
  }

  @Watch('equipConnect.carving_type_full', { deep: true })
  private async onChangeSaveCarvingConnectionFull(item: ICarvingType) {
    if (typeof item === 'object') {
      let result = { ...item }
      if (isObjectEmpty(item)) {
        result = { ...newCarvingTypeMock }
      }
      delete result.user
      this.newCarvingTypeFull = { ...result }
    } else if (typeof item === 'string') {
      let result = { ...this.newCarvingTypeFull }
      delete result.id
      delete result.user
      this.newCarvingTypeFull = { ...result, name: item }
    }

    await this.loadSecondaryTypesById('carving', 'full')
  }

  @Watch('equipConnect.carving_type_down', { deep: true })
  private async onChangeSaveCarvingConnectionDown(item: ICarvingType) {
    if (typeof item === 'object') {
      let result = { ...item }
      if (isObjectEmpty(item)) {
        result = { ...newCarvingTypeMock }
      }
      delete result.user
      this.newCarvingTypeDown = { ...result }
    } else if (typeof item === 'string') {
      let result = { ...this.newCarvingTypeDown }
      delete result.id
      delete result.user
      this.newCarvingTypeDown = { ...result, name: item }
    }

    await this.loadSecondaryTypesById('carving', 'down')
  }

  @Watch('equipConnect.carving_type_up', { deep: true })
  private async onChangeSaveCarvingConnectionUp(item: ICarvingType) {
    if (typeof item === 'object') {
      let result = { ...item }
      if (isObjectEmpty(item)) {
        result = { ...newCarvingTypeMock }
      }
      delete result.user
      this.newCarvingTypeUp = { ...result }
    } else if (typeof item === 'string') {
      let result = { ...this.newCarvingTypeUp }
      delete result.id
      delete result.user
      this.newCarvingTypeUp = { ...result, name: item }
    }

    await this.loadSecondaryTypesById('carving', 'up')
  }

  // События при монтировании компонента

  private async mounted() {
    this.equipConnect.equipe_element = Number(this.id)

    if (this.connect) {
      const result = await Wellbore.GET_EQUIP_ELEMENT_CONNECTION(
        Number(this.id)
      )

      if (result)
        this.equipConnect = {
          ...result,
        }
      this.$emit('symbols', this.getConnectionSymbols)
    }
  }
}
