import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule,
} from 'vuex-module-decorators'
import store from '@/store'

import { TUnits, TUnitTypes } from '@/types'
import { getUnits, getUnitTypes, converseUnits } from '@/services/units'

export interface IUnitState {
  units: TUnits
  unitTypes: TUnitTypes
}

@Module({ dynamic: true, store, name: 'unit' })
class UnitState extends VuexModule implements IUnitState {
  public units: TUnits = []

  public unitTypes: TUnitTypes = []

  @Mutation
  public SetUnits(units: TUnits) {
    this.units = units
  }

  @Mutation
  public SetUnitTypes(unitTypes: TUnitTypes) {
    this.unitTypes = unitTypes
  }

  @Mutation
  public ClearState() {
    this.units = []
    this.unitTypes = []
  }

  @Action
  public async GET_UNITS(withoutNotification = false) {
    if (!this.units.length) {
      const data = await getUnits(withoutNotification)
      this.SetUnits(data)
    }
  }

  @Action
  public async GET_UNITTYPES(withoutNotification = false) {
    if (!this.unitTypes.length) {
      const data = await getUnitTypes(withoutNotification)
      this.SetUnitTypes(data)
    }
  }

  @Action
  public async CONVERSE_UNITS(data: {
    sourceVal: number | null
    sourceUnit: number
    destUnit: number
  }): Promise<number | null> {
    if (data.sourceVal !== null) {
      const result = await converseUnits(
        data.sourceVal,
        data.sourceUnit,
        data.destUnit
      )
      return result
    } else return null
  }

  @Action
  public CLEAR_UNIT_STATES() {
    this.ClearState()
  }
}

export const Unit = getModule(UnitState)
