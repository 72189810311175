
import { Component, Mixins, Watch } from 'vue-property-decorator'
import {
  WPCard,
  WPSwitch,
  WPButtonIcon,
  WPTextfield,
  WPTextfieldMeasure,
  WPRadioGroup,
  WPBlockBtn,
  WPDivider,
  WPInfoBlock,
  WPButtonFileIcon,
  WPBlockHint,
  WPExpPanel,
  WPLoader,
  WPEmptyList,
  WPGroup,
  WPButton,
  WPSelect,
  WPOpacity,
  WPGroupMultiline,
  WPCheckbox,
  WPDatetimepicker,
  WPInfoProfileBlock,
} from '@/components'
import {
  IProfileCheckStructData,
  IProfilePoint,
  IWellboreValidation,
  TCheckStructResult,
} from '@/types'
import { RouteParamsMixin } from '@/mixins'
import { Wellbore } from '@/store/wellbore'
import { Unit } from '@/store/units'
import { eventBus } from '@/helpers/eventBus'
import { checkStructText, isNotNull } from '@/services/common'
import { Init } from '@/store/init'

export interface IProfilePointUnitKeys {
  depthpointUc: number
  zenithUc: number
  azimuthUc: number
  upIntervalUc: number
  downIntervalUc: number
}

interface IWellboreStatusesList {
  status: 1 | 2 | 3
  id: number
}

type TWellboreStatusesList = IWellboreStatusesList[]

@Component({
  components: {
    WPCard,
    WPButtonIcon,
    WPTextfield,
    WPTextfieldMeasure,
    WPRadioGroup,
    WPBlockBtn,
    WPDivider,
    WPButtonFileIcon,
    WPInfoBlock,
    WPSwitch,
    WPBlockHint,
    WPExpPanel,
    WPGroup,
    WPOpacity,
    WPEmptyList,
    WPLoader,
    WPButton,
    WPSelect,
    WPGroupMultiline,
    WPCheckbox,
    WPDatetimepicker,
    WPInfoProfileBlock,
  },
})
export default class TheWellboreProfile extends Mixins(RouteParamsMixin) {
  private readonly emptyTitle = this.$t('wellboreProfile.empty')

  private depthpoint: number | null = null

  private zenith: number | null = null

  private azimuth: number | null = null

  private status: number | null = null

  private actual = false

  private versionDifference = false

  private doubtful = false

  private measurementDateTime: string | null = null

  private depthpointUc = 100

  private zenithUc = 1201

  private azimuthUc = 1201

  private valid = false

  private maxDepth = 0

  private editIndex: number | null = null

  private editId: number | null = null

  private filesKey = 0

  private busy = false

  private loading = false

  private normalizeLoading = false

  private selectedAzimuth = 1

  private isEditDialog = false

  private xlsDialog = false

  private clearDialog = false

  private clearLoading = false

  private isExcelImportDialog = false

  private isReplaceXlsPoints = false

  private isCheckProfileDialog = false

  private downInterval: number | null = null

  private upInterval: number | null = 0

  private downIntervalUc = 100

  private upIntervalUc = 100

  private migrateDialog = false

  private migrateLoading = false

  private wellboreStatusesList: TWellboreStatusesList = []

  private withProcessing = false

  private get isTransparentCard() {
    return (
      this.isEditDialog || this.isExcelImportDialog || this.isCheckProfileDialog
    )
  }

  private get pointStatuses() {
    return Wellbore.pointStatuses
  }

  private get initOptions() {
    return Init.settings
  }

  private get azimuts() {
    return [
      {
        text: this.$t('wellboreProfile.optionM'),
        typeText: this.$t('wellboreProfile.azimuthTypeM'),
        value: 1,
      },
      {
        text: this.$t('wellboreProfile.optionG'),
        typeText: this.$t('wellboreProfile.azimuthTypeG'),
        value: 2,
      },
      {
        text: this.$t('wellboreProfile.optionGr'),
        typeText: this.$t('wellboreProfile.azimuthTypeGr'),
        value: 3,
      },
    ]
  }

  private get profilePointsFullLoaded() {
    return Wellbore.counts.profilePoints === Wellbore.profilePoints.length
  }

  private get profilePoints() {
    return Wellbore.profilePoints
  }

  private get wellboreValidation() {
    return Wellbore.wellboreValidation
  }

  private get isEdit() {
    return this.editIndex !== null
  }

  private async recalculateDepth() {
    const depth = await Unit.CONVERSE_UNITS({
      sourceVal: Number(this.wellbore?.project_depth),
      sourceUnit: Number(this.wellbore?.project_depth_uc),
      destUnit: Number(this.depthpointUc),
    })

    this.maxDepth = depth !== null ? depth : 0
  }

  private goToGraph() {
    void this.$router.push({
      name: 'ThwWellboreProfileGraph',
      params: {
        id: String(this.fieldId),
        wellId: String(this.wellId),
        wellboreId: String(this.wellboreId),
      },
    })
  }

  private openEditDialog() {
    this.depthpoint = null
    this.zenith = null
    this.azimuth = null
    this.isEditDialog = true
    this.versionDifference = false
  }

  private cancel() {
    if (this.isEditDialog) {
      this.depthpoint = null
      this.zenith = null
      this.azimuth = null
      this.editIndex = null
      this.editId = null
      this.isEditDialog = false
      this.versionDifference = false
      this.resetForm()
    } else
      void this.$router.push({
        name: 'TheWellbore',
        params: {
          id: String(this.fieldId),
          wellId: String(this.wellId),
          wellboreId: String(this.wellboreId),
        },
      })
  }

  private changeMeasure(val: number, key: keyof IProfilePointUnitKeys) {
    this[key] = val
  }

  private async addPoint() {
    this.busy = true
    if (this.isEdit) {
      this.busy = false
      await this.savePoint()
      return
    }
    const point: IProfilePoint = {
      depthpoint: Number(this.depthpoint),
      zenith: Number(this.zenith),
      azimuth: Number(this.azimuth),
      used_azimuth_type: Number(this.selectedAzimuth),
      depthpoint_uc: Number(this.depthpointUc),
      zenith_uc: Number(this.zenithUc),
      azimuth_uc: Number(this.azimuthUc),
      wellbore: Number(this.wellbore?.id),
      status: Number(this.status),
      doubtful: this.doubtful,
      actual: this.actual,
      version_difference: this.versionDifference,
      measurement_date_time: this.measurementDateTime,
    }

    if (this.withProcessing) {
      const success = await Wellbore.ADD_PROFILEPOINTS_INTEGRATION(point)
      if (!success) {
        this.busy = false
        return
      }
    } else {
      const success = await Wellbore.ADD_PROFILEPOINTS(point)
      if (!success) {
        this.busy = false
        return
      }
    }
    await Wellbore.GET_WELLBORE_VALIDATION(Number(this.wellboreId))
    this.depthpoint = null
    this.zenith = null
    this.azimuth = null
    this.resetForm()
  }

  private async editPoint(item: IProfilePoint, index: number) {
    if (this.editId !== item.id) {
      this.editIndex = index
      this.depthpoint = item.depthpoint
      this.depthpointUc = item.depthpoint_uc
      this.zenith = item.zenith
      this.azimuth = item.azimuth
      this.selectedAzimuth = item.used_azimuth_type
      this.zenithUc = item.zenith_uc
      this.azimuthUc = item.azimuth_uc
      this.status = item.status
      this.doubtful = item.doubtful
      this.actual = item.actual
      this.versionDifference = item.version_difference
      this.measurementDateTime = item.measurement_date_time
      this.editId = Number(item.id)
      this.isEditDialog = true
      await this.recalculateDepth()
    }
  }

  private resetForm() {
    const form = this.$refs.profileForm as Vue & { resetValidation: () => void }
    if (form) form.resetValidation()
    this.busy = false
  }

  private getItemColor(status: number) {
    switch (status) {
      case 1:
        return '#0094D9'
      case 2:
        return '#009639'
      default:
        return ''
    }
  }

  private getItemStatus(status: number) {
    return (
      this.pointStatuses.find((item) => item.code === status)?.display_name ||
      ''
    )
  }

  private getUnitSymbol(code: number) {
    return Unit.units.find((item) => item.code === code)?.symbol
  }

  private getBlockInfo(
    text: string,
    value: number | string,
    code: number,
    status?: string
  ) {
    const unit = this.getUnitSymbol(code)
    let result = `${text} ${String(value)} ${String(unit)}`

    if (status) {
      return `${result} – ${status}`
    } else {
      return result
    }
  }

  private getAzimuthType(type: number) {
    const item = this.azimuts.find((item) => item.value === type)
    if (item) {
      return item.typeText
    } else return ''
  }

  private async savePoint() {
    const point = {
      depthpoint: Number(this.depthpoint),
      zenith: Number(this.zenith),
      azimuth: Number(this.azimuth),
      depthpoint_uc: Number(this.depthpointUc),
      zenith_uc: Number(this.zenithUc),
      used_azimuth_type: Number(this.selectedAzimuth),
      azimuth_uc: Number(this.azimuthUc),
      wellbore: Number(this.wellboreId),
      id: Number(this.editId),
      status: Number(this.status),
      doubtful: this.doubtful,
      actual: this.actual,
      version_difference: this.versionDifference,
      measurement_date_time: this.measurementDateTime,
    }

    await Wellbore.SAVE_PROFILEPOINTS(point)
    await Wellbore.GET_WELLBORE_VALIDATION(Number(this.wellboreId))
    this.busy = false
  }

  private async downloadFile() {
    const file = await Wellbore.GET_PROFILEPOINTS_FILE(this.withProcessing)
    if (file) {
      const url = window.URL.createObjectURL(new Blob([file]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', String(this.$t('wellboreProfile.fileName')))
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
  }

  private async uploadFile(file: Blob) {
    if (file) {
      this.busy = true
      const wellbore = Number(this.$route.params.wellboreId)
      const result = this.withProcessing
        ? await Wellbore.UPLOAD_PROFILEPOINTS_FILE_INTEGRATION({
            wellbore,
            clear: this.isReplaceXlsPoints,
            file,
          })
        : await Wellbore.UPLOAD_PROFILEPOINTS_FILE({
            wellbore,
            clear: this.isReplaceXlsPoints,
            file,
          })
      this.closeXlsDialog()
      if (result) {
        this.isExcelImportDialog = false
        await Wellbore.RELOAD_PROFILEPOINTS(wellbore)
        await Wellbore.GET_WELLBORE_VALIDATION(Number(this.wellboreId))
      }
      this.filesKey++
      this.busy = false
    }
  }

  private openXlsDialog() {
    if (this.profilePoints.length) {
      this.xlsDialog = true
    } else {
      this.onClickXlsDialog()
    }
  }

  private openExcelImportDialog() {
    this.isExcelImportDialog = true
  }

  private closeExcelImportDialog() {
    this.isExcelImportDialog = false
  }

  private closeXlsDialog() {
    this.xlsDialog = false
  }

  private async clearProfilePoints() {
    this.clearLoading = true
    await Wellbore.CLEAR_PROFILEPOINTS({ id: Number(this.wellboreId) })
    await Wellbore.GET_PROFILEPOINTS({
      wellboreId: Number(this.wellboreId),
      clear: true,
    })
    this.clearDialog = false
    this.clearLoading = false
  }

  private toggleMigrateProfile() {
    this.migrateDialog = true
  }

  private async migrateProfile() {
    this.migrateLoading = true
    const destStatus =
      this.wellbore?.status === 2 ? 3 : this.wellbore?.status === 3 ? 2 : 0
    const destId = this.wellboreStatusesList.find(
      (item) => item.status === destStatus
    )?.id

    if (destId) {
      await Wellbore.TRANSFER_INCLINOMETRY({
        id: Number(this.wellbore?.id),
        destId: Number(destId),
      })
      eventBus.$emit('showAlert', 'transfer')
    }

    this.migrateDialog = false
    this.migrateLoading = false
  }

  private getWellboreStatuses() {
    let result: TWellboreStatusesList = []
    const statuses = [1, 2, 3]
    if (this.wellbore?.activate_integration) {
      statuses.map(async (status) => {
        const res = await Wellbore.GET_WELLBORE_BY_STATUS({
          wellbore: Number(this.wellboreId),
          status,
        })
        result.push({
          status: status,
          id: Number(res?.id),
        } as IWellboreStatusesList)
      })
    }
    this.wellboreStatusesList = result
  }

  private toggleClearProfilePoints() {
    this.clearDialog = true
  }

  private checkProfileDialogOpen() {
    this.isCheckProfileDialog = true
  }

  private closeCheckProfileDialog() {
    this.isCheckProfileDialog = false
  }

  private async normalizeInclinometry() {
    this.normalizeLoading = true
    const result = await Wellbore.NORMALIZE_INCLINOMETRY(
      Number(this.wellboreId)
    )

    if (result) {
      await this.onLoadPoints(true)
      eventBus.$emit('showAlert', 'normalize')
    }
    this.normalizeLoading = false
  }

  private async checkProfile() {
    const data: IProfileCheckStructData = {
      wellbore: Number(this.wellboreId),
      up: Number(this.upInterval),
      up_uc: this.upIntervalUc,
      down: Number(this.downInterval),
      down_uc: this.downIntervalUc,
    }

    if (
      isNotNull(data.up) &&
      isNotNull(data.down) &&
      data.up_uc &&
      data.down_uc
    ) {
      const upInt = await Unit.CONVERSE_UNITS({
        sourceVal: Number(data.up),
        sourceUnit: data.up_uc,
        destUnit: 100,
      })
      const downInt = await Unit.CONVERSE_UNITS({
        sourceVal: Number(data.down),
        sourceUnit: data.down_uc,
        destUnit: 100,
      })
      if (upInt !== null && downInt !== null && upInt <= downInt) {
        const result = await Wellbore.CHECK_PROFILE(data)
        const text = checkStructText(
          result as TCheckStructResult,
          String(this.$t('wellbore.warningText')),
          String(this.$t('wellbore.errorText')),
          String(this.$t('wellbore.criticalText'))
        )

        eventBus.$emit('showDialog', {
          title: this.$t('wellboreProfile.dialogCheckProfileTitle'),
          text:
            result && result.length
              ? text
              : this.$t('wellboreProfile.dialogCheckProfileSuccess'),
          btnDismissText: this.$t('common.back'),
        })
      } else {
        eventBus.$emit('showError', 'calcVolumeIntervalError')
        return
      }
    }
  }

  private onClickXlsDialog(isReplace = false) {
    this.isReplaceXlsPoints = isReplace
    const input = this.$refs['fileInput'] as HTMLElement
    input.click()
  }

  private async onLoadPoints(clear = false) {
    if (clear || (!this.busy && !this.profilePointsFullLoaded)) {
      this.busy = true
      await Wellbore.GET_PROFILEPOINTS({
        wellboreId: Number(this.$route.params.wellboreId),
        clear,
      })
      this.busy = false
    }
  }

  @Watch('depthpointUc')
  private async onDPUC(val: number) {
    await this.recalculateDepth()
  }

  @Watch('isEditDialog')
  private onChangeEditDialogValue(val: boolean) {
    eventBus.$emit('disableWellboreTypes', val)
  }

  @Watch('wellboreValidation')
  private onChangeValidation(val: IWellboreValidation) {
    this.downInterval = val.max_profile_points_depth
    this.downIntervalUc = 100
    this.upInterval = 0
    this.upIntervalUc = 100
  }

  private async onDelete(id: number) {
    this.busy = true
    await Wellbore.DELETE_PROFILEPOINTS({
      pointId: id,
      wellboreId: Number(this.wellbore?.id),
    })
    await Wellbore.GET_WELLBORE_VALIDATION(Number(this.wellboreId))
    this.busy = false
  }

  private async mounted() {
    await this.onLoadPoints()
    await Wellbore.GET_WELLBORE_VALIDATION(Number(this.wellboreId))
    await Wellbore.GET_PROFILEPOINTS_STATUSES()
    this.selectedAzimuth = this.wellbore?.used_azimuth_type || 1
    await this.recalculateDepth()
    void this.getWellboreStatuses()
  }
}
