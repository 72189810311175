import { Component, Vue, Watch } from 'vue-property-decorator'

import { Common } from '@/store/common'
import { Field } from '@/store/field'
import { Dictionary } from 'vue-router/types/router'
import { eventBus } from '@/helpers/eventBus'
@Component
class RouteParamsMixin extends Vue {
  public fieldId = this.$route.params.id || this.$route.query.field

  public wellId = this.$route.params.wellId || this.$route.query.well

  public wellboreId =
    this.$route.params.wellboreId || this.$route.query.wellbore

  public get field() {
    return Common.field
  }

  public get well() {
    return Common.well
  }

  public get wellbore() {
    return Common.wellbore
  }

  public emit404() {
    void this.$router.push({ name: 'PageNotFound' })
  }

  public async onChangeParamsOrQuery(val: Dictionary<string>, isParams = true) {
    if (val && !!Object.keys(val).length) {
      const fieldId = isParams ? val.id : val.field
      if (fieldId) {
        if (Number(this.field?.id) !== Number(fieldId)) {
          const result = await Field.GET_FIELD_BY_ID(Number(fieldId))
          void Common.SET_COMMON_FIELD(result)
        }
      } else {
        void Common.SET_COMMON_FIELD(null)
      }

      const wellId = isParams ? val.wellId : val.well

      if (wellId) {
        if (Number(this.well?.id) !== Number(wellId)) {
          const result = await Field.GET_WELL(Number(wellId))
          if (
            typeof result?.field === 'object' &&
            Number(result?.field.id) !== Number(fieldId)
          ) {
            this.emit404()
            return
          }
          void Common.SET_COMMON_WELL(result)
        }
      } else {
        void Common.SET_COMMON_WELL(null)
      }

      const wellboreIdParam = isParams ? val.wellboreId : val.wellbore

      if (wellboreIdParam) {
        if (Number(this.wellbore?.id) !== Number(wellboreIdParam)) {
          const result = await Field.GET_WELLBORE(Number(wellboreIdParam))
          if (
            typeof result?.well === 'object' &&
            (Number(result?.well.id) !== Number(wellId) ||
              Number(result?.well.field) !== Number(fieldId))
          ) {
            this.emit404()
            return
          }
          this.wellboreId = wellboreIdParam
          void Common.SET_COMMON_WELLBORE(result)
          eventBus.$emit('wellboreStatusLoading', false)
        }
      } else {
        void Common.SET_COMMON_WELLBORE(null)
      }
    }
  }

  @Watch('$route.query', {
    immediate: true,
    deep: true,
  })
  private async onChangeQuery(val: Dictionary<string>) {
    if (val.field || val.well || val.wellbore) {
      await this.onChangeParamsOrQuery(val, false)
    }
  }

  @Watch('$route.params', {
    immediate: true,
    deep: true,
  })
  private async onChangeParams(val: Dictionary<string>) {
    await this.onChangeParamsOrQuery(val, true)
  }
}

export default RouteParamsMixin
