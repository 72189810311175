var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"wp-accordion"},[_c('div',{staticClass:"wp-accordion__icons",class:{
      'wp-accordion__icons_first': _vm.active === 0,
      'wp-accordion__icons_last': _vm.active === _vm.data.length - 1,
    }},_vm._l((_vm.data),function(item,index){return _c('div',{key:index,staticClass:"wp-accordion__icon-block",class:{ 'wp-accordion__icon-block_active': _vm.active === index },on:{"click":function($event){return _vm.changeActive(index)}}},[_c('img',{staticClass:"wp-accordion__icon",attrs:{"src":_vm.iconPath(item.icon, index)}})])}),0),(_vm.activeItem)?[_c('div',{staticClass:"wp-accordion__spacer",style:({ left: _vm.spacerPos, width: _vm.spacerWidth })}),_c('div',{staticClass:"wp-accordion__text",class:{
        'wp-accordion__text_first': _vm.active === 0,
        'wp-accordion__text_last': _vm.active === _vm.data.length - 1,
      }},[_c('h2',{staticClass:"wp-accordion__title"},[_vm._v(_vm._s(_vm.activeItem.title))]),_c('div',{staticClass:"wp-accordion__description"},[_vm._v(" "+_vm._s(_vm.activeItem.description)+" ")])]),_c('div',{staticClass:"wp-accordion__footer",class:{ 'wp-accordion__footer_solo': _vm.isLast }},[_vm._t("default"),(!_vm.isLast)?_c('WPButton',{on:{"click":function($event){return _vm.changeActive()}}},[_c('span',[_vm._v(_vm._s(_vm.$t('onboarding.next'))+"  ")]),_c('img',{staticClass:"wp-accordion__arrow",attrs:{"src":require("@/assets/icons/arrow.svg")}})]):_vm._e()],2)]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }