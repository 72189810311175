
import { eventBus } from '@/helpers/eventBus'
import { Component, Vue } from 'vue-property-decorator'

@Component
export default class WPLocaleSwitcher extends Vue {
  private isLangActive(val: string) {
    return this.$i18n.locale === val
  }

  private onClick(val: string) {
    localStorage.setItem('lang', val)
    this.$i18n.locale = val
    eventBus.$emit('localeSwitch', val)
  }
}
