
import { Component, Vue } from 'vue-property-decorator'
import {
  WPCard,
  WPButtonMenu,
  WPButtonIcon,
  WPOpacity,
  WPLayoutInDev,
  WPTitle,
} from '@/components'
import { Field } from '@/store/field'
import { Calculator } from '@/store/calculators'

@Component({
  components: {
    WPCard,
    WPButtonMenu,
    WPButtonIcon,
    WPOpacity,
    WPLayoutInDev,
    WPTitle,
  },
})
export default class Calculators extends Vue {
  private loading = true

  private selectedCalculator = this.$route.query?.calculator || ''

  private codeSelectedCalculator = 0

  private get wells() {
    return !!Field.wellsFull.length
  }

  private get isNotMainPage() {
    return this.$route.name !== 'Calculators'
  }

  private get isEmptyPage() {
    return this.$route.name === 'CalculatorsEmpty'
  }

  private goTo(path = '', name = '', code = 0) {
    if (path && this.wells) {
      Calculator.SET_SELECTED_CALCULATOR(name)
      this.selectedCalculator = path
      this.codeSelectedCalculator = code
      void this.$router.push({
        name: `CalculatorsSelects`,
      })
    } else if (!this.wells)
      void this.$router.push({
        name: 'CalculatorsEmpty',
      })
  }

  private checkSelectedCalc(name = '') {
    return this.isNotMainPage && this.selectedCalculator === name
  }

  private async mounted() {
    await Field.GET_WELLS_FULL({})
    this.loading = false
  }
}
