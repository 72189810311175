var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('VExpansionPanels',{staticClass:"wp-exp-panel",class:{
    'wp-exp-panel_active': _vm.isOpened === 0,
  },attrs:{"disabled":_vm.disabled},model:{value:(_vm.isOpened),callback:function ($$v) {_vm.isOpened=$$v},expression:"isOpened"}},[_c('VExpansionPanel',{staticClass:"wp-exp-panel__wrapper",attrs:{"readonly":_vm.alwaysActive}},[_c('VExpansionPanelHeader',{staticClass:"wp-exp-panel__header",class:{
        'wp-exp-panel__header_large': _vm.large,
      },attrs:{"hide-actions":_vm.alwaysActive},on:{"click":_vm.onClick}},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('VExpansionPanelContent',{staticClass:"wp-exp-panel__content",class:{
        'wp-exp-panel__content_nopad': _vm.large || _vm.nopad,
      }},[_vm._t("default")],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }