import { Component, Vue } from 'vue-property-decorator'

import { Wellbore } from '@/store/wellbore'

@Component
class EquipsSearchMixin extends Vue {
  public selectLoadingEquips = false

  public searchTextEquips: string | undefined = ''

  public get queryEquips() {
    return this.searchTextEquips ? { name: this.searchTextEquips } : undefined
  }

  public async onSearchEquips(search = '') {
    this.searchTextEquips = search
    this.selectLoadingEquips = true
    void Wellbore.CLEAR_DATA('equips')
    await this.onLoadEquips()
    this.selectLoadingEquips = false
  }

  public async onLoadEquips() {
    await Wellbore.GET_EQUIPS({ wellboreId: -1 })
  }
}

export default EquipsSearchMixin
