
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class WPButtonMenu extends Vue {
  @Prop({ default: null })
  private readonly index?: number | null

  @Prop({ default: false })
  private readonly ready?: boolean

  @Prop({ default: false })
  private readonly selected?: boolean

  @Prop({ default: false })
  private readonly mini?: boolean

  @Prop({ default: true })
  private readonly margin?: boolean

  @Prop({ default: false })
  private readonly error?: boolean
}
