var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"wp-block-btn",class:{
    'wp-block-btn_hidden-icons': _vm.hiddenIcons,
    'wp-block-btn_multi': _vm.multiActionBtn,
  },on:{"mouseover":function($event){return _vm.$emit('hover')},"mouseleave":function($event){return _vm.$emit('blur')}}},[_c('div',{ref:"wpBlockBtnSlot",staticClass:"wp-block-btn__content"},[_vm._t("default")],2),_c('div',{staticClass:"wp-block-btn__icon",class:{
      'wp-block-btn__icon_disabled': _vm.disabled,
      'wp-block-btn__icon_bottom': _vm.bottomIcon,
      'wp-block-btn__icon_with-btn': _vm.withBtn,
      'wp-block-btn__icon_top': _vm.topIcon,
    },style:({ 'max-height': `${_vm.height}px` })},[(_vm.isDelete)?[_c('div',{staticClass:"wp-block-btn__icon-wrapper",class:{
          'wp-block-btn__icon-wrapper_opened': _vm.isConfirm,
          'wp-block-btn__icon-wrapper_multi': _vm.multiActionBtn,
        }},[_c('div',{staticClass:"wp-block-btn__confirm"},[_c('VIcon',{staticClass:"wp-block-btn__confirm_check mb-1",attrs:{"size":"28","color":"green"},on:{"click":_vm.emitClick}},[_vm._v("mdi-check")]),_c('VIcon',{staticClass:"wp-block-btn__confirm_cancel",attrs:{"size":"28","color":"red"},on:{"click":_vm.cancel}},[_vm._v("mdi-close")])],1),(_vm.multiActionBtn)?[_c('div',{staticClass:"wp-block-btn__icons-multi"},[_c('div',{staticClass:"wp-block-btn__multi"},[_c('VIcon',{staticClass:"wp-block-btn__icon-icon wp-block-btn__icon-icon_action",attrs:{"size":"28"},on:{"click":function($event){return _vm.onClick('multi')}}},[_vm._v(" "+_vm._s(_vm.multiActionBtnIcon)+" ")])],1),_c('div',{staticClass:"wp-block-btn__icons"},[(_vm.actionBtn)?_c('VIcon',{staticClass:"wp-block-btn__icon-icon wp-block-btn__icon-icon_action mb-1",attrs:{"size":"24"},on:{"click":function($event){return _vm.onClick('action')}}},[_vm._v(" "+_vm._s(_vm.actionBtnIcon)+" ")]):_vm._e(),_c('VIcon',{staticClass:"wp-block-btn__icon-icon wp-block-btn__icon-icon_click",attrs:{"size":"28"},on:{"click":function($event){return _vm.onClick('click')}}},[_vm._v(" "+_vm._s(_vm.icon)+" ")])],1)])]:[_c('div',{staticClass:"wp-block-btn__icons"},[(_vm.actionBtn)?_c('VIcon',{staticClass:"wp-block-btn__icon-icon wp-block-btn__icon-icon_action mb-1",attrs:{"size":"24"},on:{"click":function($event){return _vm.onClick('action')}}},[_vm._v(" "+_vm._s(_vm.actionBtnIcon)+" ")]):_vm._e(),_c('VIcon',{staticClass:"wp-block-btn__icon-icon wp-block-btn__icon-icon_click",attrs:{"size":"28"},on:{"click":function($event){return _vm.onClick('click')}}},[_vm._v(" "+_vm._s(_vm.icon)+" ")])],1)]],2)]:_c('div',[_c('div',{staticClass:"wp-block-btn__icons"},[_c('VIcon',{staticClass:"wp-block-btn__icon-icon",attrs:{"size":"28"},on:{"click":_vm.emitClick}},[_vm._v(_vm._s(_vm.icon))])],1)])],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }