
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class WPBlockBtn extends Vue {
  @Prop({ default: 'mdi-trash-can-outline' })
  private icon?: string

  @Prop({ default: false })
  private actionBtn?: boolean

  @Prop({ default: 'mdi-content-copy' })
  private actionBtnIcon?: string

  @Prop({ default: false })
  private multiActionBtn?: boolean

  @Prop({ default: 'mdi-arrow-collapse-vertical' })
  private multiActionBtnIcon?: string

  @Prop({ default: true })
  private actionBtnConfirm?: boolean

  @Prop({ default: false })
  private disabled?: boolean

  @Prop({ default: false })
  private hiddenIcons?: boolean

  @Prop({ default: false })
  private bottomIcon?: boolean

  @Prop({ default: false })
  private withBtn?: boolean

  @Prop({ default: false })
  private topIcon?: boolean

  private height: number | null = null

  private event = 'click'

  private isConfirm = false

  private timer = 0

  private get isDelete() {
    return this.icon === 'mdi-trash-can-outline'
  }

  private emitClick() {
    if (!this.disabled) {
      this.isConfirm = false
      this.$emit(this.event)
    }
  }

  private cancel() {
    this.isConfirm = false
  }

  private onClick(event: string) {
    if (!this.disabled) {
      this.event = event
      if (this.actionBtnConfirm || this.event === 'click') {
        this.isConfirm = true
      } else {
        this.$emit(this.event)
      }
    }
  }

  private mounted() {
    this.timer = setTimeout(() => {
      const elem: Element = this.$refs.wpBlockBtnSlot as Element
      let height = elem ? elem.clientHeight : 0
      if (this.withBtn) height = height - 44
      this.height = height
    }, 0)
  }

  private beforeDestroy() {
    void clearTimeout(this.timer)
  }
}
