
import { Component, Vue, Prop } from 'vue-property-decorator'
import { WPTextInfo, WPBlockInfo, WPMiniHeader, WPDivider } from '@/components'

import { Unit } from '@/store/units'
import { Wellbore } from '@/store/wellbore'

import { TElements, TEquipElements } from '@/types'

@Component({
  components: {
    WPTextInfo,
    WPBlockInfo,
    WPMiniHeader,
    WPDivider,
  },
})
export default class CalculatorsWellInfoElements extends Vue {
  @Prop({ type: Array, required: true })
  private elements?: TElements | TEquipElements

  @Prop({ type: Boolean, default: false })
  private isEquip?: boolean

  private get elementAreas() {
    return Wellbore.areas
  }

  private get elementEquipAreas() {
    return Wellbore.equipeAreas
  }

  private getUnitSymbol(code: number) {
    return String(Unit.units.find((item) => item.code === code)?.symbol)
  }

  private getUnitInfo(up: number, upUc: number, down: number, downUc: number) {
    let result = `${up} ${this.getUnitSymbol(upUc)}`
    return down && downUc
      ? `${result} – ${down} ${this.getUnitSymbol(downUc)}`
      : result
  }

  private getAreas(element: number) {
    return this.isEquip
      ? this.elementEquipAreas.filter((item) => {
          if (typeof item.equipe_element === 'number') {
            return element === item.equipe_element
          } else if (typeof item.equipe_element === 'object') {
            return element === item.equipe_element?.id
          } else return false
        })
      : this.elementAreas.filter((item) => {
          if (typeof item.element === 'number') {
            return element === item.element
          } else if (typeof item.element === 'object') {
            return element === item.element?.id
          } else return false
        })
  }
}
