var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.isForm)?_c('VForm',{staticClass:"wp-card__form",class:{
    'wp-card__form_fullscreen': _vm.fullscreen,
  },model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('VCard',{staticClass:"wp-card",class:{
      'wp-card_flat': _vm.flat,
      'wp-card_modal': _vm.modal,
      'wp-card_no-title': !_vm.title,
      'wp-card_fullscreen': _vm.fullscreen,
      'wp-card_mobile-view': _vm.isMobileView,
    },attrs:{"width":_vm.width}},[(_vm.title && !_vm.isMobileView)?_c('div',{staticClass:"d-flex wp-card__header",class:{ 'pb-2 mb-2': !_vm.flat && !_vm.isEmptyCard }},[(_vm.hint)?_c('h6',[_c('WPBlockHint',{attrs:{"text":_vm.hint}},[_vm._v(_vm._s(_vm.title))])],1):_c('h6',[_vm._v(_vm._s(_vm.title))]),(_vm.cancelBtn)?_c('WPButtonPlain',{staticClass:"wp-card__close ml-auto",on:{"click":function($event){return _vm.$emit('cancel')}}},[(_vm.cancelBtnText)?[_vm._v(" "+_vm._s(_vm.getCancelBtnText)+" ")]:_c('VIcon',{attrs:{"color":"red","size":"22"}},[_vm._v(" "+_vm._s(_vm.cancelBtnIcon)+" ")])],2):_vm._e()],1):_vm._e(),_c('div',{staticClass:"wp-card__content",class:{
        'wp-card__content_full': _vm.fullHeight,
        'wp-card__content_mobile-view': _vm.isMobileView,
      }},[_vm._t("default"),(_vm.addBtn)?_c('div',{staticClass:"d-flex"},[_c('WPButton',{staticClass:"mx-auto mt-6",class:{ 'mt-6': !_vm.isEmptyCard },attrs:{"disabled":!_vm.valid || _vm.disabled},on:{"click":_vm.onButtonClick}},[_vm._v(" "+_vm._s(_vm.getBtnText)+" ")])],1):_vm._e()],2),(_vm.footer)?_c('div',{staticClass:"d-flex wp-card__footer"},[_vm._t("footer")],2):_vm._e()])],1):_c('VCard',{staticClass:"wp-card",class:{
    'wp-card_flat': _vm.flat,
    'wp-card_modal': _vm.modal,
    'wp-card_no-title': !_vm.title,
    'wp-card_mobile-view': _vm.isMobileView,
  }},[(_vm.title)?_c('div',{staticClass:"d-flex wp-card__header",class:{ 'mb-4': !_vm.flat && !_vm.isEmptyCard }},[_c('h6',[_vm._v(_vm._s(_vm.title))]),(_vm.cancelBtn)?_c('WPButtonPlain',{staticClass:"wp-card__close ml-auto",on:{"click":function($event){return _vm.$emit('cancel')}}},[(_vm.cancelBtnText)?[_vm._v(" "+_vm._s(_vm.getCancelBtnText)+" ")]:_c('VIcon',{attrs:{"color":"red","size":"22"}},[_vm._v(" "+_vm._s(_vm.cancelBtnIcon)+" ")])],2):_vm._e()],1):_vm._e(),_c('div',{staticClass:"wp-card__content"},[_vm._t("default"),(_vm.addBtn)?_c('div',{staticClass:"d-flex"},[_c('WPButton',{staticClass:"mx-auto mt-6",class:{ 'mt-6': !_vm.isEmptyCard },attrs:{"disabled":_vm.disabled},on:{"click":_vm.onButtonClick}},[_vm._v(" "+_vm._s(_vm.getBtnText)+" ")])],1):_vm._e()],2),(_vm.footer)?_c('div',{staticClass:"d-flex wp-card__footer"},[_vm._t("footer")],2):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }