import { Component, Vue } from 'vue-property-decorator'

import { IWellboreTypes } from '@/types'
import { Field } from '@/store/field'

@Component
class wellboresStatusNameMixin extends Vue {
  private get wellbores() {
    return Field.wellbores.map((item) => {
      return {
        ...item,
        name: item.activate_integration
          ? `${item.name} – ${String(this.getStatusWellbore(item.status))}`
          : item.name,
      }
    })
  }

  private get statuses(): IWellboreTypes[] {
    return [
      {
        code: 1,
        name: 'Static',
        display_name: String(this.$t('wellbore.activation.staticWellbore')),
      },
      {
        code: 2,
        name: 'Dynamic',
        display_name: String(this.$t('wellbore.activation.dynamicWellbore')),
      },
      {
        code: 3,
        name: 'Fact',
        display_name: String(this.$t('wellbore.activation.factWellbore')),
      },
    ]
  }

  private getStatusWellbore(status?: 1 | 2 | 3) {
    return status
      ? this.statuses.find((item) => item.code === status)?.display_name
      : ''
  }
}

export default wellboresStatusNameMixin
