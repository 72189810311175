
import { Component, Prop, Vue } from 'vue-property-decorator'

import WPTextfield from '../WPTextfield.vue'

@Component({
  components: {
    WPTextfield,
  },
})
export default class WPBaseSelect extends Vue {
  @Prop({ default: () => [] })
  private readonly items?: any[]

  @Prop({ default: '' })
  private readonly value?: string

  @Prop({ default: '' })
  private readonly label?: string

  @Prop({ default: '' })
  private readonly hint?: string

  @Prop({ default: false })
  private readonly white?: boolean

  @Prop({ default: '' })
  private readonly itemText?: string

  @Prop({ default: '' })
  private readonly itemValue?: string

  @Prop({ default: '' })
  private readonly name?: string

  @Prop({ default: 'auto' })
  private readonly hideDetails?: string | boolean

  @Prop({ default: true })
  private readonly outlined?: boolean

  @Prop({ default: () => [] })
  private readonly rules?: string[]

  @Prop({ default: false })
  private readonly disabled?: boolean

  @Prop({ default: false })
  private readonly clearable?: boolean

  @Prop({ default: '' })
  private readonly noDataText?: string

  @Prop({ default: false })
  private readonly isMeasure?: boolean

  @Prop({ default: false })
  private readonly loading?: boolean

  @Prop({ default: false })
  private readonly search?: boolean

  @Prop({ default: null })
  private readonly searchText?: string | null

  @Prop({ default: false })
  private readonly returnObject?: boolean

  private inputWidth: number | null = null

  private randomKey = 0

  private get computedValue() {
    return this.value
  }

  private set computedValue(value) {
    this.$emit('input', value)
  }

  private onSearch(val: string) {
    if (this.search) this.$emit('onSearch', val)
  }

  private onLoadItems() {
    this.$emit('onLoadItems')
  }

  private created() {
    this.randomKey = Math.floor(Math.random() * 10000)
  }

  private mounted() {
    setTimeout(() => {
      const element = this.$refs[`inputSelect${this.randomKey}`]
        ? (this.$refs[`inputSelect${this.randomKey}`] as Vue).$el
        : null
      if (element) {
        this.inputWidth = element.clientWidth
        return
      } else return
    }, 0)
  }
}
