
import { Component, Mixins, Vue } from 'vue-property-decorator'
import {
  WPOpacity,
  WPCard,
  WPButtonMenu,
  WPBlockBtn,
  WPInfoBlock,
  WPDivider,
  WPButton,
  WPButtonIcon,
  WPTextInfo,
  WPTextInfoMain,
  WPBlockInfo,
  WPMiniHeader,
  WPCheckbox,
  WPTextInfoHeader,
  WPTextInfoLine,
  WPTextInfoBlock,
  WPExpPanel,
  WPBlockHint,
  WPLayoutBorder,
  WPGroupMultiline,
} from '@/components'

import ResultsCalculationElements from './ResultsCalculationElements.vue'

import { eventBus } from '@/helpers/eventBus'
import { copyToClipboard } from '@/services/common'

import {
  ICalcVolumeInputDataForm,
  ICalcStrengthInputDataForm,
  IItemCalcResult,
  IChartResult,
  IDataField,
} from '@/types'

import { Calculator } from '@/store/calculators'
import { Unit } from '@/store/units'
import { User } from '@/store/user'
import CalcResultActionsMixin from '@/mixins/calcResultActionsMixin'
import { Field } from '@/store/field'

interface ISelectedResult {
  text: string
  value: boolean
}

@Component({
  components: {
    WPOpacity,
    WPCard,
    WPButtonMenu,
    WPBlockBtn,
    WPDivider,
    WPButtonIcon,
    WPTextInfo,
    WPTextInfoMain,
    WPBlockInfo,
    WPMiniHeader,
    ResultsCalculationElements,
    WPButton,
    WPCheckbox,
    WPTextInfoHeader,
    WPTextInfoLine,
    WPTextInfoBlock,
    WPInfoBlock,
    WPExpPanel,
    WPLayoutBorder,
    WPGroupMultiline,
    WPBlockHint,
  },
})
export default class ResultCalculation extends Mixins(CalcResultActionsMixin) {
  // modal = actions | data | results | result
  private modal = ''

  private loading = true

  private selectedCalcResult: IItemCalcResult | null = null

  private selectedCalcResultIndex: number | null = null

  private selectedChart: IChartResult | null = null

  private selectedChartIndex: number | null = null

  private selectedResults: ISelectedResult[] = []

  private chartShowed = false

  private cardHeight = 600

  private itemHeight = 250

  private isFullscreenChart = false

  private timer: number | undefined = undefined

  private get calculatorItems() {
    return Calculator.calculations
  }

  private get calculators() {
    return Calculator.calculators
  }

  private get calcInfo() {
    return this.calcData ? this.calcData.calc_result : []
  }

  private get filteredCalcInfo() {
    return this.calcInfo.filter(
      (item) =>
        !!this.selectedResults.find(
          (i) => i.text === item.display_name && i.value
        )
    )
  }

  private get equipInfo() {
    return this.calcData ? this.calcData.equipment_set_info : []
  }

  private get wellboreInfo() {
    return this.calcData ? this.calcData.wellbore_info : []
  }

  private get wellEquipsInfo() {
    return this.calcData ? this.calcData.well_equipmentset_info : {}
  }

  private get inputDataInfoVolume() {
    if (this.calcData) {
      const code = this.calcData.code
      if (code === 1)
        return this.calcData.input_data as ICalcVolumeInputDataForm
    } else return null
  }

  private get inputDataInfoStrength() {
    if (this.calcData) {
      const code = this.calcData.code
      if (code === 2)
        return this.calcData.input_data as ICalcStrengthInputDataForm
    } else return null
  }

  private getUnitSymbol(code: number) {
    return String(Unit.units.find((item) => item.code === code)?.symbol)
  }

  private get isShowShareBlock() {
    return this.calcData ? User.user.id === this.calcData.user : false
  }

  private get getInputDataInfoVolume() {
    return this.inputDataInfoVolume
      ? `${String(this.inputDataInfoVolume.up_interval)} ${this.getUnitSymbol(
          this.inputDataInfoVolume.up_interval_uc
        )} – ${String(
          this.inputDataInfoVolume.down_interval
        )} ${this.getUnitSymbol(this.inputDataInfoVolume.down_interval_uc)}`
      : ''
  }

  private get techOperations() {
    return Calculator.techOperations
  }

  private get getInputDataInfoStrength() {
    const data = this.inputDataInfoStrength
    if (data) {
      const loads = data.additional_loads.map((item) => {
        return {
          title: `${String(this.$t('result.depth'))} ${this.getValueAndUnit(
            item.depth_additional_load,
            item.depth_additional_load_uc
          )}.`,
          subtitle: `F = ${this.getValueAndUnit(
            item.additional_load,
            item.additional_load_uc
          )}`,
        }
      })
      const torq = data.additional_torques.map((item) => {
        return {
          title: `${String(this.$t('result.depth'))} ${this.getValueAndUnit(
            item.depth_additional_torque,
            item.depth_additional_torque_uc
          )}.`,
          subtitle: `
          T = ${this.getValueAndUnit(
            item.additional_torque,
            item.additional_torque_uc
          )}`,
        }
      })

      const techOperation = this.techOperations.find(
        (item) => item.value === data.technological_operation
      )

      const operationInterval = `${this.getValueAndUnit(
        data.operation_interval_up,
        data.operation_interval_up_uc
      )} – ${this.getValueAndUnit(
        data.operation_interval_down,
        data.operation_interval_down_uc
      )}`

      const wedgeGripProps =
        this.calcData?.wedgegrip && this.calcData?.wedgegrip_info
          ? {
              wedge_grip_name: this.calcData.wedgegrip_info.name?.value,
              wedge_grip_angle_coverage:
                this.calcData.wedgegrip_info.angle_coverage?.value,
              wedge_grip_count: `${String(
                this.calcData.wedgegrip_info.wedge_quantity?.value
              )} ${String(this.$t('result.count'))}`,
              wedge_grip_coverage: this.calcData.wedgegrip_info.coverage?.value,
              wedge_grip_length:
                this.calcData.wedgegrip_info.wedge_length?.value,
              wedge_grip_value: this.calcData.wedgegrip_info.magnitude?.value,
              wedge_grip_incline:
                this.calcData.wedgegrip_info.angle_incline?.value,
              wedge_grip_friction:
                this.calcData.wedgegrip_info.angle_friction?.value,
            }
          : null

      const verifProps = data.verification
        ? {
            hook_tensile_force: this.getValueAndUnit(
              data.verification.hook_tensile_force,
              data.verification.hook_tensile_force_uc
            ),
            rotor_torque: this.getValueAndUnit(
              data.verification.rotor_torque,
              data.verification.rotor_torque_uc
            ),
            need_save: data.verification.need_save
              ? this.$t('result.updateParam')
              : this.$t('result.notUpdateParam'),
          }
        : null

      const predictProps = data.drillability_forecast
        ? {
            step: this.getValueAndUnit(
              data.drillability_forecast.step,
              data.drillability_forecast.step_uc
            ),
            start_depth: this.getValueAndUnit(
              data.drillability_forecast.start_depth,
              data.drillability_forecast.start_depth_uc
            ),
          }
        : null

      return {
        step: data.calculation_step
          ? this.getValueAndUnit(
              data.calculation_step,
              data.calculation_step_uc
            )
          : '9 m',
        loads: loads,
        torques: torq,
        technological_operation: techOperation?.name,
        direction: data.direction,
        resistance_coefficient: data.resistance_coefficient,
        fortress_rocks:
          data.fortress_rocks === 7.8
            ? `${String(this.$t('result.soft'))} (7.8)`
            : data.fortress_rocks === 6.9
            ? `${String(this.$t('result.medium'))} (6.9)`
            : `${String(this.$t('result.solid'))} (5.5)`,
        drilling_conditions:
          data.drilling_conditions === 'normal'
            ? this.$t('result.normal')
            : this.$t('result.complicated'),
        circulation: data.circulation
          ? this.$t('result.plus')
          : this.$t('result.minus'),
        external_pressure: this.getValueAndUnit(
          data.external_pressure,
          data.external_pressure_uc
        ),
        rock_density: this.getValueAndUnit(
          data.rock_density,
          data.rock_density_uc
        ),
        sludge_concentration: data.sludge_concentration,
        load_PRI: this.getValueAndUnit(data.load_PRI, data.load_PRI_uc),
        drilling_mud_density: this.getValueAndUnit(
          data.drilling_mud_density,
          data.drilling_mud_density_uc
        ),
        drilling_fluid_consumption: this.getValueAndUnit(
          data.drilling_fluid_consumption,
          data.drilling_fluid_consumption_uc
        ),
        rotation_frequency_equipmentset: this.getValueAndUnit(
          data.rotation_frequency_equipmentset,
          data.rotation_frequency_equipmentset_uc
        ),
        rotation_frequency_ZD: this.getValueAndUnit(
          data.rotation_frequency_ZD,
          data.rotation_frequency_ZD_uc
        ),
        tool_speed: this.getValueAndUnit(data.tool_speed, data.tool_speed_uc),
        pressure_drop_ZTS: this.getValueAndUnit(
          data.pressure_drop_ZTS,
          data.pressure_drop_ZTS_uc
        ),
        pressure_drop_VZD: this.getValueAndUnit(
          data.pressure_drop_VZD,
          data.pressure_drop_VZD_uc
        ),
        pressure_drop_doloto: this.getValueAndUnit(
          data.pressure_drop_doloto,
          data.pressure_drop_doloto_uc
        ),
        ...data.coefficient_reserve_strength,
        ...wedgeGripProps,
        operationInterval,
        verifProps,
        predictProps,
      }
    }
    return ''
  }

  private get getSelectingIcon() {
    return this.selectedResults.filter((item) => item.value).length === 0
      ? 'mdi-plus-box-outline'
      : 'mdi-minus-box-outline'
  }

  private getValueAndUnit(
    value: number | string | null | undefined,
    uc: number
  ) {
    return `${String(value)} ${this.getUnitSymbol(uc)}`
  }

  private async copyCalculation() {
    const iData = this.calcData ? this.calcData.input_data : null
    if (iData) {
      const inputData = {
        ...iData,
        wedge_grip: this.calcData?.wedgegrip
          ? Number(this.calcData?.wedgegrip)
          : null,
      }
      if (inputData) {
        void Calculator.SET_INPUT_DATA(inputData)
        let calculator = ''
        switch (this.code) {
          case 1:
            calculator = 'ConstructionSpaceVolumes'
            break

          case 2:
            calculator = 'EquipmentStrengthService'
            break
        }

        const wellbore = await Field.GET_WELLBORE(
          Number(this.calcData?.wellbore)
        )
        if (wellbore && typeof wellbore.well !== 'number') {
          const query = {
            field: String(wellbore.well.field),
            well: String(wellbore.well.id),
            wellbore: String(this.calcData?.wellbore),
            equip: String(this.calcData?.equipment_set),
          }

          void this.$router.push({
            name: `Calculators${calculator}`,
            query,
          })
        }
      }
    }
  }

  private checkAll() {
    const isPlus =
      this.selectedResults.filter((item) => item.value).length === 0
    this.selectedResults.forEach((item) => {
      item.value = isPlus
    })
  }

  private openModal(modal: string, data?: IItemCalcResult, index?: number) {
    this.modal = modal
    if (data) this.selectedCalcResult = data
    if (index !== undefined) this.selectedCalcResultIndex = index
    if (this.modal === 'result') {
      this.timer = setTimeout(() => {
        const item = document.querySelector(
          '.v-virtual-scroll__item:first-child'
        ) as HTMLElement
        if (item) this.itemHeight = item.offsetHeight
      }, 0)
    }
  }

  private toggleFullscreenChart() {
    this.isFullscreenChart = !this.isFullscreenChart
  }

  private openChart(data?: IChartResult, index?: number) {
    if (data) this.selectedChart = data
    if (index !== undefined) this.selectedChartIndex = index
  }

  private toggleChart() {
    this.chartShowed = !this.chartShowed
  }

  private cancel() {
    if (this.isFullscreenChart) {
      this.isFullscreenChart = false
    } else {
      this.modal = ''
      this.chartShowed = false
      this.selectedCalcResult = null
      this.selectedCalcResultIndex = null
    }
  }

  private copyLink() {
    const path = window.location.href
    void copyToClipboard(path)
    eventBus.$emit('showAlert', 'copied')
  }

  private getSortedSelectedCalcResult(
    data: Record<string, IDataField>,
    order: string
  ) {
    const arr = Object.entries(data)
    const arrSorted = arr.sort((a, b) => {
      const keyA = Number(a[0])
      const keyB = Number(b[0])
      if (order === 'asc') {
        return keyA < keyB ? -1 : 1
      } else {
        return keyA > keyB ? -1 : 1
      }
    })
    return arrSorted.map(([key, value]) => {
      return value
    })
  }

  private async showChart() {
    this.chartLoading = true
    if (!this.chart) {
      const result = await Calculator.GET_CHART(String(this.id))
      this.chart = result
    }
    this.modal = ''
    this.chartLoading = false
    this.loading = false
    this.toggleChart()
  }

  private async mounted() {
    this.id = Number(this.$route.params?.calcId)

    // await Calculator.GET_CALCULATIONS({ clear: true })
    await Calculator.GET_CALCULATION(String(this.id))
    await Calculator.GET_CALCULATORS()
    await Calculator.GET_TECH_OPERATIONS()

    const calcName = this.calculators.find(
      (item) => item.code === this.code
    )?.display_name
    if (calcName) Calculator.SET_SELECTED_CALCULATOR(calcName)

    this.selectedResults = this.calcInfo.map((item) => ({
      text: item.display_name,
      value: true,
    }))

    const card: HTMLElement | null = document?.querySelector(
      '.wp-card__item-result'
    )
    this.cardHeight = card ? card.offsetHeight - 100 : 600

    if (this.$route.query.chart) {
      await this.showChart()
    } else {
      this.loading = false
    }
  }

  private beforeDestroy() {
    void clearTimeout(this.timer)
  }
}
