
import { Component, Mixins, Watch } from 'vue-property-decorator'
import { Calculator } from '@/store/calculators'
import { Wellbore } from '@/store/wellbore'
import { Field } from '@/store/field'
import { Common } from '@/store/common'
import { Unit } from '@/store/units'

import {
  WPCard,
  WPButton,
  WPButtonMenu,
  WPButtonPlain,
  WPButtonIcon,
  WPOpacity,
  WPDivider,
  WPLayoutInDev,
  WPSelect,
  WPCheckCalculationBtns,
  WPButtonSlide,
  WPTextfieldMeasure,
  WPDialog,
} from '@/components'
import { RouteParamsMixin } from '@/mixins'
import {
  ICheckStructData,
  ICalculatorResult,
  TCheckStructResult,
} from '@/types'
import { eventBus } from '@/helpers/eventBus'
import { checkStructText, copyToClipboard } from '@/services/common'
import { Init } from '@/store/init'

export interface IUnitKeys {
  currentDepthUc: number
}

const MIN_DEPTH = 20

@Component({
  components: {
    WPCard,
    WPOpacity,
    WPButton,
    WPButtonMenu,
    WPButtonPlain,
    WPButtonIcon,
    WPLayoutInDev,
    WPDivider,
    WPSelect,
    WPCheckCalculationBtns,
    WPButtonSlide,
    WPTextfieldMeasure,
    WPDialog,
  },
})
export default class TheWellbore extends Mixins(RouteParamsMixin) {
  private showCalcForm = false

  private showCheckForm = false

  private loading = false

  private inputData: ICalculatorResult | null = null

  private selectedEquip: string | number | null =
    Number(this.$route.query.equip) || null

  private selectedCheckEquip: string | number | null = null

  private calc = this.$route.query.calculator || ''

  private activationLoading = false

  private transferLoading = false

  private showActivationForm = false

  private currentDepth: number | null = MIN_DEPTH

  private currentDepthUc = 100

  private minCurrentDepth = MIN_DEPTH

  private showActivationInfoModal = false

  private showDeactivationTransferModal = false

  private showDeactivationModal = false

  private code = ''

  private get isCalcFormDisabled() {
    return !(this.calc && this.selectedEquip)
  }

  private get isEditDialog() {
    return this.$route.name !== 'TheWellbore'
  }

  private get isMainSelected() {
    return this.$route.name === 'TheWellboreMain'
  }

  private get isMainReady() {
    return !!(this.wellbore && this.wellbore.name)
  }

  private get repeatInputData() {
    return this.inputData?.input_data
  }

  private get calculators() {
    return Calculator.calculators
  }

  private changeMeasure(val: number, key: keyof IUnitKeys) {
    this[key] = val
  }

  private changeActivationInfoVisible(val: boolean) {
    this.showActivationInfoModal = val
  }

  private changeDeactivationVisible(val: boolean) {
    this.showDeactivationModal = val
    this.activationLoading = false
  }

  private changeDeactivationTransferVisible(val: boolean) {
    this.showDeactivationTransferModal = val
    this.transferLoading = false
  }

  private calculate(copyData = false) {
    const query = {
      field: String(this.field?.id),
      well: String(this.well?.id),
      wellbore: String(this.wellbore?.id),
      equip: String(this.selectedEquip),
    }

    if (copyData && this.repeatInputData)
      Calculator.SET_INPUT_DATA(this.repeatInputData)

    if (this.calc)
      void this.$router.push({
        name: `Calculators${String(this.calc)}`,
        query,
      })
  }

  private goToResult() {
    if (this.inputData) {
      const routeData = this.$router.resolve({
        name: 'ResultsCalculation',
        params: {
          calcId: String(this.inputData.id),
        },
      })
      window.open(routeData.href, '_blank')
    }
  }

  private get isParamsSelected() {
    return this.$route.name === 'TheWellboreParams'
  }

  private get isParamsReady() {
    return !!(
      this.wellbore &&
      this.wellbore.current_depth !== null &&
      this.wellbore.project_depth !== null &&
      this.wellbore.altituda !== null &&
      this.wellbore.rotorheight !== null &&
      this.wellbore.magnetic_declination !== null
    )
  }

  private get isProfileReady() {
    return !!this.wellbore?.profile_points_count
  }

  private get isIntervalsReady() {
    return !!this.wellbore?.intervals_count
  }

  private get isEquipsReady() {
    return !!this.wellbore?.equipment_set_count
  }

  private get isTransferReady() {
    return this.wellbore && this.wellbore.status === 3
  }

  private get equips() {
    return Wellbore.equips
  }

  private get initOptions() {
    return Init.settings
  }

  private get isSecondaryCardOpened() {
    return (
      this.isEditDialog ||
      this.showCalcForm ||
      this.showCheckForm ||
      this.showActivationForm
    )
  }

  private get isActivationBtnDisabled() {
    const isActivated = this.wellbore && this.wellbore.activate_integration

    if (isActivated) {
      return this.wellbore?.data_transfer || this.wellbore?.status !== 1
    } else {
      return false
    }
  }

  private setCalculator(path = '', name = '') {
    Calculator.SET_SELECTED_CALCULATOR(name)
    this.calc = path
  }

  private goToCalculator() {
    void this.$router.push({
      name: `Calculators${String(this.calc)}`,
      query: {
        field: String(this.$route.params.id),
        well: String(this.$route.params.wellId),
        wellbore: String(this.$route.params.wellboreId),
        equip: String(this.selectedEquip),
      },
    })
  }

  private goTo(path = '') {
    void this.$router.push({
      name: `TheWellbore${path}`,
    })
  }

  private checkPageName(name = '') {
    return this.$route.name === name
  }

  private openCalculators() {
    this.showCalcForm = true
  }

  private openCheckStruct() {
    this.showCheckForm = true
  }

  private closeModals() {
    this.showCalcForm = false
    this.showCheckForm = false
  }

  private closeIntegration() {
    this.showActivationForm = false
    this.activationLoading = false
  }

  private closeTransfer() {
    this.transferLoading = false
  }

  private copyInfo() {
    const text = (this.$refs.codeBlock as HTMLElement).innerText
    void copyToClipboard(text)
    this.changeActivationInfoVisible(false)
  }

  private async checkStruct(isActivate = false, isTransfer = false) {
    let data: ICheckStructData = {
      wellbore: Number(this.wellboreId),
    }
    if (this.selectedCheckEquip) data.equip = Number(this.selectedCheckEquip)

    const result =
      isActivate || isTransfer
        ? await Wellbore.CHECK_FULL_STRUCT(data)
        : await Wellbore.CHECK_STRUCT(data)

    if (isActivate && result?.length === 0) {
      void this.activationInit()
    } else if (isTransfer && result?.length === 0) {
      await this.transferInit()
    } else {
      const text = checkStructText(
        result as TCheckStructResult,
        String(this.$t('wellbore.warningText')),
        String(this.$t('wellbore.errorText')),
        String(this.$t('wellbore.criticalText'))
      )

      eventBus.$emit('showDialog', {
        title: this.$t('wellbore.dialogCheckTitle'),
        text:
          result && result.length
            ? text
            : this.$t('wellbore.dialogCheckSuccess'),
        btnDismissText: this.$t('common.back'),
        btnAcceptText:
          !isActivate && !isTransfer ? '' : this.$t('common.continue'),
      })
    }
  }

  private cancel() {
    void this.$router.push({
      name: 'TheWell',
      params: {
        id: String(this.fieldId),
        wellId: String(this.wellId),
      },
    })
  }

  private activationInit() {
    this.showActivationForm = true
  }

  private async transferInit() {
    if (this.wellbore && this.wellbore.id) {
      await Wellbore.ACTIVATE_TRANSFER_WELLBORE({
        id: this.wellbore.id,
        data_transfer: true,
      })
      Common.SET_COMMON_WELLBORE({ ...this.wellbore, data_transfer: true })
    }
    this.transferLoading = false
  }

  private async showInfo() {
    if (!this.code) {
      const info = await Wellbore.GET_WELLBORE_ACTIVATION_INFO(
        String(this.wellbore?.id)
      )
      this.code = info || ''
    }
    this.showActivationInfoModal = true
  }

  private async activation() {
    const id = Number(this.wellboreId)
    const result = await Wellbore.ACTIVATE_INTEGRATION({
      wellboreId: id,
      depth: this.currentDepth ? this.currentDepth : undefined,
      depthUc: this.currentDepth ? this.currentDepthUc : undefined,
    })
    if (result) {
      const factId = result.Fact
      void this.$router
        .push({
          name: String(this.$route.name),
          params: {
            ...this.$route.params,
            wellboreId: String(factId),
          },
        })
        .catch(() => {
          return
        })

      await this.onToggleCard()

      const info = await Wellbore.GET_WELLBORE_ACTIVATION_INFO(String(factId))
      this.code = info || ''
      this.showActivationInfoModal = true
      void Field.RELOAD_WELLBORES(Number(this.wellId))
      await Promise.all([
        Wellbore.GET_EQUIPS({
          wellboreId: Number(result.Fact),
          clear: true,
        }),
        Wellbore.GET_PROFILEPOINTS({
          wellboreId: Number(result.Fact),
          clear: true,
        }),
        Wellbore.GET_INTERVALS({
          wellboreId: Number(result.Fact),
          clear: true,
        }),
      ])
      this.closeIntegration()
    }
  }

  private async changeWellboreByStatus(code: number) {
    if (code !== this.wellbore?.status) {
      eventBus.$emit('wellboreStatusLoading', true)
      const result = await Wellbore.GET_WELLBORE_BY_STATUS({
        wellbore: Number(this.wellboreId),
        status: code,
      })
      if (result) {
        await Promise.all([
          Wellbore.GET_EQUIPS({
            wellboreId: Number(result.id),
            clear: true,
          }),
          Wellbore.GET_PROFILEPOINTS({
            wellboreId: Number(result.id),
            clear: true,
          }),
          Wellbore.GET_INTERVALS({
            wellboreId: Number(result.id),
            clear: true,
          }),
          Wellbore.GET_WELLBORE_VALIDATION(Number(result.id)),
        ])

        void this.$router
          .push({
            name: String(this.$route.name),
            params: {
              ...this.$route.params,
              wellboreId: String(result.id),
            },
          })
          .catch(() => {
            return
          })
      }
    }
  }

  private onDeactivateIntegrationModal() {
    this.activationLoading = true
    this.showDeactivationModal = true
  }

  private async onDeactivateIntegration() {
    if (this.wellbore && this.wellbore.id) {
      await Wellbore.DEACTIVATE_INTEGRATION(this.wellbore.id)
      Common.SET_COMMON_WELLBORE({ ...this.wellbore })
    }
    await this.onToggleCard()
    void Field.RELOAD_WELLBORES(Number(this.wellId))
    this.showDeactivationModal = false
    this.activationLoading = false
  }

  private onDeactivateTransferModal() {
    this.transferLoading = true
    this.showDeactivationTransferModal = true
  }

  private async onDeactivateTransfer() {
    if (this.wellbore && this.wellbore.id) {
      await Wellbore.ACTIVATE_TRANSFER_WELLBORE({
        id: this.wellbore.id,
        data_transfer: false,
      })
      Common.SET_COMMON_WELLBORE({ ...this.wellbore, data_transfer: false })
    }
    this.showDeactivationTransferModal = false
    this.transferLoading = false
  }

  private async onActivateIntegration() {
    const result = await Wellbore.CHECK_INTEGRATION(Number(this.wellboreId))
    if (this.initOptions.enable_wellbore_integration && result) {
      this.activationLoading = true
      await this.checkStruct(true)
    }
  }

  private async onActivateTransfer() {
    this.transferLoading = true
    await this.checkStruct(false, true)
  }

  private async onLoadEquips() {
    await Wellbore.GET_EQUIPS({ wellboreId: Number(this.wellboreId) })
  }

  private async onChangeData() {
    if (!this.isCalcFormDisabled) {
      this.loading = true
      const query = {
        field: String(this.field?.id),
        well: String(this.well?.id),
        wellbore: String(this.wellbore?.id),
        equip: String(this.selectedEquip),
      }

      const code = this.calculators.find(
        (item) => item.name === this.calc
      )?.code

      const result = await Calculator.GET_CALCULATIONS_REPEAT({
        ...query,
        last_date: String(true),
        code: String(code),
      })
      this.inputData = result.length ? result[0] : null
      this.loading = false
    } else this.inputData = null
  }

  @Watch('selectedEquip')
  private async onChangeEquip() {
    this.inputData = null
    await this.onChangeData()
  }

  @Watch('isSecondaryCardOpened')
  private async onToggleCard() {
    const result = await Field.GET_WELLBORE(Number(this.wellboreId))
    void Common.SET_COMMON_WELLBORE(result)
  }

  @Watch('calc')
  private async onChangeCalc() {
    this.inputData = null
    await this.onChangeData()
  }

  @Watch('currentDepthUc')
  private async onChangeDepthUc(val: number) {
    const result = await Unit.CONVERSE_UNITS({
      sourceVal: MIN_DEPTH,
      sourceUnit: 100,
      destUnit: val,
    })
    if (result) this.minCurrentDepth = result
  }

  private async mounted() {
    await this.onLoadEquips()
    await Calculator.GET_CALCULATORS()
    eventBus.$on('dialogClick', () => {
      if (this.activationLoading) void this.activationInit()
      if (this.transferLoading) void this.transferInit()
    })
    eventBus.$on('dialogDismiss', () => {
      if (this.activationLoading) void this.closeIntegration()
      if (this.transferLoading) void this.closeTransfer()
    })
    eventBus.$on(
      'changeWellboreType',
      async (code: number) => await this.changeWellboreByStatus(code)
    )
    eventBus.$on('wellboreShowInfo', () => this.showInfo())
  }

  private beforeDestroy() {
    eventBus.$off('dialogClick')
    eventBus.$off('dialogDismiss')
    eventBus.$off('changeWellboreType')
    eventBus.$off('wellboreShowInfo')
  }
}
