import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule,
} from 'vuex-module-decorators'
import store from '@/store'

import {
  IField,
  IWell,
  IWellbore,
  TFields,
  TWells,
  TWellTypes,
  TWellbores,
  IFieldCounts,
  TFieldCounts,
  ICluster,
  TClusters,
} from '@/types'
import Vue from 'vue'
import {
  getFieldById,
  getFields,
  getWellboresByWellId,
  getWellsByFieldId,
  getWellTypes,
  newField,
  newWell,
  newWellbore,
  addWellType,
  saveWellbore,
  deleteWellbore,
  cloneWellbore,
  getWellbore,
  saveField,
  deleteField,
  saveWell,
  deleteWell,
  getWells,
  getWell,
  getClusters,
  addCluster,
} from '@/services/fields'

import { getPage } from '@/services/common'
import { Dictionary } from 'vue-router/types/router'

export interface IFieldState {
  fields: TFields
  wells: TWells
  wellsFull: TWells
  welltypes: TWellTypes
  wellbores: TWellbores
  clusters: TClusters
}

@Module({ dynamic: true, store, name: 'field' })
class FieldState extends VuexModule implements IFieldState {
  public fields: TFields = []

  public wells: TWells = []

  public wellsFull: TWells = []

  public welltypes: TWellTypes = []

  public wellbores: TWellbores = []

  public clusters: TClusters = []

  public counts: IFieldCounts = {
    fields: null,
    wells: null,
    wellsFull: null,
    wellbores: null,
    welltypes: null,
    clusters: null,
  }

  @Mutation
  public SetFields(fields: TFields) {
    fields.map((item) => {
      this.fields.push(item)
    })
  }

  @Mutation
  public SetWells(wells: TWells) {
    wells.map((item) => {
      this.wells.push(item)
    })
  }

  @Mutation
  public SetWellsFull(wells: TWells) {
    wells.map((item) => {
      this.wellsFull.push(item)
    })
  }

  @Mutation
  public SetWellFull(well: IWell) {
    const index = this.wellsFull.findIndex((item) => item.id === well.id)
    Vue.set(this.wellsFull, index, well)
  }

  @Mutation
  public SetField(field: IField) {
    const index = this.fields.findIndex((item) => item.id === field.id)
    Vue.set(this.fields, index, field)
  }

  @Mutation
  public SetWell(well: IWell) {
    const index = this.wells.findIndex((item) => item.id === well.id)
    Vue.set(this.wells, index, well)
  }

  @Mutation
  public SetWellTypes(welltypes: TWellTypes) {
    welltypes.map((item) => {
      this.welltypes.push(item)
    })
  }

  @Mutation
  public SetClusters(clusters: TClusters) {
    clusters.map((item) => {
      this.clusters.push(item)
    })
  }

  @Mutation
  public SetWellbores(wellbores: TWellbores) {
    wellbores.map((item) => {
      this.wellbores.push(item)
    })
  }

  @Mutation
  public SetWellbore(wellbore: IWellbore) {
    const index = this.wellbores.findIndex((item) => item.id === wellbore.id)
    Vue.set(this.wellbores, index, wellbore)
  }

  @Mutation
  public SetCount(data: { type: TFieldCounts; count: number | null }) {
    this.counts[data.type] = data.count
  }

  @Mutation
  public ClearFieldState() {
    this.fields = []
    this.wells = []
    this.welltypes = []
    this.wellbores = []
    this.wellsFull = []
    this.clusters = []
    this.counts.fields = null
    this.counts.wells = null
    this.counts.welltypes = null
    this.counts.wellbores = null
    this.counts.wellsFull = null
    this.counts.clusters = null
  }

  @Mutation
  public ClearData(data: TFieldCounts) {
    this[data] = []
  }

  @Action
  public CLEAR_DATA(data: TFieldCounts) {
    this.SetCount({ type: data, count: null })
    this.ClearData(data)
  }

  @Action
  public async GET_FIELDS(query?: Dictionary<string>) {
    const page = getPage(this.counts.fields, this.fields.length)

    if (page !== null) {
      const fields = await getFields(page, query)
      this.SetCount({ type: 'fields', count: fields.count })
      if (fields.results) {
        this.SetFields(fields.results)
      }
    }
  }

  @Action
  public async GET_FIELD_BY_ID(fieldId: number) {
    return await getFieldById(fieldId)
  }

  @Action
  public async ADD_FIELD(field: IField) {
    const response = await newField(field)
    if (response) {
      await this.RELOAD_FIELDS()
    }
  }

  @Action
  public async RELOAD_FIELDS() {
    void this.CLEAR_DATA('fields')
    await this.GET_FIELDS()
  }

  @Action
  public async RELOAD_WELLS(fieldId: number) {
    void this.CLEAR_DATA('wells')
    await this.GET_WELLS({ fieldId })
  }

  @Action
  public async RELOAD_WELLS_FULL() {
    void this.CLEAR_DATA('wellsFull')
    await this.GET_WELLS_FULL({})
  }

  @Action
  public async RELOAD_WELLBORES(wellId?: number) {
    void this.CLEAR_DATA('wellbores')
    await this.GET_WELLBORES({ wellId, query: { status: '1' } })
  }

  @Action
  public async RELOAD_WELL_TYPES() {
    void this.CLEAR_DATA('welltypes')
    await this.GET_WELL_TYPES()
  }

  @Action
  public async RELOAD_CLUSTERS(fieldId: number) {
    void this.CLEAR_DATA('clusters')
    await this.GET_CLUSTERS({ field: fieldId })
  }

  @Action
  public async SAVE_FIELD(field: IField) {
    const resp = await saveField(field)
    if (resp) {
      this.SetField(resp)
    }
  }

  @Action
  public async DELETE_FIELD(fieldId: number) {
    await deleteField(fieldId)
    await this.RELOAD_FIELDS()
  }

  @Action
  public async GET_WELLS(data: {
    fieldId: number
    query?: Dictionary<string>
    cluster?: number
    clear?: boolean
  }) {
    const { fieldId, query, cluster, clear } = data
    const page = clear ? 1 : getPage(this.counts.wells, this.wells.length)

    if (page !== null) {
      const wells = await getWellsByFieldId(fieldId, page, query, cluster)
      if (clear) void this.CLEAR_DATA('wells')
      this.SetCount({ type: 'wells', count: wells.count })
      if (wells.results) {
        this.SetWells(wells.results)
      }
    }
  }

  @Action
  public async GET_WELLS_FULL(data: {
    query?: Dictionary<string>
    clear?: boolean
  }) {
    const { query, clear } = data
    const page = clear
      ? 1
      : getPage(this.counts.wellsFull, this.wellsFull.length)

    if (page !== null) {
      const wells = await getWells(page, query)
      if (clear) void this.CLEAR_DATA('wellsFull')
      this.SetCount({ type: 'wellsFull', count: wells.count })
      if (wells.results) {
        this.SetWellsFull(wells.results)
      }
    }
  }

  @Action
  public async GET_WELL_TYPES(query?: Dictionary<string>) {
    const page = getPage(this.counts.welltypes, this.welltypes.length)

    if (page !== null) {
      const data = await getWellTypes(page, query)
      this.SetCount({ type: 'welltypes', count: data.count })
      if (data.results) {
        this.SetWellTypes(data.results)
      }
    }
  }

  @Action
  public async GET_WELL(wellId: number) {
    const well = await getWell(wellId)
    return well
  }

  @Action
  public async ADD_WELL_TYPE(name: string) {
    const typeResp = await addWellType(name)
    if (typeResp) {
      await this.RELOAD_WELL_TYPES()
      return typeResp
    }
  }

  @Action
  public async ADD_WELL(well: IWell) {
    const wellResp = await newWell(well)
    if (wellResp) {
      await this.RELOAD_WELLS(Number(well.field))
    }
  }

  @Action
  public async SAVE_WELL(well: IWell) {
    const resp = await saveWell(well)
    if (resp) {
      this.SetWell(resp)
    }
  }

  @Action
  public async SAVE_WELL_FULL(well: IWell) {
    const resp = await saveWell(well)
    if (resp) {
      this.SetWellFull(resp)
    }
  }

  @Action
  public async DELETE_WELL(wellId: number) {
    await deleteWell(wellId)

    const field = this.wells.find((item) => item.id === wellId)?.field as IField
    await this.RELOAD_WELLS(Number(field.id))
  }

  @Action
  public async DELETE_WELL_FULL(wellId: number) {
    await deleteWell(wellId)
    await this.RELOAD_WELLS_FULL()
  }

  @Action
  public async GET_WELLBORES(data: {
    wellId?: number
    query?: Dictionary<string>
    clear?: boolean
  }) {
    const { wellId, clear, query } = data
    const page = clear
      ? 1
      : getPage(this.counts.wellbores, this.wellbores.length)
    if (page !== null) {
      const wellbores = await getWellboresByWellId(wellId, page, query)
      if (clear) void this.CLEAR_DATA('wellbores')
      this.SetCount({ type: 'wellbores', count: wellbores.count })
      if (wellbores.results) {
        this.SetWellbores(wellbores.results)
      }
    }
  }

  @Action
  public async GET_WELLBORE(wellboreId: number) {
    const wellbore = await getWellbore(wellboreId)
    return wellbore
  }

  @Action
  public async SAVE_WELLBORE(wellbore: IWellbore) {
    const wellboreResp = await saveWellbore(wellbore)
    const result = this.wellbores
    if (result.length && wellboreResp) {
      this.SetWellbore(wellboreResp)
    }
  }

  @Action
  public async SAVE_WELLBORE_SILENT(wellbore: IWellbore) {
    const wellboreResp = await saveWellbore(wellbore, false)
    const result = this.wellbores
    if (result.length && wellboreResp) {
      this.SetWellbore(wellboreResp)
    }
  }

  @Action
  public async ADD_WELLBORE(wellbore: IWellbore) {
    const wellboreResp = await newWellbore(wellbore)
    if (wellboreResp) {
      await this.RELOAD_WELLBORES(Number(wellbore.well))
    }
  }

  @Action
  public async DELETE_WELLBORE(wellboreId: number) {
    await deleteWellbore(wellboreId)

    const well = this.wellbores.find((item) => item.id === wellboreId)?.well as
      | IWell
      | number

    const idWell = typeof well === 'number' ? well : Number(well.id)
    await this.RELOAD_WELLBORES(idWell)
  }

  @Action
  public async CLONE_WELLBORE(wellboreId: number) {
    const result = await cloneWellbore(wellboreId)
    if (result) await this.RELOAD_WELLBORES(Number(result.well))
  }

  @Action
  public async GET_CLUSTERS(props: {
    field?: number
    name?: string
    clear?: boolean
  }) {
    const { field, clear, name } = props
    const page = clear ? 1 : getPage(this.counts.clusters, this.clusters.length)

    if (page !== null) {
      const data = await getClusters(field, page, name)
      if (clear) void this.CLEAR_DATA('clusters')
      this.SetCount({ type: 'clusters', count: data.count })
      if (data.results) {
        this.SetClusters(data.results)
      }
    }
  }

  @Action
  public async ADD_CLUSTER(data: ICluster) {
    const typeResp = await addCluster(data)
    if (typeResp) {
      await this.RELOAD_CLUSTERS(data.field)
      return typeResp
    }
  }

  @Action
  public CLEAR_FIELD_STATES() {
    this.ClearFieldState()
  }
}

export const Field = getModule(FieldState)
