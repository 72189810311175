
import { Component, Vue } from 'vue-property-decorator'
import {
  WPSelect,
  WPButton,
  WPTextfield,
  WPDivider,
  WPPalettePicker,
  WPBlockHint,
} from '@/components'

import { User } from '@/store/user'

import { eventBus } from '@/helpers/eventBus'

import { ISettings } from '@/types'

@Component({
  components: {
    WPSelect,
    WPButton,
    WPDivider,
    WPPalettePicker,
    WPTextfield,
    WPBlockHint,
  },
})
export default class Settings extends Vue {
  private loading = true

  private valid = false

  private langChanged = false

  private settings: ISettings = {
    theme: '',
    language: 'ru-ru',
    measure_type: 'metric',
    round_digits: 2,
  }

  private readonly languageSelect = [
    {
      text: 'Русский',
      value: 'ru-ru',
    },
    {
      text: 'English',
      value: 'en-us',
    },
  ]

  private readonly colorsSelect = ['blue', 'orange', 'green', 'black']

  private get unitsSelect() {
    return [
      {
        text: this.$t('profile.unitsM'),
        value: 1,
      },
      {
        text: this.$t('profile.unitsUS'),
        value: 2,
      },
    ]
  }

  private async save() {
    this.loading = true
    await User.SAVE_SETTINGS(this.settings)
    this.$i18n.locale = String(this.settings.language)
    void eventBus.$emit('changeTheme', this.settings.theme)
    void eventBus.$emit('closeSettings')
    if (this.langChanged) void location.reload()
    this.loading = false
  }

  private onChangeLang() {
    this.langChanged = true
  }

  private async mounted() {
    const result = await User.GET_SETTINGS()
    this.settings = { ...result }
    this.langChanged = false
    this.loading = false
  }
}
