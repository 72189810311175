import {
  IAuthTokens,
  TokenRefreshRequest,
  applyAuthTokenInterceptor,
} from 'axios-jwt'
import axios from 'axios'
import { AxiosLoginResponse } from '@/types'
import { eventBus, getError } from '@/helpers/eventBus'

const HOST =
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  process.env.NODE_ENV === 'production'
    ? window.location.host
    : '172.10.11.150:9866'

const BASE_URL = `//${HOST}/api/v1`

const axiosInstance = axios.create({ baseURL: BASE_URL })

const requestRefresh: TokenRefreshRequest = async (
  refreshToken: string
): Promise<IAuthTokens | string> => {
  const response = await axios.post<AxiosLoginResponse>(
    `${BASE_URL}/accounts/jwt/refresh/`,
    {
      refresh: refreshToken,
    }
  )

  if (response.data.access) {
    return response.data.access
  }

  return ''
}

axiosInstance.interceptors.response.use(
  function (response) {
    return response
  },
  function (error) {
    const e = getError(error)

    if (e && e.status === 401) {
      eventBus.$emit('unauthorized')
    }

    if (e && e.status === 403) {
      eventBus.$emit('pageNoPermission')
      return Promise.reject()
    }

    if (e && e.status === 404) {
      eventBus.$emit('pageNotFound')
      return Promise.reject()
    }

    return Promise.reject(error)
  }
)

applyAuthTokenInterceptor(axiosInstance, {
  requestRefresh,
  headerPrefix: 'JWT ',
})

export default axiosInstance
