
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

interface IListItem {
  [key: string]: string
}

@Component
export default class WPBaseCombobox extends Vue {
  @Prop({ default: () => [] })
  private readonly items?: any[]

  @Prop({ default: '' })
  private readonly value?: string

  @Prop({ default: '' })
  private readonly label?: string

  @Prop({ default: '' })
  private readonly hint?: string

  @Prop({ default: '' })
  private readonly placeholder?: string

  @Prop({ default: false })
  private readonly white?: boolean

  @Prop({ default: '' })
  private readonly itemText?: string

  @Prop({ default: '' })
  private readonly itemValue?: string

  @Prop({ default: 'auto' })
  private readonly hideDetails?: string | boolean

  @Prop({ default: true })
  private readonly outlined?: boolean

  @Prop({ default: () => [] })
  private readonly rules?: string[]

  @Prop({ default: false })
  private readonly disabled?: boolean

  @Prop({ default: false })
  private readonly isMeasure?: boolean

  @Prop({ default: false })
  private readonly loading?: boolean

  @Prop({ default: true })
  private readonly returnObject?: boolean

  @Prop({ default: false })
  private readonly readonly?: boolean

  @Prop({ default: false })
  private readonly disableTyping?: boolean

  @Prop({ default: false })
  private readonly clearable?: boolean

  private inputWidth: number | null = null

  private randomKey = 0

  private get computedValue() {
    return this.value
  }

  private set computedValue(value) {
    this.emitValue(value)
  }

  private emitValue(value: any) {
    this.$emit('input', value)
  }

  private onLoadItems() {
    this.$emit('onLoadItems')
  }

  @Watch('computedValue', {
    immediate: true,
  })
  private onChangeValue(val: any) {
    if (
      this.returnObject &&
      typeof this.value === 'number' &&
      this.items &&
      this.items.length
    ) {
      let result
      this.items.forEach((item: IListItem) => {
        if (this.itemValue && item && item[this.itemValue]) {
          if (item[this.itemValue] === val) result = item
        }
      })
      if (result) this.emitValue(result)
    }
  }

  private created() {
    this.randomKey = Math.floor(Math.random() * 10000)
  }

  private mounted() {
    setTimeout(() => {
      const element = this.$refs[`inputCombo${this.randomKey}`]
        ? (this.$refs[`inputCombo${this.randomKey}`] as Vue).$el
        : null
      if (element) {
        this.inputWidth = element.clientWidth
        return
      } else return
    }, 0)
  }
}
