
import { Component, Vue } from 'vue-property-decorator'
import { WPButton } from '@/components'

@Component({
  components: {
    WPButton,
  },
})
export default class PrivacyPolicy extends Vue {}
