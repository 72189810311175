
import { Component, Vue } from 'vue-property-decorator'
import {
  WPTextfield,
  WPTextfieldPassword,
  WPButton,
  WPCard,
  WPDivider,
  WPSelect,
  WPLogo,
} from '@/components'

import { User } from '@/store/user'
import { ISupportRequestLang } from '@/types'
import { eventBus } from '@/helpers/eventBus'

@Component({
  components: {
    WPLogo,
    WPButton,
    WPTextfieldPassword,
    WPTextfield,
    WPDivider,
    WPCard,
    WPSelect,
  },
})
export default class Password extends Vue {
  private username = ''

  private email = ''

  private get valid() {
    return this.username && this.email
  }

  private async send() {
    const request: ISupportRequestLang = {
      type: Number(this.getPassResetTypeValue),
      email: this.email,
      message: this.username,
      lang: 'ru-ru',
    }
    const result = await User.SEND_SUPPORT_REQUEST(request)
    if (result !== null) {
      this.back()
    }
  }

  private get getPassResetTypeValue() {
    return this.supportTypes.find(
      (item) => item.name === this.$t('passReset.problemType')
    )?.id
  }

  private get supportTypes() {
    return User.supportTypes
  }

  private back() {
    void this.$router.push({ name: 'Login' })
  }

  private async mounted() {
    await User.GET_SUPPORT_TYPES()
    eventBus.$on('localeSwitch', async (val: string) => {
      await User.GET_SUPPORT_TYPES(val)
    })
  }
}
