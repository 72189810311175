
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class WPMiniHeader extends Vue {
  @Prop({ default: false })
  private colored?: boolean

  @Prop({ default: '' })
  private title?: string

  private get notEmpty() {
    return !!Object.keys(this.$slots).length
  }
}
