
import { Component, Vue } from 'vue-property-decorator'

import { Common } from '@/store/common'

import { WPButton, WPCard } from '@/components'

@Component({
  components: {
    WPButton,
    WPCard,
  },
})
export default class WPMobilePage extends Vue {
  private get isAppLoaded() {
    return Common.appLoaded
  }

  private get link() {
    return Common.downloadLink
  }

  private goToMobile() {
    if (this.link) window.location.href = this.link
  }
}
