import { eventBus, getError } from '@/helpers/eventBus'

import axiosInstance from '@/services/api'
import { TUnits, TUnitTypes, IPagedData } from '@/types'

export async function getUnits(withoutNotification = false): Promise<TUnits> {
  try {
    const response = await axiosInstance.get<IPagedData<TUnits>>('/units/', {
      params: { page_size: -1 },
    })
    if (response?.data) {
      return response.data.results
    }
    return []
  } catch (error: unknown) {
    if (!withoutNotification) eventBus.$emit('showError', getError(error))
    return []
  }
}

export async function getUnitTypes(
  withoutNotification = false
): Promise<TUnitTypes> {
  try {
    const response = await axiosInstance.get<IPagedData<TUnitTypes>>(
      '/units/types/',
      {
        params: { page_size: -1 },
      }
    )
    if (response?.data) {
      return response.data.results
    }
    return []
  } catch (error: unknown) {
    if (!withoutNotification) eventBus.$emit('showError', getError(error))
    return []
  }
}

export async function converseUnits(
  sourceVal: number | null,
  sourceUnit: number,
  destUnit: number
): Promise<number | null> {
  if (sourceVal !== null && sourceUnit && destUnit) {
    try {
      const response = await axiosInstance.post<{ dest_val: number }>(
        '/units/conversion/',
        {
          source_val: sourceVal,
          source_unit: sourceUnit,
          dest_unit: destUnit,
        }
      )
      if (response?.data) {
        return response.data.dest_val
      }
      return null
    } catch (error: unknown) {
      eventBus.$emit('showError', getError(error))
      return null
    }
  }
  return null
}
