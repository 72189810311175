
import { Component, Prop, Vue } from 'vue-property-decorator'

import { eventBus } from '@/helpers/eventBus'
import { IWellboreTypes } from '@/types'

@Component
export default class WPBreadcrumbs extends Vue {
  @Prop({ default: '' })
  private link?: string

  @Prop({ default: '' })
  private field?: string

  @Prop({ default: '' })
  private well?: string

  @Prop({ default: '' })
  private wellbore?: string

  @Prop({ default: 'Динамичный' })
  private wellboreType?: string

  @Prop({ default: '' })
  private calculator?: string

  @Prop({ default: false })
  private isCalculatorsMainPage?: boolean

  @Prop({ default: false })
  private calculatorPage?: boolean

  @Prop({ default: false })
  private calculatorResults?: boolean

  private menuOpened = false

  private statusLoading = false

  private statusDisabled = false

  private toggleMenu(val: boolean) {
    this.menuOpened = val
  }

  private get isShow(): boolean {
    return !!(
      this.field ||
      this.well ||
      this.wellbore ||
      this.calculatorPage ||
      this.calculatorResults
    )
  }

  private get statuses(): IWellboreTypes[] {
    return [
      {
        code: 1,
        name: 'Static',
        display_name: String(this.$t('wellbore.activation.staticWellbore')),
      },
      {
        code: 2,
        name: 'Dynamic',
        display_name: String(this.$t('wellbore.activation.dynamicWellbore')),
      },
      {
        code: 3,
        name: 'Fact',
        display_name: String(this.$t('wellbore.activation.factWellbore')),
      },
    ]
  }

  private onClickType(code: number) {
    eventBus.$emit('changeWellboreType', code)
  }

  private mounted() {
    eventBus.$on('wellboreStatusLoading', (val: boolean) => {
      this.statusLoading = val
    })
    eventBus.$on('disableWellboreTypes', (val: boolean) => {
      this.statusDisabled = val
    })
  }

  private beforeDestroy() {
    eventBus.$off('wellboreStatusLoading')
    eventBus.$off('disableWellboreTypes')
  }
}
