
import { Component, Mixins, Prop } from 'vue-property-decorator'
import WPBaseRadio from './base/WPBaseRadio.vue'
import { ValidationMixin } from '@/mixins'

@Component({
  components: { WPBaseRadio },
})
export default class WPRadioGroup extends Mixins(ValidationMixin) {
  @Prop({ default: null })
  public readonly value?: string | number

  @Prop({ default: '' })
  public readonly label?: string

  @Prop({ default: '' })
  public readonly name?: string

  @Prop({ default: () => [] })
  private readonly items?: any[]

  private get computedValue() {
    return this.value
  }

  private set computedValue(value) {
    this.$emit('input', value)
  }

  private onChange(val: any) {
    this.$emit('change', val)
  }
}
