import { eventBus } from '@/helpers/eventBus'

import { Component, Vue, Watch } from 'vue-property-decorator'

import { Calculator } from '@/store/calculators'

@Component
class CalculatorMixin extends Vue {
  public progress = false

  public modal = ''

  public calcCode = 0

  public open(modal: string) {
    this.modal = modal
  }

  public close() {
    this.modal = ''
  }

  public get params() {
    return this.$route.query
  }

  public toggleProgress(val: boolean) {
    this.progress = val
  }

  public get calculators() {
    return Calculator.calculators
  }

  public get selectedCalc() {
    return Calculator.calculators.find((item) => item.code === this.calcCode)
  }

  public setCalc() {
    const calc = this.selectedCalc?.display_name
    if (calc) Calculator.SetSelectedCalculator(calc)
  }

  @Watch('calculators')
  private onLoadCalculators() {
    this.setCalc()
  }

  public created() {
    eventBus.$on('calculatorPercent', (val: boolean) =>
      this.toggleProgress(val)
    )
    eventBus.$on('closeInitDataModal', () => this.close())
  }
}

export default CalculatorMixin
