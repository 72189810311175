
import { Component, Mixins, Watch } from 'vue-property-decorator'
import {
  WPCard,
  WPProgressBar,
  WPButtonMenu,
  WPGroup,
  WPTextfieldMeasure,
} from '@/components'

import CalculatorsLayout from './CalculatorsLayout.vue'

import { CalculatorMixin } from '@/mixins'

import { Field } from '@/store/field'
import { Unit } from '@/store/units'
import { Calculator } from '@/store/calculators'
import { ICalcVolumeInputDataForm } from '@/types'

export interface IIntervalKeys {
  downInterval: number
  downIntervalUc: number
  upInterval: number
  upIntervalUc: number
}

@Component({
  components: {
    WPCard,
    WPProgressBar,
    WPButtonMenu,
    WPGroup,
    WPTextfieldMeasure,
    CalculatorsLayout,
  },
})
export default class CalculatorsVolume extends Mixins(CalculatorMixin) {
  public readonly calcCode = 1

  private downInterval = 0

  private downIntervalUc = 100

  private upInterval = 0

  private upIntervalUc = 100

  private maxValueDown = 0

  private maxValueUp = 0

  private projectDepth = 0

  private projectDepthUc = 100

  private get stages() {
    return [
      this.$t('calcs.volume.stages.mining'),
      this.$t('calcs.volume.stages.pipes'),
      this.$t('calcs.volume.stages.equips'),
      this.$t('calcs.volume.stages.pipesMaterial'),
      this.$t('calcs.volume.stages.equipsMaterial'),
      this.$t('calcs.volume.stages.pipesInner'),
      this.$t('calcs.volume.stages.equipsInner'),
      this.$t('calcs.volume.stages.well'),
      this.$t('calcs.volume.stages.annulus'),
      this.$t('calcs.volume.stages.effective'),
      this.$t('calcs.volume.stages.tool'),
      this.$t('calcs.volume.stages.annular'),
    ]
  }

  private get inputData() {
    return {
      up_interval: Number(this.upInterval),
      up_interval_uc: Number(this.upIntervalUc),
      down_interval: Number(this.downInterval),
      down_interval_uc: Number(this.downIntervalUc),
    }
  }

  private changeMeasure(val: number, key: keyof IIntervalKeys) {
    this[key] = val
  }

  private saveInterval() {
    void this.$router.replace(
      {
        query: {
          ...this.params,
          up: String(this.upInterval),
          up_uc: String(this.upIntervalUc),
          down: String(this.downInterval),
          down_uc: String(this.downIntervalUc),
        },
      },
      () => ({})
    )
    this.close()
  }

  @Watch('downIntervalUc')
  private async onChangeDownUc(val: number) {
    const depth = await Unit.CONVERSE_UNITS({
      sourceVal: this.projectDepth,
      sourceUnit: this.projectDepthUc,
      destUnit: val,
    })
    this.maxValueDown = depth !== null ? depth : 0
  }

  @Watch('upIntervalUc')
  private async onChangeUpUc(val: number) {
    const depth = await Unit.CONVERSE_UNITS({
      sourceVal: this.projectDepth,
      sourceUnit: this.projectDepthUc,
      destUnit: val,
    })
    this.maxValueUp = depth !== null ? depth : 0
  }

  private async mounted() {
    let wellbore = null

    if (this.params.field && this.params.well) {
      await Field.GET_WELLBORES({ wellId: Number(this.params.well) })
      wellbore = Field.wellbores.find(
        (wellbore) =>
          wellbore?.id && wellbore.id === Number(this.params.wellbore)
      )
    } else {
      wellbore = await Field.GET_WELLBORE(Number(this.params.wellbore))
    }

    if (wellbore) {
      this.downInterval = Number(this.params?.down) || wellbore.project_depth
      this.downIntervalUc =
        Number(this.params?.down_uc) || wellbore.project_depth_uc
      this.upIntervalUc = Number(this.params?.up_uc) || 100
      this.upInterval = Number(this.params?.up) || 0

      this.projectDepth = wellbore.project_depth
      this.projectDepthUc = wellbore.project_depth_uc
      this.maxValueUp = wellbore.project_depth
      this.maxValueDown = wellbore.project_depth
    }

    if (Calculator.inputData) {
      const data = Calculator.inputData as ICalcVolumeInputDataForm
      this.downInterval = data.down_interval
      this.downIntervalUc = data.down_interval_uc
      this.upIntervalUc = data.up_interval_uc
      this.upInterval = data.up_interval
      Calculator.CLEAR_INPUT_DATA()
    }
  }
}
