
import { Component, Mixins, Watch } from 'vue-property-decorator'
import { Field } from '@/store/field'
import { Wellbore } from '@/store/wellbore'

import {
  WPTextfieldMeasure,
  WPList,
  WPCard,
  WPTextfield,
  WPOpacity,
  WPBlockHint,
  WPLayoutBorder,
  WPExpPanel,
  WPRadioGroup,
} from '@/components'
import { RouteParamsMixin } from '@/mixins'

export interface IWellboreUnitKeys {
  currentDepthUc: number
  projectDepthUc: number
  altitudaUc: number
  rotorheightUc: number
  magneticDeclinationUc: number
  gridDeclinationUc: number
}

const wellboreMock = {
  name: '',
  currentDepth: '',
  projectDepth: '',
  altituda: '',
  rotorheight: '',
  magneticDeclination: '',
  gridDeclination: '',
  currentDepthUc: 100,
  projectDepthUc: 100,
  altitudaUc: 100,
  rotorheightUc: 100,
  magneticDeclinationUc: 1201,
  gridDeclinationUc: 1201,
  profilePoints: 0,
  coefUp: 1.15,
  coefDown: 0.85,
  ignoreRes: 1,
  ignoreInc: 1,
}

@Component({
  components: {
    WPTextfield,
    WPCard,
    WPList,
    WPOpacity,
    WPTextfieldMeasure,
    WPBlockHint,
    WPLayoutBorder,
    WPExpPanel,
    WPRadioGroup,
  },
})
export default class TheWell extends Mixins(RouteParamsMixin) {
  private wellboreForm = {
    name: '',
    currentDepth: '',
    projectDepth: '',
    altituda: '',
    rotorheight: '',
    magneticDeclination: '',
    gridDeclination: '',
    currentDepthUc: 100,
    projectDepthUc: 100,
    altitudaUc: 100,
    rotorheightUc: 100,
    magneticDeclinationUc: 1201,
    gridDeclinationUc: 1201,
    profilePoints: 0,
    coefUp: 1.15,
    coefDown: 0.85,
    ignoreRes: 1,
    ignoreInc: 1,
  }

  private busy = false

  private isAddDialog = false

  private isShownWellboreList = false

  private loading = false

  private search = ''

  private get emptyCountWellbores() {
    return Field.counts.wellbores === 0
  }

  private get ignoreItems() {
    return [
      {
        text: this.$t('wellboreParams.notIgnore'),
        value: 1,
      },
      {
        text: this.$t('wellboreParams.ignoreSingle'),
        value: 2,
      },
      {
        text: this.$t('wellboreParams.ignoreAlways'),
        value: 3,
      },
    ]
  }

  private get title() {
    return this.$t('wellbores.empty', { msg: this.wellName })
  }

  private get items() {
    return Field.wellbores
  }

  private get wellName() {
    if (this.well) {
      return this.well.name
    }
    return ''
  }

  private get query() {
    return this.search ? { name: this.search } : undefined
  }

  private openAddDialog() {
    this.wellboreForm = { ...wellboreMock }
    this.isAddDialog = true
  }

  private async addWellbore() {
    this.loading = true
    if (this.field?.id && this.well?.id) {
      const newWellbore = {
        name: this.wellboreForm.name,
        well: this.well.id,
        current_depth: Number(this.wellboreForm.currentDepth),
        project_depth: Number(this.wellboreForm.projectDepth),
        altituda: Number(this.wellboreForm.altituda),
        rotorheight: Number(this.wellboreForm.rotorheight),
        magnetic_declination: Number(this.wellboreForm.magneticDeclination),
        grid_declination: Number(this.wellboreForm.gridDeclination),
        current_depth_uc: Number(this.wellboreForm.currentDepthUc),
        project_depth_uc: Number(this.wellboreForm.projectDepthUc),
        altituda_uc: Number(this.wellboreForm.altitudaUc),
        rotorheight_uc: Number(this.wellboreForm.rotorheightUc),
        magnetic_declination_uc: Number(
          this.wellboreForm.magneticDeclinationUc
        ),
        grid_declination_uc: Number(this.wellboreForm.gridDeclinationUc),
        used_azimuth_type: 1,
        resistance_coefficient_up: Number(this.wellboreForm.coefUp),
        resistance_coefficient_down: Number(this.wellboreForm.coefDown),
        ignore_inclinometry_validations: this.wellboreForm.ignoreInc as
          | 1
          | 2
          | 3,
        ignore_calculations_validations: this.wellboreForm.ignoreRes as
          | 1
          | 2
          | 3,
      }
      await Field.ADD_WELLBORE(newWellbore)
    }

    this.cancel()
    if (this.search) {
      void Field.CLEAR_DATA('wellbores')
      await this.onSearch(this.search, true)
    }
    this.loading = false
  }

  private cancel() {
    this.isAddDialog = false
  }

  private changeMeasure(val: number, key: keyof IWellboreUnitKeys) {
    this.wellboreForm[key] = val
  }

  private async onLoad() {
    if (!this.busy) {
      this.busy = true
      await Field.GET_WELLBORES({
        wellId: Number(this.$route.params.wellId),
        query: { ...this.query, status: '1' },
      })
      this.busy = false
    }
  }

  private async onSearch(val: string, force?: boolean) {
    if (this.search !== val || force) {
      this.search = val
      await this.onLoad()
    }
  }

  private onClickItem(id: string) {
    void Wellbore.CLEAR_WELLBORE_STATES()
    void this.$router.push({
      name: 'TheWellbore',
      params: {
        id: String(this.fieldId),
        wellId: String(this.wellId),
        wellboreId: String(id),
      },
    })
  }

  private async onDeleteItem(id: number) {
    await Field.DELETE_WELLBORE(id)
    if (this.search) {
      void Field.CLEAR_DATA('wellbores')
      await this.onSearch(this.search, true)
    }
  }

  private async onCloneItem(id: number) {
    this.loading = true
    await Field.CLONE_WELLBORE(id)
    this.loading = false
  }

  @Watch('wellboreForm.currentDepth')
  private onChangeDepth(val: string) {
    this.wellboreForm.projectDepth = val
  }

  @Watch('wellboreForm.currentDepthUc')
  private onChangeDepthUc(val: number) {
    this.wellboreForm.projectDepthUc = val
  }

  private async mounted() {
    await this.onLoad()
    this.isShownWellboreList = true
  }

  private beforeDestroy() {
    if (this.search) void Field.CLEAR_DATA('wellbores')
  }
}
