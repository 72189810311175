
import { Component, Mixins, Watch } from 'vue-property-decorator'
import { Field } from '@/store/field'
import {
  WPCard,
  WPButtonMenu,
  WPButtonIcon,
  WPTextfieldMeasure,
  WPTextfield,
  WPBlockHint,
  WPExpPanel,
  WPRadioGroup,
  WPLayoutBorder,
} from '@/components'
import { RouteParamsMixin } from '@/mixins'

export interface IUnitKeys {
  currentDepthUc: number
  projectDepthUc: number
  altitudaUc: number
  rotorheightUc: number
  magneticDeclinationUc: number
  gridDeclinationUc: number
}

@Component({
  components: {
    WPCard,
    WPButtonMenu,
    WPButtonIcon,
    WPTextfieldMeasure,
    WPTextfield,
    WPBlockHint,
    WPExpPanel,
    WPRadioGroup,
    WPLayoutBorder,
  },
})
export default class TheWellboreParams extends Mixins(RouteParamsMixin) {
  private currentDepth: number | null = null

  private projectDepth: number | null = null

  private altituda: number | null = null

  private rotorheight: number | null = null

  private magneticDeclination: number | null = null

  private gridDeclination: number | null = null

  private ignoreInc: 1 | 2 | 3 = 1

  private ignoreRes: 1 | 2 | 3 = 1

  private currentDepthUc = 100

  private projectDepthUc = 100

  private altitudaUc = 100

  private rotorheightUc = 100

  private magneticDeclinationUc = 1201

  private gridDeclinationUc = 1201

  private coefUp: number | null = 1.15

  private coefDown: number | null = 0.85

  private get ignoreItems() {
    return [
      {
        text: this.$t('wellboreParams.notIgnore'),
        value: 1,
      },
      {
        text: this.$t('wellboreParams.ignoreSingle'),
        value: 2,
      },
      {
        text: this.$t('wellboreParams.ignoreAlways'),
        value: 3,
      },
    ]
  }

  private cancel() {
    void this.$router.push({
      name: 'TheWellbore',
      params: {
        id: String(this.fieldId),
        wellId: String(this.wellId),
        wellboreId: String(this.wellboreId),
      },
    })
  }

  private changeMeasure(val: number, key: keyof IUnitKeys) {
    this[key] = val
  }

  private async saveWellbore() {
    if (this.wellbore && this.well) {
      await Field.SAVE_WELLBORE({
        ...this.wellbore,
        current_depth: Number(this.currentDepth),
        project_depth: Number(this.projectDepth),
        altituda: Number(this.altituda),
        rotorheight: Number(this.rotorheight),
        magnetic_declination: Number(this.magneticDeclination),
        grid_declination: Number(this.gridDeclination),
        current_depth_uc: Number(this.currentDepthUc),
        project_depth_uc: Number(this.projectDepthUc),
        altituda_uc: Number(this.altitudaUc),
        rotorheight_uc: Number(this.rotorheightUc),
        magnetic_declination_uc: Number(this.magneticDeclinationUc),
        well: Number(this.wellId),
        grid_declination_uc: Number(this.gridDeclinationUc),
        resistance_coefficient_up: Number(this.coefUp),
        resistance_coefficient_down: Number(this.coefDown),
        ignore_inclinometry_validations: this.ignoreInc,
        ignore_calculations_validations: this.ignoreRes,
      })
    }
  }

  @Watch('currentDepth')
  private onChangeDepth(val: number | null) {
    this.projectDepth = val
  }

  @Watch('currentDepthUc')
  private onChangeDepthUc(val: number) {
    this.projectDepthUc = val
  }

  private async mounted() {
    if (!this.field) await Field.GET_FIELDS()
    if (!this.well)
      await Field.GET_WELLS({ fieldId: Number(this.$route.params.id) })
    if (!this.wellbore)
      await Field.GET_WELLBORES({
        wellId: Number(this.$route.params.wellId),
        query: { status: '1' },
      })
    if (this.wellbore) {
      this.currentDepth = this.wellbore.current_depth
      this.projectDepth = this.wellbore.project_depth
      this.altituda = this.wellbore.altituda
      this.rotorheight = this.wellbore.rotorheight
      this.magneticDeclination = this.wellbore.magnetic_declination
      this.gridDeclination = this.wellbore.grid_declination || null
      this.currentDepthUc = this.wellbore.current_depth_uc
      this.projectDepthUc = this.wellbore.project_depth_uc
      this.altitudaUc = this.wellbore.altituda_uc
      this.rotorheightUc = this.wellbore.rotorheight_uc
      this.magneticDeclinationUc = this.wellbore.magnetic_declination_uc
      this.gridDeclinationUc = this.wellbore.grid_declination_uc || 1201
      this.coefUp = this.wellbore.resistance_coefficient_up || 1.15
      this.coefDown = this.wellbore.resistance_coefficient_down || 0.85
      this.ignoreInc = this.wellbore.ignore_inclinometry_validations
      this.ignoreRes = this.wellbore.ignore_calculations_validations
    }
  }
}
