
import { Component, Vue, Prop } from 'vue-property-decorator'
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'

@Component({
  components: {
    VueSlider,
  },
})
export default class WPButtonSlide extends Vue {
  @Prop({ default: '22' })
  private readonly iconSize?: string

  @Prop({ default: false })
  private readonly disabled?: boolean

  @Prop({ default: false })
  private readonly loading?: boolean

  @Prop({ default: false })
  private readonly isDeactivate?: boolean

  @Prop({ default: true })
  private readonly margin?: boolean

  private MIN_VAL = 0

  private MAX_VAL = 90

  private value = this.MIN_VAL

  private dragOn = false

  private activated = false

  private get notClickable() {
    return this.disabled || this.loading
  }

  private setDefaultValue() {
    void (this.$refs.buttonSlide as VueSlider).setValue(this.MIN_VAL)
    this.value = this.MIN_VAL
  }

  private activate() {
    if (this.value === this.MAX_VAL && !this.activated) {
      this.activated = true
      this.$emit(this.isDeactivate ? 'onDeactivate' : 'onActivate')
    }
  }

  private onDragStart() {
    this.dragOn = true
  }

  private onDragEnd() {
    this.dragOn = false
    void this.activate()
    setTimeout(() => this.setDefaultValue(), 1)
    this.activated = false
  }

  private onChange(value: number) {
    if (!this.dragOn && value > this.MIN_VAL) void this.setDefaultValue()
    if (this.dragOn && value >= this.MAX_VAL) {
      void this.activate()
    }
  }
}
