import { VueConstructor } from 'vue'
import { VueConfiguration } from 'vue/types/vue'
import { IThemeHelper, IThemeList, TThemes } from '@/types/'
import * as Themes from './themes'

const DEFAULT_THEME = 'blue'

const ThemeHelper: IThemeHelper = {
  active: '',

  apply(value?: string) {
    const themes: IThemeList = Object.assign({}, Themes)

    if (!this.active) {
      if (!Object.keys(themes).includes(DEFAULT_THEME)) {
        throw Error('ThemeError: not fount default theme "blue".')
      }

      this.active = value || DEFAULT_THEME
    }

    if (this.active in themes) {
      for (const key in themes[this.active]) {
        const value = themes[this.active][key]
        window.app.style.setProperty(`--` + key, value)
      }
    }
  },

  // ToDo: доработать метод
  set(value?: string) {
    if (value) {
      this.active = value
    }
    this.apply()
    return
  },

  // ToDo: доработать метод
  get() {
    return {}
  },

  // ToDo: доработать метод
  list(): TThemes {
    return Themes as unknown as TThemes
  },
}

export default {
  install(Vue: VueConstructor, options: VueConfiguration) {
    // ToDo: разобраться с типизацией
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    Vue.prototype.$theme = ThemeHelper
    Vue.$theme = ThemeHelper
  },
}
