
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class WPBaseTextfield extends Vue {
  @Prop({ default: '' })
  private readonly value?: string | number

  @Prop({ default: '' })
  private readonly label?: string

  @Prop({ default: '' })
  private readonly type?: string

  @Prop({ default: '' })
  private readonly placeholder?: string

  @Prop({ default: '' })
  private readonly hint?: string

  @Prop({ default: '' })
  private readonly icon?: string

  @Prop({ default: false })
  private readonly white?: boolean

  @Prop({ default: 'auto' })
  private readonly hideDetails?: string | boolean

  @Prop({ default: () => [] })
  private readonly rules?: string[]

  @Prop({ default: null })
  private readonly min?: number

  @Prop({ default: null })
  private readonly max?: number

  @Prop({ default: '' })
  private readonly suffix?: string

  @Prop({ default: false })
  private readonly clearable?: boolean

  @Prop({ default: false })
  private readonly flat?: boolean

  @Prop({ default: false })
  private readonly mini?: boolean

  private clearValue() {
    this.onInput('')
  }

  private onInput(value: string | number) {
    this.$emit('input', value)
  }

  private onBlur() {
    this.$emit('blur', this.value)
  }

  private onKeyDown(e: KeyboardEvent) {
    if (this.type === 'number') {
      const symbol = e.key
      const numbers = /[a-zA-Zа-яА-Я]/
      if (symbol === '-') {
        if (String(this.value)) e.preventDefault()
        return
      }

      if (symbol.length === 1 && numbers.test(symbol) && !e.ctrlKey) {
        e.preventDefault()
      }
    }
  }

  private onIconClick($event: MouseEvent) {
    this.$emit('icon-click', $event)
  }
}
