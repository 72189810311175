
import { Component, Vue } from 'vue-property-decorator'
import { WPButton } from '@/components'

@Component({
  components: {
    WPButton,
  },
})
export default class Page403 extends Vue {
  private goToMain() {
    void this.$router.push({ name: 'Fields' })
  }
}
