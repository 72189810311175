import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule,
} from 'vuex-module-decorators'
import store from '@/store'

import { Unit } from './units'

import {
  ISettings,
  TSupportTypes,
  IUserData,
  ISupportRequestLang,
} from '@/types'
import {
  getSettings,
  getSupportTypes,
  getUserInfo,
  saveSettings,
  sendSupportRequest,
} from '@/services/user'

export interface IUserState {
  settings: ISettings
  supportTypes: TSupportTypes
}

@Module({ dynamic: true, store, name: 'user' })
class UserState extends VuexModule implements IUserState {
  public settings: ISettings = {
    theme: 'blue',
    language: 'ru-ru',
    measure_type: 'metric',
    round_digits: 2,
  }

  public user: IUserData = {
    email: '',
    id: 0,
    username: '',
  }

  public supportTypes: TSupportTypes = []

  @Mutation
  public SetSettings(settings: ISettings) {
    this.settings = { ...settings }
  }

  @Mutation
  public SetUserData(user: IUserData) {
    this.user = { ...user }
  }

  @Mutation
  public SetSupportTypes(types: TSupportTypes) {
    this.supportTypes = types
  }

  @Action
  public async GET_SETTINGS(withoutNotification = false) {
    const data = await getSettings(withoutNotification)
    this.SetSettings(data)
    return data
  }

  @Action
  public async SAVE_SETTINGS(settings: ISettings) {
    const data = await saveSettings(settings)
    if (this.settings.language !== data.language) {
      Unit.CLEAR_UNIT_STATES()
      await Promise.all([
        Unit.GET_UNITS(),
        Unit.GET_UNITTYPES(),
        this.GET_SETTINGS(),
        this.GET_USER_DATA(),
      ])
    }
    this.SetSettings(data)
    return data
  }

  @Action
  public async GET_SUPPORT_TYPES(lang?: string) {
    const data = await getSupportTypes(lang)
    this.SetSupportTypes(data)
  }

  @Action
  public async SEND_SUPPORT_REQUEST(request: ISupportRequestLang) {
    const data = await sendSupportRequest(request)
    return data
  }

  @Action
  public async GET_USER_DATA(withoutNotification = false) {
    const data = await getUserInfo(withoutNotification)
    this.SetUserData(data)
    return data
  }
}

export const User = getModule(UserState)
