import { IError } from '@/types'
import Vue from 'vue'

export const eventBus = new Vue()

export function getError(error: IError | unknown) {
  if (error) {
    const { message, response } = error as IError

    const err = {
      message: message,
      status: response?.status,
      data: response?.data,
    }

    return err
  } else {
    return null
  }
}
