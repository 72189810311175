
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

const TIMER_DURATION = 5000

@Component
export default class WPAlert extends Vue {
  @Prop({ default: 'success' })
  private type?: string

  @Prop({ default: '' })
  private visible?: boolean

  @Prop({ default: 0 })
  private status?: number

  private timerId?: number | null = null

  private classes = ['alert']

  private closed = true

  @Watch('visible')
  private onChangeVisibility(val: boolean) {
    if (typeof this.timerId === 'number') clearTimeout(this.timerId)
    if (val) {
      this.closed = false
      this.timerId = setTimeout(() => {
        if (!this.closed) this.onInputAlert()
      }, TIMER_DURATION)
    } else {
      this.timerId = null
      this.closed = true
    }
  }

  private onInputAlert() {
    this.closed = true
    this.$emit('dismiss')
  }

  private created() {
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
    if (!isSafari) this.classes.push('alert_animation')
  }

  private beforeDestroy() {
    if (typeof this.timerId === 'number') clearTimeout(this.timerId)
  }
}
