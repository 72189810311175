
import { Component, Prop, Vue } from 'vue-property-decorator'
import WPButton from './WPButton.vue'

interface IData {
  title: string
  description: string
  icon: string
}

@Component({
  components: {
    WPButton,
  },
})
export default class WPAccordion extends Vue {
  @Prop({ required: true })
  private data?: IData[]

  private active = 0

  private get activeItem(): IData | undefined {
    return this.data ? this.data[this.active] : undefined
  }

  private get spacerWidth() {
    return this.data ? `${100 / this.data?.length}%` : ''
  }

  private get spacerPos() {
    return this.data ? `${this.active * (100 / this.data?.length)}%` : ''
  }

  private get isLast() {
    return this.data ? this.active === this.data.length - 1 : false
  }

  private iconPath(icon: string, index: number): any {
    const type = this.active === index ? 'on' : 'off'
    return require(`../assets/icons/${icon}_${type}.svg`)
  }

  private changeActive(index?: number) {
    this.active = index !== undefined ? index : this.active + 1
    this.$emit('change', this.active)
    if (this.data && this.active === this.data.length) this.$emit('end')
  }
}
