
import moment from 'moment'

import { Component, Mixins } from 'vue-property-decorator'
import {
  WPCard,
  WPOpacity,
  WPButtonMenu,
  WPButton,
  WPTextfield,
  WPCalculationResult,
  WPDivider,
  WPSelect,
  WPBlockBtn,
  WPLoader,
  WPDatepicker,
  WPTitle,
  WPButtonIcon,
} from '@/components'

import { Field } from '@/store/field'
import { Wellbore } from '@/store/wellbore'
import { Calculator } from '@/store/calculators'
import { Dictionary } from 'vue-router/types/router'
import {
  ClustersSearchMixin,
  FieldsSearchMixin,
  WellboresSearchMixin,
  WellsSearchMixin,
  EquipsSearchMixin,
  WellboresStatusNameMixin,
  CalcResultActionsMixin,
} from '@/mixins'
import { eventBus } from '@/helpers/eventBus'
import { copyToClipboard } from '@/services/common'
import { User } from '@/store/user'

@Component({
  components: {
    WPCard,
    WPOpacity,
    WPButton,
    WPButtonMenu,
    WPTextfield,
    WPCalculationResult,
    WPDivider,
    WPBlockBtn,
    WPSelect,
    WPDatepicker,
    WPLoader,
    WPButtonIcon,
    WPTitle,
  },
})
export default class Results extends Mixins(
  FieldsSearchMixin,
  ClustersSearchMixin,
  WellsSearchMixin,
  WellboresSearchMixin,
  EquipsSearchMixin,
  WellboresStatusNameMixin,
  CalcResultActionsMixin
) {
  private selectedField: number | null =
    Number(this.$route.query?.field) || null

  private selectedWell: number | null = Number(this.$route.query?.well) || null

  private selectedCluster: number | null = null

  private selectedWellbore: number | null =
    Number(this.$route.query?.wellbore) || null

  private selectedEquip: number | null =
    Number(this.$route.query?.equip) || null

  private selectedCalculator: number | null = null

  private selectedDate: string[] | null = null

  private selectedLang: string | null = null

  private selectedUnitSystem: number | null = null

  private isFiltersOpened = false

  private isResultOpened = false

  private calcUser = 0

  private calcText = ''

  private copyLoading = false

  private search = ''

  private loading = true

  private cardHeight = 200

  private busy = false

  private timerId?: number = undefined

  private selectLoadingCalcs = false

  private searchTextCalcs: string | null = null

  private get languageSelect() {
    return [
      {
        text: this.$t('results.langRu'),
        value: 'ru-ru',
      },
      {
        text: this.$t('results.langEn'),
        value: 'en-us',
      },
    ]
  }

  private get unitSystemSelect() {
    return [
      {
        text: this.$t('profile.unitsM'),
        value: 1,
      },
      {
        text: this.$t('profile.unitsUS'),
        value: 2,
      },
    ]
  }

  private get fields() {
    return Field.fields
  }

  private get wells() {
    return Field.wells
  }

  private get clusters() {
    return Field.clusters
  }

  private get equips() {
    return Wellbore.equips
  }

  private get calculatorItems() {
    return Calculator.calculators
  }

  private get isLoading() {
    return Calculator.counts.calculations === null
  }

  private get resultsFullLoaded() {
    return Calculator.counts.calculations === Calculator.calculations.length
  }

  private get calculations() {
    return Calculator.calculations
  }

  public get codeChartAllowedCalcs() {
    return [2].includes(this.calcCode)
  }

  public get codeVizualizationAllowedCalcs() {
    return [2].includes(this.calcCode)
  }

  private get isShowShareBlock() {
    return User.user.id === this.calcUser
  }

  private get filtersQuery() {
    const {
      selectedCalculator,
      selectedDate,
      selectedField,
      selectedWell,
      selectedWellbore,
      selectedCluster,
      selectedEquip,
      selectedLang,
      selectedUnitSystem,
      search,
    } = this

    const filters =
      selectedCalculator ||
      selectedDate ||
      selectedField ||
      selectedCluster ||
      selectedWell ||
      selectedWellbore ||
      selectedEquip ||
      selectedLang ||
      selectedUnitSystem ||
      search
    let query: Dictionary<string> | undefined = filters ? {} : undefined
    if (query) {
      if (selectedCalculator) query.code = String(selectedCalculator)
      if (selectedField) query.field = String(selectedField)
      if (selectedCluster) query.cluster = String(selectedCluster)
      if (selectedWell) query.well = String(selectedWell)
      if (selectedWellbore) query.wellbore = String(selectedWellbore)
      if (selectedEquip) query.equipment_set = String(selectedEquip)
      if (selectedUnitSystem) query.measure_type = String(selectedUnitSystem)
      if (selectedDate) {
        if (selectedDate.length === 1) {
          query.date = selectedDate[0]
        } else {
          query.start_date = selectedDate[0]
          query.end_date = selectedDate[1]
        }
      }
      if (selectedLang) query.language = selectedLang
      if (search) query.text_search = search
    }
    return query
  }

  private get queryCalcs() {
    return this.searchTextCalcs
      ? { display_name: this.searchTextCalcs }
      : undefined
  }

  private openFilters() {
    this.isFiltersOpened = true
  }

  private closeFilters() {
    this.isFiltersOpened = false
  }

  private getCalc(code: number) {
    const calc = this.calculatorItems.find((item) => item.code === code)
    return calc || null
  }

  private getDate(date: string) {
    return moment
      .utc(date, 'YYYY-MM-DD[T]HH:mm:ss')
      .format('DD.MM.YYYY – HH:mm')
  }

  private async selectField(field: number) {
    this.selectedField = field
    this.selectedCluster = null
    this.selectedWell = null
    this.selectedWellbore = null
    this.selectedEquip = null
    void Field.CLEAR_DATA('clusters')
    void Field.CLEAR_DATA('wells')
    await Field.GET_CLUSTERS({ field })
    await Field.GET_WELLS({ fieldId: field })
  }

  private async selectCluster(cluster: number) {
    this.selectedCluster = cluster
    this.selectedWell = null
    this.selectedWellbore = null
    this.selectedEquip = null
    void Field.CLEAR_DATA('wells')
    await Field.GET_WELLS({ fieldId: Number(this.selectedField), cluster })
  }

  private async selectWell(well: number) {
    this.selectedWell = well
    this.selectedWellbore = null
    this.selectedEquip = null
    void Field.CLEAR_DATA('wellbores')
    await Field.GET_WELLBORES({ wellId: well })
  }

  private async selectWellbore(wellbore: number) {
    this.selectedWellbore = wellbore
    this.selectedEquip = null
    void Wellbore.CLEAR_DATA('equips')
    await Wellbore.GET_EQUIPS({ wellboreId: wellbore })
  }

  public async copyCalculation() {
    this.copyLoading = true
    const iData = await Calculator.GET_CALCULATION(String(this.id))
    if (iData) {
      const inputData = {
        ...iData.input_data,
        wedge_grip: iData?.wedgegrip ? Number(iData?.wedgegrip) : null,
      }
      if (inputData) {
        void Calculator.SET_INPUT_DATA(inputData)
        let calculator = ''
        switch (this.code) {
          case 1:
            calculator = 'ConstructionSpaceVolumes'
            break

          case 2:
            calculator = 'EquipmentStrengthService'
            break
        }

        const wellbore = await Field.GET_WELLBORE(Number(iData?.wellbore))
        if (wellbore && typeof wellbore.well !== 'number') {
          const query = {
            field: String(wellbore.well.field),
            well: String(wellbore.well.id),
            wellbore: String(iData?.wellbore),
            equip: String(iData?.equipment_set),
          }

          void this.$router.push({
            name: `Calculators${calculator}`,
            query,
          })
        }
      }
    }
  }

  private async clearFilters() {
    this.selectedField = null
    this.selectedWell = null
    this.selectedCluster = null
    this.selectedWellbore = null
    this.selectedEquip = null
    this.selectedCalculator = null
    this.selectedDate = null
    this.selectedLang = null
    this.selectedUnitSystem = null
    this.search = ''

    this.isFiltersOpened = false
    await this.setFilters()
  }

  private async setFilters() {
    this.loading = true
    this.isFiltersOpened = false
    void Calculator.CLEAR_DATA('calculations')
    await this.onLoadResults(true)
    this.loading = false
  }

  private goToCalcs() {
    void this.$router.push({
      name: `Calculators`,
    })
  }

  private copyLink() {
    const path = `${window.location.href}/${String(this.id)}`
    void copyToClipboard(path)
    eventBus.$emit('showAlert', 'copied')
  }

  private showResult() {
    const routeData = this.$router.resolve({
      name: 'ResultsCalculation',
      params: {
        calcId: String(this.id),
      },
    })
    window.open(routeData.href, '_blank')
  }

  private showChart() {
    const routeData = this.$router.resolve({
      name: 'ResultsCalculation',
      params: {
        calcId: String(this.id),
      },
      query: {
        chart: 'true',
      },
    })
    window.open(routeData.href, '_blank')
  }

  private cancel() {
    this.isFiltersOpened = false
    this.onCloseResult()
  }

  private onCloseResult() {
    this.calcCode = 0
    this.calcUser = 0
    this.calcText = ''
    this.calcMeasure = 0
    this.id = 0
    this.isResultOpened = false
  }

  private onSearch(val: string) {
    this.search = val
    this.loading = true
    clearTimeout(this.timerId)

    this.timerId = setTimeout(async () => {
      await this.setFilters()
      this.loading = false
    }, 300)
  }

  private async onLoadCalcs() {
    await Calculator.GET_CALCULATORS(this.queryCalcs)
  }

  public async onLoadClusters() {
    await Field.GET_CLUSTERS({
      field: Number(this.selectedField),
      name: this.searchTextClusters,
    })
  }

  public async onLoadWellbores() {
    await Field.GET_WELLBORES({
      wellId: Number(this.selectedWell),
      query: this.queryWellbores,
    })
  }

  public async onLoadEquips() {
    await Wellbore.GET_EQUIPS({
      wellboreId: Number(this.selectedWellbore),
      clear: undefined,
      query: this.queryEquips,
    })
  }

  public async onLoadWells() {
    await Field.GET_WELLS({
      fieldId: Number(this.selectedField),
      query: this.queryWells,
      cluster: this.selectedCluster ? Number(this.selectedCluster) : undefined,
    })
  }

  private async onLoadResults(clear = false) {
    if (!this.busy && !this.resultsFullLoaded) {
      this.busy = true
      await Calculator.GET_CALCULATIONS({
        clear,
        query: this.filtersQuery,
      })
      this.busy = false
    }
  }

  private async onSearchCalcs(search = '') {
    this.searchTextCalcs = search
    this.selectLoadingCalcs = true
    void Calculator.CLEAR_DATA('calculators')
    await this.onLoadCalcs()
    this.selectLoadingCalcs = false
  }

  private onClickResult(data: {
    id: string
    calc: string
    code: number
    user: number
    measure: number
  }) {
    // Calculator.SET_SELECTED_CALCULATOR(data.calc)
    // void this.$router.push({
    //   name: `ResultsCalculation`,
    //   params: {
    //     calcId: data.id,
    //   },
    // })
    this.calcText = data.calc
    this.calcUser = Number(data.user)
    this.calcCode = Number(data.code)
    this.id = Number(data.id)
    this.calcMeasure = data.measure
    this.isResultOpened = true
  }

  private async mounted() {
    await Promise.all([
      Field.GET_FIELDS(),
      Calculator.GET_CALCULATIONS({}),
      Calculator.GET_CALCULATORS(),
    ])
    this.loading = false
    const card: HTMLElement | null = document?.querySelector(
      '.wp-results__card-list'
    )
    this.cardHeight = card ? card.offsetHeight - 140 : 200
  }

  private beforeDestroy() {
    void Calculator.CLEAR_DATA('calculations')
  }
}
