
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class WPBaseSwitchItem extends Vue {
  @Prop({ default: false })
  private readonly value?: string | number | boolean

  @Prop({ default: false })
  private readonly selectedValue?: string | number | boolean

  @Prop({ default: '' })
  private readonly label?: string

  @Prop({ default: '' })
  private readonly name?: string

  @Prop({ default: false })
  private readonly disabled?: boolean

  private get computedValue() {
    return this.value
  }

  private set computedValue(value) {
    this.$emit('input', value)
  }

  private onClick() {
    if (!this.disabled) this.$emit('input', this.value)
  }
}
