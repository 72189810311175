
import { Component, Mixins, Prop } from 'vue-property-decorator'
import WPBaseTextfield from './base/WPBaseTextfield.vue'
import WPSelect from './WPSelect.vue'
import { ValidationMixin } from '@/mixins'

import { Unit } from '@/store/units'

@Component({
  components: { WPBaseTextfield, WPSelect },
})
export default class WPTextfield extends Mixins(ValidationMixin) {
  @Prop({ default: '' })
  public readonly value?: string | number

  @Prop({ default: '' })
  private readonly label?: string

  @Prop({ default: '' })
  private readonly type?: string

  @Prop({ default: '' })
  private readonly placeholder?: string

  @Prop({ default: '' })
  private readonly hint?: string

  @Prop({ default: false })
  private readonly white?: boolean

  @Prop({ default: 'auto' })
  private readonly hideDetails?: string | boolean

  @Prop({ default: '' })
  private readonly suffix?: string

  @Prop({ default: null })
  private readonly measure?: number | null

  @Prop({ default: '' })
  private readonly name?: string

  @Prop({ default: false })
  private readonly disabled?: boolean

  private get measureItems() {
    const type = Unit.units.find(
      (item) => item.code === Number(this.measure)
    )?.type
    const units = Unit.units.filter((item) => item.type === type)
    return units
  }

  private get computedMeasure() {
    return this.measure
  }

  private set computedMeasure(value) {
    this.$emit('select', value)
  }

  private onBlur(value: string | number) {
    if (this.value === '') {
      this.$emit('input', '')
      return
    }
    if (this.type === 'number') this.$emit('input', Number(value))
  }

  private onInput(value: string | number) {
    this.$emit('input', value)
  }
}
