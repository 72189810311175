
import { Component, Mixins, Prop } from 'vue-property-decorator'
import WPBaseTextfield from './base/WPBaseTextfield.vue'
import { ValidationMixin } from '@/mixins'

@Component({
  components: { WPBaseTextfield },
})
export default class WPTextfieldPassword extends Mixins(ValidationMixin) {
  @Prop({ default: '' })
  public readonly value?: string

  @Prop({ default: '' })
  private readonly label?: string

  @Prop({ default: '' })
  private readonly placeholder?: string

  @Prop({ default: '' })
  private readonly hint?: string

  @Prop({ default: 'auto' })
  private readonly hideDetails?: string | boolean

  @Prop({ default: true })
  private readonly white?: boolean

  @Prop({ default: false })
  private readonly flat?: boolean

  private icon = 'mdi-eye'

  private showPassword = false

  private onIconClick() {
    this.showPassword = !this.showPassword
    this.$emit('showPass', this.showPassword)
  }

  private onInput(value: string) {
    this.$emit('input', value)
  }
}
