import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import vuetify from '@/plugins/vuetify'
import i18n from '@/plugins/i18n'
import Theme from '@/plugins/themeHelper'
import '@mdi/font/css/materialdesignicons.css'

import infiniteScroll from 'vue-infinite-scroll'

import en from '@/locales/en'
import ru from '@/locales/ru'

Vue.use(VueAxios, axios)
Vue.use(Theme)
Vue.use(infiniteScroll)
Vue.config.productionTip = false

new Vue({
  router,
  vuetify,
  i18n,
  store,
  render: (h) => h(App),
}).$mount('#app')

if (module.hot) {
  module.hot.accept(['./locales/en', './locales/ru'], function () {
    i18n.setLocaleMessage('en', en)
    i18n.setLocaleMessage('ru', ru)
  })
}
