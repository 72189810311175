
import { Component, Prop, Watch, Mixins } from 'vue-property-decorator'
import {
  WPCard,
  WPSelect,
  WPDivider,
  WPCheckCalculationBtns,
} from '@/components'

import { Field } from '@/store/field'
import { Wellbore } from '@/store/wellbore'
import { Calculator } from '@/store/calculators'

import { ICalculatorResult } from '@/types'
import {
  ClustersSearchMixin,
  FieldsSearchMixin,
  WellboresSearchMixin,
  WellsSearchMixin,
  EquipsSearchMixin,
  WellboresStatusNameMixin,
} from '@/mixins'

@Component({
  components: {
    WPCard,
    WPSelect,
    WPDivider,
    WPCheckCalculationBtns,
  },
})
export default class CalculatorsSelects extends Mixins(
  FieldsSearchMixin,
  ClustersSearchMixin,
  WellsSearchMixin,
  WellboresSearchMixin,
  EquipsSearchMixin,
  WellboresStatusNameMixin
) {
  @Prop({ default: '' })
  public readonly calculator?: string

  @Prop({ default: 0 })
  public readonly code?: number

  private selectedField: number | null =
    Number(this.$route.query?.field) || null

  private selectedWell: number | null = Number(this.$route.query?.well) || null

  private selectedCluster: number | null = null

  private selectedWellbore: number | null =
    Number(this.$route.query?.wellbore) || null

  private selectedEquip: number | null =
    Number(this.$route.query?.equip) || null

  private inputData: ICalculatorResult | null = null

  private loading = false

  private get repeatInputData() {
    return this.inputData?.input_data
  }

  private get fields() {
    return Field.fields
  }

  private get wells() {
    return Field.wells
  }

  private get clusters() {
    return Field.clusters
  }

  private get equips() {
    return Wellbore.equips
  }

  private get valid() {
    return (
      this.selectedField &&
      this.selectedWell &&
      this.selectedWellbore &&
      this.selectedEquip
    )
  }

  private cancel() {
    void this.$router.push({
      name: 'Calculators',
    })
  }

  private calculate(copyData = false) {
    const query = {
      field: String(this.selectedField),
      well: String(this.selectedWell),
      wellbore: String(this.selectedWellbore),
      equip: String(this.selectedEquip),
    }

    if (copyData && this.repeatInputData)
      Calculator.SET_INPUT_DATA(this.repeatInputData)

    if (this.calculator)
      void this.$router.push({
        name: `Calculators${this.calculator}`,
        query,
      })
  }

  private goToResult() {
    if (this.inputData) {
      const routeData = this.$router.resolve({
        name: 'ResultsCalculation',
        params: {
          calcId: String(this.inputData.id),
        },
      })
      window.open(routeData.href, '_blank')
    }
  }

  private async selectField(field: number) {
    this.selectedField = field
    this.selectedCluster = null
    this.selectedWell = null
    this.selectedWellbore = null
    this.selectedEquip = null
    this.searchTextWells = ''
    this.searchTextClusters = ''
    void Field.CLEAR_DATA('wells')
    void Field.CLEAR_DATA('clusters')
    await this.onLoadClusters()
    await this.onLoadWells()
  }

  private async selectClusters(cluster: number) {
    this.selectedCluster = cluster
    this.selectedWell = null
    this.selectedWellbore = null
    this.selectedEquip = null
    this.searchTextWells = ''
    void Field.CLEAR_DATA('wells')
    await this.onLoadWells(cluster)
  }

  private async selectWell(well: number) {
    this.selectedWell = well
    this.selectedWellbore = null
    this.selectedEquip = null
    void Field.CLEAR_DATA('wellbores')
    await Field.GET_WELLBORES({ wellId: well })
  }

  private async selectWellbore(wellbore: number) {
    this.selectedWellbore = wellbore
    this.selectedEquip = null
    void Wellbore.CLEAR_DATA('equips')
    await Wellbore.GET_EQUIPS({ wellboreId: wellbore })
  }

  public async onLoadWells(cluster?: number, clear?: boolean) {
    await Field.GET_WELLS({
      fieldId: Number(this.selectedField),
      query: this.queryWells,
      cluster,
      clear,
    })
  }

  public async onLoadClusters(clear?: boolean) {
    await Field.GET_CLUSTERS({
      field: Number(this.selectedField),
      name: this.searchTextClusters,
      clear,
    })
  }

  public async onLoadWellbores(clear?: boolean) {
    await Field.GET_WELLBORES({
      wellId: Number(this.selectedWell),
      query: this.queryWellbores,
      clear,
    })
  }

  public async onLoadEquips(clear?: boolean) {
    await Wellbore.GET_EQUIPS({
      wellboreId: Number(this.selectedWellbore),
      clear,
      query: this.queryEquips,
    })
  }

  @Watch('selectedEquip')
  private async onChangeEquip(val: number | null) {
    if (this.valid) {
      this.loading = true
      const query = {
        field: String(this.selectedField),
        well: String(this.selectedWell),
        wellbore: String(this.selectedWellbore),
        equip: String(this.selectedEquip),
      }

      const result = await Calculator.GET_CALCULATIONS_REPEAT({
        ...query,
        last_date: String(true),
        code: String(this.code),
      })
      this.inputData = result.length ? result[0] : null
      this.loading = false
    } else this.inputData = null
  }

  private async mounted() {
    await Field.GET_FIELDS()
    if (this.selectedField) {
      const cluster = this.selectedCluster
        ? Number(this.selectedCluster)
        : undefined
      await this.onLoadWells(cluster, true)
      await this.onLoadClusters(true)
    }
    if (this.selectedWell) await this.onLoadWellbores(true)
    if (this.selectedWellbore) await this.onLoadEquips(true)
    if (!this.calculator) {
      void this.$router.push({ name: 'Calculators' })
    }
  }
}
