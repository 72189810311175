
import { Component, Vue, Prop } from 'vue-property-decorator'
import WPBaseButton from './base/WPBaseButton.vue'
import WPButton from './WPButton.vue'
import WPInputLabel from './WPInputLabel.vue'

type TColors = string[][]
interface IColor {
  alpha: number
  hex: string
  hexa: string
  hsla: {
    h: number
    s: number
    l: number
    a: number
  }
  hsva: {
    h: number
    s: number
    v: number
    a: number
  }
  hue: number
  rgba: {
    r: number
    g: number
    b: number
    a: number
  }
}

@Component({
  components: {
    WPBaseButton,
    WPButton,
    WPInputLabel,
  },
})
export default class WPColorPicker extends Vue {
  @Prop({ default: () => [] })
  private readonly colors?: TColors

  @Prop({ default: '' })
  private readonly value?: string

  @Prop({ default: '' })
  private readonly label?: string

  private show = false

  private onInput(value: IColor) {
    this.$emit('input', value.hex)
  }

  private onClose() {
    this.show = !this.show
  }
}
