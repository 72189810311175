
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'
import {
  WPButtonIcon,
  WPTextfield,
  WPTextfieldMeasure,
  WPRadioGroup,
  WPBlockBtn,
  WPDivider,
  WPInfoBlock,
  WPGroup,
  WPCombobox,
  WPSwitch,
  WPGroupMultiline,
  WPCheckbox,
  WPColorPicker,
  WPBlockHint,
  WPExpPanel,
} from '@/components'
import TheWellboreIntervalElementAreas from './TheWellboreIntervalElementAreas.vue'

import { RouteParamsMixin } from '@/mixins'

import { Wellbore } from '@/store/wellbore'
import { Unit } from '@/store/units'

import { TElementTypes, TElements, IElement, IInterval } from '@/types'

import { eventBus } from '@/helpers/eventBus'
import { timeout } from '@/services/common'

export interface IElementUnitKeys {
  length_uc: number
  outer_diameter_uc: number
  inner_diameter_uc: number
  wall_thickness_uc: number
  repeat_length_uc: number
}

const mockElement: IElement = {
  elementtype: null,
  number: null,
  color: '',
  length: null,
  length_uc: 100,
  repeatability: false,
  repeat_length: 0,
  repeat_length_uc: 100,
  full_length: false,
  complexity: false,
  outer_diameter: null,
  outer_diameter_uc: 100,
  inner_diameter_or_thickness: false,
  wall_thickness: null,
  wall_thickness_uc: 100,
  inner_diameter: null,
  inner_diameter_uc: 100,
}

@Component({
  components: {
    WPButtonIcon,
    WPTextfield,
    WPTextfieldMeasure,
    WPRadioGroup,
    WPBlockBtn,
    WPDivider,
    WPInfoBlock,
    WPGroup,
    WPCombobox,
    WPSwitch,
    WPGroupMultiline,
    WPCheckbox,
    WPColorPicker,
    TheWellboreIntervalElementAreas,
    WPBlockHint,
    WPExpPanel,
  },
})
export default class TheWellboreIntervalElements extends Mixins(
  RouteParamsMixin
) {
  @Prop({ type: Number, required: true })
  private interval?: number

  @Prop({ type: [Number, String], default: 0 })
  private maxLength?: number | string

  @Prop({ type: Number, default: 100 })
  private maxLengthUc?: number

  @Prop({ type: Boolean, default: false })
  private validParent?: boolean

  @Prop({ type: Boolean, default: true })
  private isParamsOpened?: boolean

  private maxLengthWithUc = 0

  private valid = false

  private editIndex: number | null = null

  private editId?: number = undefined

  private isFormOpened = false

  private isShowAddBtnList = false

  private isShowAddBtnListId = 0

  private isShowEditBtnList = false

  private isShowEditBtnListId = 0

  private busy = false

  private colors = [
    ['#000000', '#b183ed'],
    ['#7f7f7f', '#96b5d1'],
    ['#afabab', '#a4c290'],
    ['#eff5a2', '#aefdf9'],
    ['#dea883', '#00b0f0'],
  ]

  private element: IElement = {
    ...mockElement,
  }

  private get elementTypeMethods() {
    return [
      {
        text: this.$t('elements.selectInner'),
        value: true,
      },
      {
        text: this.$t('elements.selectThickness'),
        value: false,
      },
    ]
  }

  private get isEdit() {
    return this.editIndex !== null
  }

  private get elementTypes(): TElementTypes {
    return Wellbore.elementTypes
  }

  private get intervalObj(): IInterval | undefined {
    const result = Wellbore.intervals.find((item) => item.id === this.interval)
    return result
  }

  private get elements(): TElements {
    return Wellbore.elements
  }

  private get listElements(): TElements {
    return [...this.elements].sort(
      (a, b) => Number(b.number) - Number(a.number)
    )
  }

  private get isAreas(): boolean {
    return !!Wellbore.areas.length
  }

  private get isBtnDisabled(): boolean {
    return !this.valid || (this.element.complexity && !this.isAreas)
  }

  private get isFullLengthEnabled() {
    if (this.isShowAddBtnList) {
      return this.isShowAddBtnListId === this.listElements[0].id
    } else return false
  }

  private changeMeasure(val: number, key: keyof IElementUnitKeys) {
    this.element[key] = val
  }

  private getElementTypeName(obj: IElement) {
    return typeof obj.elementtype === 'object'
      ? obj.elementtype?.name
      : Wellbore.elementTypes.find((item) => item.id === obj.elementtype)?.name
  }

  private getUnitSymbol(code: number) {
    return Unit.units.find((item) => item.code === code)?.symbol
  }

  private getElementInfo(item: IElement) {
    const unitLength = String(this.getUnitSymbol(item.length_uc))
    const unitLengthRepeat = String(this.getUnitSymbol(item.repeat_length_uc))

    const repeat = item.repeatability
    const full = item.full_length
    const simple = !item.complexity
    const data = {
      length: item.length,
      lengthUc: unitLength,
    }
    const dataRepeat = {
      length: item.length,
      lengthUc: unitLength,
      repeat: item.repeat_length,
      repeatUc: unitLengthRepeat,
    }

    if (repeat) {
      if (full) {
        if (simple) {
          return this.$t('elements.elementInfo.repeatFullSimple', data)
        } else {
          return this.$t('elements.elementInfo.repeatFullCompl', data)
        }
      } else {
        if (simple) {
          return this.$t('elements.elementInfo.repeatLengthSimple', dataRepeat)
        } else {
          return this.$t('elements.elementInfo.repeatLengthCompl', dataRepeat)
        }
      }
    } else {
      if (full) {
        if (simple) {
          return this.$t('elements.elementInfo.soloFullSimple', data)
        } else {
          return this.$t('elements.elementInfo.soloFullCompl', data)
        }
      } else {
        if (simple) {
          return this.$t('elements.elementInfo.soloLengthSimple', data)
        } else {
          return this.$t('elements.elementInfo.soloLengthCompl', data)
        }
      }
    }
  }

  private getElementTypeColor(item: IElement) {
    const { elementtype } = item
    if (typeof elementtype === 'object') {
      return elementtype?.color
    } else {
      const color = Wellbore.elementTypes.find(
        (item) => item.id === elementtype
      )
      return color ? color.color : ''
    }
  }

  private clearColor() {
    this.element.color = ''
  }

  private toggleAddBtnList(val: boolean, id: number) {
    this.isShowAddBtnList = val
    this.isShowAddBtnListId = id
  }

  private toggleEditBtnList(val: boolean, id: number) {
    this.isShowEditBtnList = val
    this.isShowEditBtnListId = id
  }

  private showAddBtnList(id: number, index: number) {
    const currentBtn = this.isShowAddBtnList && this.isShowAddBtnListId === id
    const currentEditBtn =
      this.isShowEditBtnList && this.isShowEditBtnListId === id
    const isLast = index === 0
    let isHasEmptyNumber = false
    if (!isLast) {
      const numCurr = this.listElements[index].number || 0
      const numNext = this.listElements[index - 1].number || 0
      isHasEmptyNumber = numNext - numCurr > 1
      return (currentBtn && isHasEmptyNumber) || currentEditBtn
    } else return true
  }

  private visibleAddBtnList(id: number, index: number) {
    const isLast = index === 0
    let isHasEmptyNumber = false
    if (!isLast) {
      const numCurr = this.listElements[index].number || 0
      const numNext = this.listElements[index - 1].number || 0
      isHasEmptyNumber = numNext - numCurr > 1
      return isHasEmptyNumber
    } else return true
  }

  private async resetForm(isCloseForm = false, isScrollForm = true) {
    const form = this.$refs.intervalsForm as Array<
      Vue & { resetValidation: () => void }
    >
    if (form[0]) form[0].resetValidation()

    if (isCloseForm) {
      this.isFormOpened = false
      this.toggleAddBtnList(false, 0)
      this.toggleEditBtnList(false, 0)
    }
    if (isScrollForm) {
      await this.scrollFunc()
    }
  }

  private async editPoint(item: IElement, index: number) {
    if (this.editId !== item.id) {
      this.toggleEditBtnList(true, Number(item.id))
      this.toggleAddBtnList(true, Number(item.id))
      if (this.validParent) {
        this.$emit('save')
      }

      this.editId = item.id
      this.editIndex = index
      this.element = { ...item }
      await this.onChangeFullRepeat(item.full_length)

      if (!this.isFormOpened) {
        this.isFormOpened = true
        await timeout(0)
      }
      await this.scrollFunc()
    }
  }

  private async scrollFunc(isTimer = true) {
    const el = document.querySelector(
      '.wp-form__exp-form_opened'
    ) as HTMLElement

    if (el) {
      if (isTimer) await timeout(300)
      el.scrollIntoView({ block: 'start', behavior: 'smooth' })
    }
  }

  private async addElement(isCloseForm = false, isScrollForm = true) {
    this.busy = true
    const valid = await this.checkElementLength()

    if (!valid) {
      eventBus.$emit('showError', `length | ${this.maxLengthWithUc}`)
      this.busy = false
      return
    }

    if (typeof this.element.elementtype === 'string') {
      const newType = await Wellbore.ADD_ELEMENT_TYPES(this.element.elementtype)
      if (newType) {
        this.element.elementtype = newType
      }
    }

    if (this.isEdit) {
      await this.saveElement(true, isScrollForm)
      return
    }

    const element = {
      ...this.element,
      interval: this.interval,
    }

    const newElem = await Wellbore.ADD_ELEMENT(element)
    await Wellbore.RELOAD_INTERVALS(Number(this.wellboreId))

    if (isCloseForm && newElem) {
      const index = this.listElements.findIndex(
        (item) => item.id === newElem.id
      )
      await this.editPoint(newElem, index)
      this.busy = false
    } else if (newElem) {
      const noNumber = this.elements.find(
        (item) => item.number === Number(newElem?.number) + 1
      )
      if (noNumber) {
        this.element = {
          ...mockElement,
          number: Number(this.listElements[0].number) + 1,
        }
        this.toggleAddBtnList(true, Number(this.listElements[0].id))
      } else {
        this.element = {
          ...mockElement,
          number: Number(newElem?.number) + 1,
        }
        this.toggleAddBtnList(true, Number(newElem?.id))
      }
      // await this.resetForm(isCloseForm, false)
      // this.isFormOpened = true
      if (isScrollForm) {
        await timeout(300)
        await this.scrollFunc()
      }
    }
    this.busy = false
  }

  private async checkElementLength() {
    let elementsLength = 0
    let promises: Promise<number | null>[] = []
    this.listElements.forEach((item, index) => {
      let length = 0
      let lengthUc = 0
      if (index === this.editIndex) {
        length = this.element.repeatability
          ? this.element.repeat_length
          : Number(this.element.length)
        lengthUc = this.element.repeatability
          ? this.element.repeat_length_uc
          : this.element.length_uc
      } else {
        length = item.repeatability ? item.repeat_length : Number(item.length)
        lengthUc = item.repeatability ? item.repeat_length_uc : item.length_uc
      }
      promises.push(
        Unit.CONVERSE_UNITS({
          sourceVal: length,
          sourceUnit: lengthUc,
          destUnit: 100,
        })
      )
    })
    if (this.editIndex === null) {
      promises.push(
        Unit.CONVERSE_UNITS({
          sourceVal: this.element.repeatability
            ? this.element.repeat_length
            : Number(this.element.length),
          sourceUnit: this.element.repeatability
            ? this.element.repeat_length_uc
            : this.element.length_uc,
          destUnit: 100,
        })
      )
    }
    await Promise.all(promises).then((values) => {
      values.map((value) => {
        if (value) elementsLength = elementsLength + value
      })
    })

    return Number(elementsLength.toFixed(10)) <= this.maxLengthWithUc
  }

  private async saveElement(isCLoseForm = false, isScrollForm = true) {
    const element = {
      ...this.element,
      interval: this.interval,
    }
    const resp = await Wellbore.SAVE_ELEMENT(element)

    if (resp) {
      this.editIndex = null
      this.editId = undefined
      this.element = {
        ...mockElement,
        number: Number(this.listElements[0].number) + 1,
      }
      await this.resetForm(isCLoseForm, isScrollForm)
    }
    this.busy = false
  }

  private async recalculateLength() {
    const maxLength = await Unit.CONVERSE_UNITS({
      sourceVal: Number(this.maxLength),
      sourceUnit: Number(this.maxLengthUc),
      destUnit: this.element.repeatability
        ? this.element.repeat_length_uc
        : this.element.length_uc,
    })
    this.maxLengthWithUc = maxLength !== null ? maxLength : 0
  }

  private onHoverBlockBtnList(value: boolean, id: number) {
    if (!this.isFormOpened && !this.isEdit) this.toggleAddBtnList(value, id)
  }

  private onBlurBlockBtnList() {
    if (!this.isFormOpened && !this.isEdit) this.toggleAddBtnList(false, 0)
  }

  private async onClickExpPanel(value: boolean, item: IElement) {
    if (value !== undefined && item.id) this.toggleAddBtnList(value, item.id)
    if (this.validParent) {
      this.$emit('save')
      if (this.isFormOpened) {
        this.isFormOpened = false
      } else {
        this.isFormOpened = true
        if (!this.isEdit) this.element.number = Number(item.number) + 1
      }
      if (value) {
        await timeout(100)
        await this.scrollFunc()
      }
    }
  }

  private async onClickExpEmptyPanel(value: boolean) {
    if (this.validParent) {
      this.$emit('save')
      if (this.isFormOpened) {
        this.isFormOpened = false
      } else {
        this.isFormOpened = true
        this.element.number = 1
      }
      if (value) {
        await timeout(100)
        await this.scrollFunc()
      }
    }
  }

  private async onLoadElementTypes() {
    await Wellbore.GET_ELEMENT_TYPES()
  }

  private async onDelete(id: number) {
    await Wellbore.DELETE_ELEMENT(id)
    await Wellbore.RELOAD_INTERVALS(Number(this.wellboreId))
    this.$emit('onDeleteElement')
  }

  @Watch('element.full_length')
  private async onChangeFullRepeat(val: boolean) {
    if (val && this.intervalObj) {
      const resCheck = await Wellbore.CHECK_INTERVAL({
        id: Number(this.intervalObj.id),
        elementId: this.isEdit ? this.element.id : undefined,
      })
      const result = Number(resCheck?.remain_length)

      if (this.element.repeatability) {
        const resultWithUc = await Unit.CONVERSE_UNITS({
          sourceVal: result,
          sourceUnit: 100,
          destUnit: this.element.repeat_length_uc,
        })

        this.element.repeat_length =
          Number(resultWithUc) < 0 ? 0 : Number(resultWithUc)
      } else {
        const resultWithUc = await Unit.CONVERSE_UNITS({
          sourceVal: result,
          sourceUnit: 100,
          destUnit: this.element.length_uc,
        })

        this.element.length =
          Number(resultWithUc) < 0 ? 0 : Number(resultWithUc)
      }
    }
  }

  @Watch('element.repeatability')
  private async onChangeRepeatability(val: boolean) {
    await this.onChangeFullRepeat(this.element.full_length)
  }

  @Watch('elements')
  private async onLoadElements(val: TElements) {
    if (val && val.length) {
      if (this.$route.query.type === 'element') {
        const arr = this.listElements
        const id = Number(this.$route.query.id)
        const item = arr.find((item) => Number(item.id) === id)
        const itemIndex = arr.findIndex((item) => Number(item.id) === id)
        if (item) {
          await this.editPoint(item, itemIndex)
        }
      }
      if (!this.isEdit)
        this.element.number = Number(this.listElements[0].number) + 1
    }

    this.busy = false
  }

  @Watch('maxLength')
  private async onChangeMaxLength(val: number) {
    await this.recalculateLength()
    await this.onChangeFullRepeat(this.element.full_length)
  }

  @Watch('maxLengthUc')
  private async onChangeMaxLengthUc(val: number) {
    await this.recalculateLength()
    await this.onChangeFullRepeat(this.element.full_length)
  }

  @Watch('element.length_uc')
  private async onChangeLengthUc(val: number) {
    await this.recalculateLength()
  }

  @Watch('element.repeat_length_uc')
  private async onChangeRepeatLengthUc(val: number) {
    await this.recalculateLength()
  }

  private async mounted() {
    await Promise.all([
      Wellbore.GET_ELEMENTS(Number(this.interval)),
      Wellbore.GET_ELEMENT_TYPES(),
    ])
    await this.recalculateLength()
  }
}
