
import { Component, Mixins } from 'vue-property-decorator'
import { Field } from '@/store/field'
import { WPCard, WPButtonMenu, WPButtonIcon, WPTextfield } from '@/components'
import { RouteParamsMixin } from '@/mixins'
import { eventBus } from '@/helpers/eventBus'

@Component({
  components: {
    WPCard,
    WPButtonMenu,
    WPButtonIcon,
    WPTextfield,
  },
})
export default class TheWellboreMain extends Mixins(RouteParamsMixin) {
  private wellboreTitle = ''

  private cancel() {
    void this.$router.push({
      name: 'TheWellbore',
      params: {
        id: String(this.fieldId),
        wellId: String(this.wellId),
        wellboreId: String(this.wellboreId),
      },
    })
  }

  private async saveWellbore() {
    if (this.wellbore && this.well) {
      await Field.SAVE_WELLBORE({
        ...this.wellbore,
        name: this.wellboreTitle,
        well: Number(this.wellId),
      })
    }
  }

  private get isActivated() {
    return this.wellbore?.activate_integration
  }

  private get isFactWellbore() {
    return this.isActivated && this.wellbore && this.wellbore.status === 3
  }

  private get getWellboreType() {
    if (this.isActivated) {
      switch (this.wellbore?.status) {
        case 1:
          return this.$t('wellbore.activation.staticWellbore')

        case 2:
          return this.$t('wellbore.activation.dynamicWellbore')

        case 3:
          return this.$t('wellbore.activation.factWellbore')

        default:
          break
      }
    } else return ''
  }

  private onClickInfo() {
    eventBus.$emit('wellboreShowInfo')
  }

  private async mounted() {
    if (!this.field) await Field.GET_FIELDS()
    if (!this.well)
      await Field.GET_WELLS({ fieldId: Number(this.$route.params.id) })
    if (!this.wellbore)
      await Field.GET_WELLBORES({
        wellId: Number(this.$route.params.wellId),
        query: { status: '1' },
      })
    this.wellboreTitle = this.wellbore ? this.wellbore.name : ''
  }
}
