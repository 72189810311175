import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule,
} from 'vuex-module-decorators'
import store from '@/store'

import { IInitSettings } from '@/types'
import { getInitSettings } from '@/services/init'

export interface IInitState {
  settings: IInitSettings
}

@Module({ dynamic: true, store, name: 'init' })
class InitState extends VuexModule implements IInitState {
  public settings: IInitSettings = {
    enable_main_swagger: false,
    enable_integration_swagger: false,
    enable_user_registration: false,
    enable_wellbore_integration: false,
    enable_calc_2_additional: false,
  }

  @Mutation
  public SetInitSettings(settings: IInitSettings) {
    this.settings = { ...settings }
  }

  @Action
  public async GET_INIT_SETTINGS() {
    const data = await getInitSettings()
    if (data) this.SetInitSettings(data)
    return data
  }
}

export const Init = getModule(InitState)
