
import { Component, Vue, Prop } from 'vue-property-decorator'
import WPButton from './WPButton.vue'
import WPCard from './WPCard.vue'

import { eventBus } from '@/helpers/eventBus'

@Component({
  components: {
    WPButton,
    WPCard,
  },
})
export default class WPDialog extends Vue {
  @Prop({ default: 600 })
  public readonly maxWidth?: number

  @Prop({ default: '' })
  public readonly title?: string

  @Prop({ default: '' })
  public readonly text?: string | string[] | null

  @Prop({ default: false })
  public readonly value?: boolean

  @Prop({ default: '' })
  public readonly btnDismissText?: string

  @Prop({ default: '' })
  public readonly btnAcceptText?: string

  @Prop({ default: '' })
  public readonly classes?: string

  @Prop({ default: false })
  public readonly withSlot?: boolean

  @Prop({ default: true })
  public readonly buttonsLine?: boolean

  @Prop({ default: false })
  public readonly buttonsSwap?: boolean

  @Prop({ default: true })
  public readonly globalModal?: boolean

  @Prop({ default: true })
  public readonly persistent?: boolean

  private get textArray() {
    return typeof this.text === 'object'
  }

  private onChangeValue(value: boolean) {
    this.$emit('input', value)
  }

  private onClick() {
    this.$emit('input', false)
    this.$emit('dialogClick')
    if (this.globalModal) eventBus.$emit('dialogClick')
  }

  private onDismiss() {
    this.$emit('input', false)
    this.$emit('dialogDismiss')
    if (this.globalModal) eventBus.$emit('dialogDismiss')
  }
}
