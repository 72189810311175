
import { Component, Prop, Mixins } from 'vue-property-decorator'
import WPBaseSelect from './base/WPBaseSelect.vue'
import { ValidationMixin } from '@/mixins'

@Component({
  components: { WPBaseSelect },
})
export default class WPSelect extends Mixins(ValidationMixin) {
  @Prop({ default: () => [] })
  private readonly items?: Record<string, any>[]

  @Prop({ default: '' })
  public readonly value?: string | number | any

  @Prop({ default: '' })
  private readonly label?: string

  @Prop({ default: '' })
  private readonly hint?: string

  @Prop({ default: false })
  private readonly white?: boolean

  @Prop({ default: '' })
  private readonly itemText?: string

  @Prop({ default: '' })
  private readonly itemValue?: string

  @Prop({ default: 'auto' })
  private readonly hideDetails?: string | boolean

  @Prop({ default: true })
  private readonly outlined?: boolean

  @Prop({ default: false })
  private readonly disabled?: boolean

  @Prop({ default: false })
  private readonly clearable?: boolean

  @Prop({ default: '' })
  private readonly noDataText?: string

  @Prop({ default: false })
  private readonly isMeasure?: boolean

  @Prop({ default: false })
  private readonly search?: boolean

  @Prop({ default: false })
  private readonly loading?: boolean

  @Prop({ default: null })
  private readonly searchText?: string | null

  @Prop({ default: false })
  private readonly returnObject?: boolean

  private timerId?: number = undefined

  private get computedValue() {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access
    if (!!this.value && typeof this.value === 'object') return this.value?.id

    if (typeof this.value === 'string' || typeof this.value === 'number')
      return this.value
  }

  private set computedValue(value) {
    this.$emit('input', value)
  }

  private get getNoDataText() {
    return this.noDataText ? this.noDataText : this.$t('components.selectEmpty')
  }

  private onLoadItems() {
    this.$emit('onLoadItems')
  }

  private onSearch(val: string) {
    void clearTimeout(this.timerId)

    this.timerId = setTimeout(() => {
      this.$emit('onSearch', val)
    }, 300)
  }
}
