
import { Component, Vue } from 'vue-property-decorator'
import { WPCard, WPTextfield, WPSelect, WPButton } from '@/components'
import { User } from '@/store/user'
import { ISupportRequestLang } from '@/types'

import { eventBus } from '@/helpers/eventBus'

@Component({
  components: {
    WPCard,
    WPTextfield,
    WPSelect,
    WPButton,
  },
})
export default class SettingsSupport extends Vue {
  private loading = false

  private valid = false

  private request: ISupportRequestLang = {
    type: null,
    email: '',
    message: '',
    lang: '',
  }

  private get supportTypes() {
    return User.supportTypes
  }

  private async send() {
    this.loading = true
    await User.SEND_SUPPORT_REQUEST(this.request)
    void eventBus.$emit('closeSupport')
    this.loading = false
  }

  private async mounted() {
    await User.GET_SUPPORT_TYPES()
  }
}
