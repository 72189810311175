import { eventBus, getError } from '@/helpers/eventBus'

import axiosInstance from '@/services/api'
import {
  IField,
  IWell,
  IWellbore,
  TFields,
  TWells,
  TWellTypes,
  TWellbores,
  IWellTypes,
  IPagedData,
  ICluster,
  TClusters,
} from '@/types'

import { DataMock } from '@/helpers/consts'
import { Dictionary } from 'vue-router/types/router'

export async function getFields(
  page?: number | string,
  query?: Dictionary<string>
): Promise<IPagedData<TFields>> {
  try {
    const response = await axiosInstance.get<IPagedData<TFields>>('/fields/', {
      params: { page, ...query },
    })
    if (response?.data) {
      return response?.data
    }
    return DataMock
  } catch (error: unknown) {
    eventBus.$emit('showError', getError(error))
    return DataMock
  }
}

export async function getFieldById(fieldId: number): Promise<IField | null> {
  try {
    const response = await axiosInstance.get<IField>(`/fields/${fieldId}/`)
    if (response?.data) {
      return response.data
    }

    return null
  } catch (error: unknown) {
    eventBus.$emit('showError', getError(error))
    return null
  }
}

export async function newField(field: IField): Promise<IField | null> {
  try {
    const response = await axiosInstance.post<IField>('/fields/', field)
    if (response?.data) {
      eventBus.$emit('showAlert', `field | ${response.data.name}`)
      return response.data
    }

    return null
  } catch (error: unknown) {
    eventBus.$emit('showError', getError(error))
    return null
  }
}

export async function saveField(field: IField) {
  try {
    const id = String(field.id)
    delete field.id
    const response = await axiosInstance.put<IField>(`/fields/${id}/`, field)
    if (response?.data) {
      eventBus.$emit('showAlert', `fieldSave | ${response.data.name}`)
      return response.data
    }

    return null
  } catch (error: unknown) {
    eventBus.$emit('showError', getError(error))
    return null
  }
}

export async function deleteField(fieldId: number) {
  try {
    const response = await axiosInstance.delete<IField>(`/fields/${fieldId}/`)
    if (response?.data) {
      eventBus.$emit('showAlert', `fieldDelete | ${response.data.name}`)
      return
    }

    return null
  } catch (error: unknown) {
    eventBus.$emit('showError', getError(error))
    return null
  }
}

export async function getWell(wellId: number): Promise<IWell | null> {
  try {
    const response = await axiosInstance.get<IWell>(`/wells/${wellId}/`)
    if (response?.data) {
      return response.data
    }

    return null
  } catch (error: unknown) {
    eventBus.$emit('showError', getError(error))
    return null
  }
}

export async function getWellTypes(
  page: number | string = '',
  query?: Dictionary<string>
) {
  try {
    const response = await axiosInstance.get<IPagedData<TWellTypes>>(
      '/welltypes/',
      {
        params: { page, ...query },
      }
    )
    if (response?.data) {
      return response.data
    }

    return DataMock
  } catch (error: unknown) {
    eventBus.$emit('showError', getError(error))
    return DataMock
  }
}

export async function addWellType(name: string): Promise<IWellTypes | null> {
  try {
    const response = await axiosInstance.post<IWellTypes>(`/welltypes/`, {
      name,
    })
    if (response?.data) {
      return response.data
    }

    return null
  } catch (error: unknown) {
    eventBus.$emit('showError', getError(error))
    return null
  }
}

export async function getWellsByFieldId(
  fieldId?: number,
  page?: number | string,
  query?: Dictionary<string>,
  cluster?: number
): Promise<IPagedData<TWells>> {
  try {
    const response = await axiosInstance.get<IPagedData<TWells>>(`/wells/`, {
      params: { field: fieldId, page, cluster, ...query },
    })
    if (response?.data) {
      return response.data
    }

    return DataMock
  } catch (error: unknown) {
    eventBus.$emit('showError', getError(error))
    return DataMock
  }
}

export async function getWells(
  page?: number | string,
  query?: Dictionary<string>
): Promise<IPagedData<TWells>> {
  try {
    const response = await axiosInstance.get<IPagedData<TWells>>(`/wells/`, {
      params: { page, ...query },
    })
    if (response?.data) {
      return response.data
    }

    return DataMock
  } catch (error: unknown) {
    eventBus.$emit('showError', getError(error))
    return DataMock
  }
}

export async function newWell(well: IWell): Promise<IWell | null> {
  try {
    const response = await axiosInstance.post<IWell>(`/wells/`, well)
    if (response?.data) {
      eventBus.$emit('showAlert', `well | ${response.data.name}`)
      return response.data
    }

    return null
  } catch (error: unknown) {
    eventBus.$emit('showError', getError(error))
    return null
  }
}

export async function saveWell(well: IWell) {
  try {
    const id = String(well.id)
    delete well.id
    const response = await axiosInstance.put<IWell>(`/wells/${id}/`, well)
    if (response?.data) {
      eventBus.$emit('showAlert', `wellSave | ${response.data.name}`)
      return response.data
    }

    return null
  } catch (error: unknown) {
    eventBus.$emit('showError', getError(error))
    return null
  }
}

export async function deleteWell(wellId: number) {
  try {
    const response = await axiosInstance.delete<IField>(`/wells/${wellId}/`)
    if (response?.data) {
      eventBus.$emit('showAlert', `wellDelete | ${response.data.name}`)
      return
    }

    return null
  } catch (error: unknown) {
    eventBus.$emit('showError', getError(error))
    return null
  }
}

export async function getWellboresByWellId(
  wellId?: number,
  page: number | string = '',
  query?: Dictionary<string>
): Promise<IPagedData<TWellbores>> {
  try {
    const response = await axiosInstance.get<IPagedData<TWellbores>>(
      `/wellbores/`,
      {
        params: { well: wellId, page, ...query },
      }
    )
    if (response?.data) {
      return response.data
    }

    return DataMock
  } catch (error: unknown) {
    eventBus.$emit('showError', getError(error))
    return DataMock
  }
}

export async function getWellbore(
  wellboreId: number
): Promise<IWellbore | null> {
  try {
    const response = await axiosInstance.get<IWellbore>(
      `/wellbores/${wellboreId}/`
    )
    if (response?.data) {
      return response.data
    }

    return null
  } catch (error: unknown) {
    eventBus.$emit('showError', getError(error))
    return null
  }
}

export async function newWellbore(wellbore: IWellbore) {
  try {
    const response = await axiosInstance.post<IWellbore>(
      `/wellbores/`,
      wellbore
    )
    if (response?.data) {
      eventBus.$emit('showAlert', `wellbore | ${response.data.name}`)
      return response.data
    }

    return null
  } catch (error: unknown) {
    eventBus.$emit('showError', getError(error))
    return null
  }
}

export async function saveWellbore(wellbore: IWellbore, isAlert = true) {
  try {
    const id = String(wellbore.id)
    delete wellbore.id
    const response = await axiosInstance.patch<IWellbore>(
      `/wellbores/${id}/`,
      wellbore
    )
    if (response?.data && isAlert) {
      eventBus.$emit('showAlert', `wellboreSaved | ${response.data.name}`)
      return response.data
    }

    return null
  } catch (error: unknown) {
    eventBus.$emit('showError', getError(error))
    return null
  }
}

export async function deleteWellbore(wellboreId: number) {
  try {
    const response = await axiosInstance.delete<any>(
      `/wellbores/${wellboreId}/`
    )
    if (response?.data) {
      eventBus.$emit('showAlert', `wellboreDelete`)
      return
    }

    return null
  } catch (error: unknown) {
    eventBus.$emit('showError', getError(error))
    return null
  }
}

export async function cloneWellbore(wellboreId: number) {
  try {
    const response = await axiosInstance.post<IWellbore>(
      `/wellbores/clone/${wellboreId}/`
    )
    if (response?.data) {
      eventBus.$emit('showAlert', `wellboreClone`)
      return response.data
    }

    return null
  } catch (error: unknown) {
    eventBus.$emit('showError', getError(error))
    return null
  }
}

export async function getClusters(
  field?: number,
  page?: number | string,
  name?: string
) {
  try {
    const response = await axiosInstance.get<IPagedData<TClusters>>(
      '/clusters/',
      {
        params: { page, field, name },
      }
    )
    if (response?.data) {
      return response.data
    }

    return DataMock
  } catch (error: unknown) {
    eventBus.$emit('showError', getError(error))
    return DataMock
  }
}

export async function addCluster(data: ICluster): Promise<ICluster | null> {
  try {
    const response = await axiosInstance.post<ICluster>(`/clusters/`, {
      ...data,
    })
    if (response?.data) {
      return response.data
    }

    return null
  } catch (error: unknown) {
    eventBus.$emit('showError', getError(error))
    return null
  }
}
