import { ITheme } from '@/types/theme'

const colors = {
  black: '#333333',
  white: '#FFFFFF',
  bglight: '#f0f2f5',

  brand: '#FF4606',
  brandDark: '#943919', // orange dark
  brandLight: '#fce1c7', // orange light

  gray: '#B5B5B5',
  grayDark: '#8F8F8F',
  grayLight: '#E8E8E8',
  grayUltraLight: '#F4F4F4',
  grayBg: '#eae3ea',

  red: '#E62100',
  redLight: '#EB5000',
  green: '#009639',
  greenLight: '#F4FFF8',
  greenDark: '#375832',
  yellowLight: '#FFF8EE',

  themeOrange: '#FF4606',
  themeBlue: '#0094D9',
  themeBlack: '#333333',
  themeGreen: '#009639',
}

const OrangeTheme: ITheme = {
  text: colors.black,
  primary: colors.brand,
  secondary: colors.brandDark,
  bg: colors.bglight,
  warning: colors.brandLight,
  muted: colors.grayBg,
  info: colors.yellowLight,
  dark: colors.black,
  darkGrey: colors.grayDark,
  lightGrey: colors.grayLight,
  gray: colors.gray,
  success: colors.green,
  error: colors.red,
  white: colors.white,
  themeOrange: colors.themeOrange,
  themeBlue: colors.themeBlue,
  themeBlack: colors.themeBlack,
  themeGreen: colors.themeGreen,
}

export default OrangeTheme
