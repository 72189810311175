import { Component, Vue } from 'vue-property-decorator'

import { Field } from '@/store/field'

@Component
class WellboresSearchMixin extends Vue {
  public selectLoadingWellbores = false

  public searchTextWellbores: string | undefined = ''

  public get queryWellbores() {
    return this.searchTextWellbores
      ? { name: this.searchTextWellbores }
      : undefined
  }

  public async onSearchWellbores(search = '') {
    this.searchTextWellbores = search
    this.selectLoadingWellbores = true
    void Field.CLEAR_DATA('wellbores')
    await this.onLoadWellbores()
    this.selectLoadingWellbores = false
  }

  public async onLoadWellbores() {
    await Field.GET_WELLBORES({})
  }
}

export default WellboresSearchMixin
