export const ru = {
  common: {
    enterName: 'Введите наименование',
    enterValue: 'Введите значение',
    enterDate: 'Выберите дату',
    enterSearch: 'Введите текст для поиска',
    search: 'Поиск',
    ok: 'ОК',
    addButton: 'Добавить',
    cancelButton: 'Отмена',
    sendButton: 'Отправить',
    back: 'Закрыть',
    config: 'Настройки',
    logout: 'Выход',
    confirm: 'Применить',
    save: 'Сохранить',
    up: 'Вверх',
    down: 'Вниз',
    continue: 'Продолжить',
    apply: 'Подтвердить',
    notSelected: 'Не выбрано',
    support: 'Поддержка',
  },

  dict: {
    field: 'Месторождение',
    well: 'Скважина',
    wellbore: 'Ствол',
    wellboreType: 'Тип ствола',
    wellboreFull: 'Ствол скважины',
    equip: 'Компоновка',
    equipFull: 'Компоновка оборудования',
    interval: 'Интервал',
    cluster: 'Куст',
  },

  errorPage: {
    title: 'Ошибка',
    link: 'Ссылка недействительна',
    back: 'Вернуться на главную',
  },

  permissionPage: {
    title: 'Ошибка',
    link: 'У вас нет доступа для просмотра этой страницы',
    back: 'Вернуться на главную',
  },

  components: {
    alertError: 'Код ошибки',
    breadcrumbs: {
      calc: 'Моделирование и расчет',
      calcResult: 'Результат расчета',
      settings: 'Настройки',
      params: 'Параметры системы',
      support: 'Поддержка',
      report: 'Сообщить о проблеме',
    },
    selectEmpty: 'Нет данных',
    color: 'Выберите цвет',
    datetime: 'Выберите дату и время',
    datetimeLabel: 'Дата и время',
  },

  hints: {
    altitude: 'Альтитуда – высота над уровнем моря',
    declination:
      'Угол между географическим и магнитным азимутами в точке устья скважины',
    intervalChange:
      'Не рекомендуется изменять интервал спуска после создания элементов компоновки. Если это необходимо, то после изменения интервала обязательно проверьте длины элементов!',
    initData: 'Перед расчётом проверьте заполнение исходных данных!',
    techOperation:
      'При автоматическом задании коэффициента сопротивления придвижении инструмента вверх берется значение - 1.15; вниз – 0.85',
    fortress:
      'Эквивалент показателя крепости горной породы для любого разбуриваемого материала. Для горных пород принимает значения: 7.8 – для мягких пород; 6.9 – для средних; 5.5 – для твердых',
    calcStep: 'Расстояние между точками, в которых происходит расчет',
    repeat:
      'Элемент может последовательно повторяться на заданном интервале конструкции скважины',
    complexity:
      'Для преобразования элемента в сложный необходимо сохранить элемент и зайти в окно редактирования. Сложный элемент дает возможность создания пользовательского элемента с любыми геометрическими характеристиками',
    innerDiameterOrThickness:
      'Для определенных типов элементов внутренний диаметр/толщина стенки задается условно (например для долота, ЗД и др.)',
    interval:
      'Интервал – участок скважины, пробуренный долотом одного диаметра',
    coef: 'Значения коэффициента могут варьироваться от 0.1 до 0.5',
    cavernosity:
      'Коэффициент кавернозности показывает во сколько раз увеличивается объем или площадь ствола относительно ствола, соответствующего диаметру долота',
    changeInterval:
      'Не рекомендуется изменять интервал спуска колонны после создания элементов колонны. Если это необходимо, то после изменения интервала обязательно проверьте длины элементов!',
    diffConnection:
      '"На весь инструмент" - расчёт соединений производится внутри элемента с расчетным шагом, соединения однотипны и имеют одинаковые характеристики.\n"Только на концах элемента" - расчет производится по двум соединениям на разных концах элемента, тип и характеристики задаются для каждого соединения отдельно',
    customConnection:
      'Другое - пользовательский тип соединения, для которого задаются допускаемые растягивающее усилие и крутящий момент',
    disembarkation:
      'При наличии высадки прочностной расчет ведется по телу трубы',
    limitEndurance:
      'Наибольшая величина циклического напряжения, при которой материал может работать неограниченно долго без разрушения',
    round:
      'Разрядность округления - количество значимых цифр после запятой, представленных в результатах расчета. Например: если разрядность округления 4, то для числа 10,3464678009879 результатом округления будет 10,3465. Для числа 0,0000645763 результатом округления будет 0,00006458',
    drillingConditions:
      'Условия проведения работ (бурение/КРС) влияют на значение коэффициента запаса прочности при задании его автоматически. Если осложнения встречаются редко, или их нет вовсе, то процесс строительства скважин характеризуется как нормальные условия проведения работ. Если осложнения возможны на каждой скважине, пробуренной на данной площади, то это осложненные условия проведения работ',
    shared:
      'Нажмите, если необходимо дать доступ к результатам расчёта другим пользователям по ссылке. Для того, чтобы закрыть доступ, нажмите на кнопку еще раз и поле «Поделиться» станет неактивным',
    gridDeclination:
      'Угол в данной точке между ее меридианом и линией, параллельной оси абсцисс или осевому меридиану',
    updateParams: 'Обновлять параметры',
    wellboreProfile: `<div class="the-wellbore-profile__hints">
      <div class="the-wellbore-profile__hint"><span class="the-wellbore-profile__hint-point the-wellbore-profile__hint-predict"></span> Прогноз</div>
      <div class="the-wellbore-profile__hint"><span class="the-wellbore-profile__hint-point the-wellbore-profile__hint-fact"></span> Факт</div> 
    </div>`,
  },

  menu: {
    fields: 'Месторождения',
    wells: 'Скважины',
    calcs: 'Моделирование и расчеты',
    results: 'Результаты расчетов',
  },

  alert: {
    errors: {
      fail: 'Ведутся технические работы, работа системы в настоящий момент нестабильна. Приносим извинения за временные неудобства',
      length:
        'Ошибка сохранения элемента: суммарная длина элементов не может быть больше {msg}',
      calcReportNotSupported:
        'Создание отчета для данного типа калькулятора еще не поддерживается',
      calcVolumeIntervalError:
        'Ошибка расчета - верхняя граница интервала не может быть больше нижней!',
      pipeIntervalError:
        'Верхний интервал спуска колонны не может быть больше нижнего!',
      lengthIntervalError:
        'Верхняя граница интервала не может быть больше нижней!',
      pipeCementingIntervalError:
        'Верхний интервал цементирования не может быть больше нижнего!',
      areasLength:
        'Ошибка при сохранении участка – суммарная длина участков превышает длину элемента! Максимальная длина участка: {msg} м',
      noPermission: 'У вас нет доступа для просмотра этой страницы: {msg}',
      fileUploadError:
        '<b>Код ошибки 415</b> – Неподдерживаемый тип данных в запросе',
    },
    texts: {
      resetPass:
        'Заявка на восстановление пароля создана! Проверьте вашу электронную почту',
      newPass: 'Пароль успешно изменен! Используйте его, чтобы войти в систему',
      saved: 'Данные успешно сохранены!',
      profileSaved: 'Изменения сохранены!',
      support:
        'Ваше обращение успешно зарегистрировано! Ожидайте ответа на вашу электронную почту',
      field: 'Месторождение "{msg}" успешно создано!',
      fieldSave: 'Месторождение "{msg}" успешно сохранено!',
      fieldDelete: 'Месторождение "{msg}" успешно удалено!',
      well: 'Скважина "{msg}" успешно создана!',
      wellSave: 'Скважина "{msg}" успешно сохранена!',
      wellDelete: 'Скважина "{msg}" успешно удалена!',
      wellbore: 'Ствол "{msg}" успешно создан!',
      wellboreSaved: 'Ствол "{msg}" успешно сохранен!',
      wellboreDelete: 'Ствол успешно удален!',
      wellboreClone: 'Ствол успешно скопирован!',
      wellboreTransferOn: 'Передача данных успешно активирована!',
      wellboreTransferOff: 'Передача данных отключена!',
      pointDelete: 'Точка профиля успешно удалена!',
      pointCreate: 'Точка профиля успешно создана!',
      pointCreateIntegration:
        'Запрос на добавление точки профиля успешно отправлен!',
      pointSave: 'Точка профиля успешно сохранена!',
      intervalCreate: 'Интервал успешно создан!',
      intervalSave: 'Интервал успешно сохранен!',
      intervalDelete: 'Интервал успешно удален!',
      elementCreate: 'Элемент успешно создан!',
      elementSave: 'Элемент успешно сохранен!',
      elementDelete: 'Элемент успешно удален!',
      areaCreate: 'Участок элемента успешно создан!',
      areaSave: 'Участок элемента успешно сохранен!',
      areaDelete: 'Участок элемента успешно удален!',
      equipCreate: 'Компоновка оборудования успешно создана!',
      equipSave: 'Компоновка оборудования успешно сохранена!',
      equipDelete: 'Компоновка оборудования успешно удалена!',
      equipClone: 'Компоновка оборудования успешно склонирована!',
      connectionCreate: 'Соединение элемента компоновки успешно создано!',
      connectionSave: 'Соединение элемента компоновки успешно сохранено!',
      connectionDelete: 'Соединение элемента компоновки успешно удалено!',
      copied: 'Ссылка на данный результат расчета скопирована в буфер обмена',
      sharedOn: 'Открыт общий доступ к результатам расчёта по ссылке',
      sharedOff: 'Общий доступ к результатам расчета по ссылке закрыт',
      normalize: 'Нормализация инклинометрии успешно завершена!',
      transfer: 'Миграция инклинометрии успешно завершена!',
    },
  },

  validations: {
    required: 'Поле обязательно',
    min: 'Не менее {min}',
    max: 'Не более {max}',
    email: 'Введите корректный email',
    zero: 'Не может быть 0',
    integer: 'Значение должно быть целым',
  },

  areas: {
    length: 'Длина участка',
    outer: 'Наружный диаметр',
    inner: 'Внутренний диаметр',
    save: 'Сохранить участок',
    add: 'Добавить участок',
    edit: 'Редактировать участок',
    lengthInfo: 'Участок длиной',
    number: 'Номер участка',
  },

  resultsScreen: {
    intervals: 'Интервалов',
    pcs: 'шт.',
    pipes: 'Колонны',
    elements: 'элем',
    equipment: 'Компоновка',
    calculation: 'Расчет',
    resultsCalculations: 'Результаты раcчетов',
    filter: 'Фильтр',
    toNewCalculation:
      'Список отчетов пуст. Чтобы добавить отчет, перейдите в раздел "Моделирование и расчеты"',
    goToCalculators: 'Моделирование и расчеты',
    measureTypeM: 'Система СИ',
    measureTypeUS: 'Имперская',
  },

  fieldScreen: {
    fieldsList: 'Список месторождений',
    fieldsListIsEmpty:
      'Список местрождений пуст или нет результатов по выбранным фильтрам',
    fieldSearch: 'Поиск по месторождениям',
    addField: 'Добавить месторождение',
    editField: 'Изменить месторождение',
    newFieldLabel: 'Наименование месторождения',
    save: 'Сохранить месторождение',
    create: 'Создать месторождение',
  },

  wells: {
    list: 'Список скважин',
    search: 'Поиск по скважинам',
    cardTitle: 'Создание скважины',
    cardTitleEdit: 'Изменение скважины',
    create: 'Создать скважину',
    save: 'Сохранить скважину',
    name: 'Наименование скважины',
    type: 'Тип скважины',
    cluster: 'Куст скважины',
    empty:
      'У месторождения "{msg}" нет добавленных скважин или нет результатов по выбранным фильтрам',
    filter: 'Настройки фильтра',
    selectField: 'Выберите месторождение',
    selectType: 'Выберите тип скважины',
    selectCluster: 'Выберите куст',
    clear: 'Сбросить фильтр',
    emptyWells:
      'Вы еще не добавили ни одной скважины или нет результатов по выбранным фильтрам. Чтобы создать новую, перейдите на страницу "Месторождения" и выберите месторождение, где вы хотите создать скважину',
  },

  wellbores: {
    search: 'Поиск по стволам',
    cardTitle: 'Создание ствола',
    create: 'Создать ствол скважины',
    name: 'Наименование ствола',
    depthCurrent: 'Глубина текущего забоя',
    depthProject: 'Глубина проектного забоя',
    alt: 'Альтитуда',
    height: 'Высота стола ротора',
    declination: 'Магнитное склонение',
    empty:
      'У скважины "{msg}" еще не созданы стволы или нет результатов по выбранным фильтрам',
  },

  wellbore: {
    editTitle: 'Редактирование ствола',
    save: 'Сохранить ствол скважины',
    info: 'Основная информация',
    params: 'Параметры ствола',
    points: 'Профиль ствола скважины',
    intervals: 'Конструкция скважины',
    equips: 'Компоновки оборудования',
    vizualization: 'Показать конструкцию',
    check: 'Валидация ствола и компоновки',
    checkTitle: 'Валидация ствола и компоновки',
    checkBtn: 'Проверить',
    calculate: 'Осуществить расчеты',
    back: 'Отменить редактирование ствола',
    goToCalc: 'Перейти к расчетам',
    calcs: 'Расчетные модули',
    equip: 'Компоновка оборудования',
    dialogCheckTitle:
      'Результат проверки заполненных данных по стволу и компоновке',
    dialogCheckSuccess:
      'Данные по конструкции скважины и компоновке оборудования заполнены корректно',
    activation: {
      activate: 'Активировать интеграцию',
      deactivate: 'Завершить интеграцию',
      deactivateTitle: 'Завершение интеграции',
      formTitle: 'Состояние фактического ствола',
      success: 'Интеграция успешно активирована',
      info: 'Информация для подключения',
      copy: 'Скопировать информацию',
      bindData: 'Передача данных',
      unbindData: 'Отключить передачу данных',
      unbindTitle: 'Отключение передачи данных',
      bindWarning:
        'Выполнение данного действия приведет к прекращению обмена данными с интегратором',
      activationWarning:
        'Выполнение данного действия приведет к удалению фактического и динамичного стволов скважины',
      staticWellbore: 'Статичный',
      dynamicWellbore: 'Динамичный',
      factWellbore: 'Фактический',
      planActual: 'План-факт валидация',
    },
    warningText: 'Предупреждение',
    errorText: 'Ошибка',
    criticalText: 'Критическая ошибка',
  },

  equips: {
    title: 'Список компоновок',
    equip: 'Компоновка оборудования',
    save: 'Сохранить компоновку',
    add: 'Добавить компоновку',
    name: 'Наименование компоновки',
    enterName: 'Укажите наименование',
    purpose: 'Назначение компоновки',
    enterPurpose: 'Укажите назначение',
    empty: 'У ствола скважины еще нет компоновок оборудования',
    interval: 'Интервал спуска',
    up: 'Верх',
    down: 'Низ',
    depth: 'Интервал спуска',
    intervals: 'Интервал применения',
    forDrilling: 'Бурение',
    notForDrilling: 'Спец. работы',
    length: 'Длина компоновки',
    autolength: 'Автообновление длины',
    equipComposition: 'Состав компоновки',
  },

  wellboreMain: {
    title: 'Основная информация',
    save: 'Сохранить наименование',
    name: 'Наименование ствола',
    enterName: 'Укажите наименование ствола',
  },

  wellboreParams: {
    title: 'Параметры ствола',
    saveParams: 'Сохранить параметры',
    currentDepth: 'Глубина текущего забоя',
    projectDepth: 'Глубина проектного забоя',
    altitude: 'Альтитуда',
    rotorHeight: 'Высота стола ротора',
    magneticDeclination: 'Магнитное склонение',
    gridDeclination: 'Сближение меридиан',
    resistanceCoefs: 'Коэффициенты сопротивления',
    resistanceCoefUp: 'Коэффициент сопротивления (↑)',
    resistanceCoefDown: 'Коэффициент сопротивления (↓)',
    blockValid: 'Блокирующие валидации',
    ignoreInc: 'Игнорировать блокирующие валидации инклинометрии',
    ignoreRes: 'Игнорировать блокирующие валидации для расчетов',
    notIgnore: 'Не игнорировать',
    ignoreSingle: 'Игнорировать 1 раз',
    ignoreAlways: 'Игнорировать всегда',
  },

  wellboreProfile: {
    title: 'Профиль ствола',
    point: 'Точка профиля',
    depth: 'Глубина точки замера',
    depthMini: 'Глубина',
    edit: 'Редактировать точку',
    add: 'Добавить точку замера',
    radioSelect: 'Тип азимута',
    number: 'Номер точки замера',
    depthPoint: 'Глубина по стволу в точке замера инклинометрии',
    zenith: 'Зенитный угол',
    azimuth: 'Азимут',
    azimuthM: 'Азимут магнитный',
    azimuthG: 'Азимут географический',
    azimuthGr: 'Азимут GRID',
    save: 'Сохранить точку замера',
    optionM: 'Азимут магнитный',
    optionG: 'Азимут географический',
    optionGr: 'Азимут GRID',
    azimuthTypeM: 'магнитный',
    azimuthTypeG: 'географический',
    azimuthTypeGr: 'GRID',
    fileImport: 'Автозаполнение (по шаблону)',
    downloadFile: 'Скачать шаблон',
    importXls: 'Импорт XLS',
    fileName: 'Шаблон.xls',
    empty: 'У ствола еще не созданы точки профиля',
    selectAction: 'Выбрать действие',
    validationWellbore: 'Валидация профиля',
    viewGraph: 'Просмотр профиля',
    transferToFact: 'Миграция в фактический ствол',
    transferToDyn: 'Миграция в динамичный ствол',
    normalize: 'Нормализация инклинометрии',
    clearPoints: 'Удаление всех точек замера',
    modal: 'Импорт XLS',
    modalText: 'Выберите метод загрузки новых точек профиля',
    modalAdd: 'Добавить',
    modalReplace: 'Заменить',
    clear: {
      modal: 'Предупреждение',
      text: 'Вы уверены?',
      confirm: 'ОК',
      cancel: 'Отмена',
    },
    migrate: {
      btn: 'Миграция инклинометрии',
      alert:
        'Миграция инклинометрии доступна только для динамичного и фактического стволов с активированной интеграцией',
      modal: 'Миграция инклинометрии',
      text_fd:
        'Вы уверены, что хотите мигрировать все точки профиля из фактического ствола в динамичный ствол? Все точки профиля в динамичном стволе будут полностью заменены!',
      text_df:
        'Вы уверены, что хотите мигрировать все точки профиля из динамичного ствола в фактический ствол? Все точки профиля в фактическом стволе будут полностью заменены!',
      confirm: 'ОК',
      cancel: 'Отмена',
    },
    graph: {
      title: 'График профиля ствола',
      fact: 'Факт',
      future: 'Прогноз',
      depth: 'Глубина',
    },
    status: 'Статус точки замера',
    statusPlan: 'План',
    statusPredict: 'Прогноз',
    statusFact: 'Факт',
    actual: 'Актуальный',
    notactual: 'Неактуальный',
    unreliable: 'Недостоверный',
    truthful: 'Достоверный',
    mismatch: 'Несоответствие траекторий',
    date: 'Дата и время замера',
    dialogCheckProfileTitle:
      'Результат проверки данных по профилю ствола скважины',
    dialogCheckProfileSuccess:
      'Данные по профилю ствола скважины заполнены корректно',
    continueCheckProfile: 'Приступить к валидации',
    withProcessing: 'С обработкой',
    withoutProcessing: 'Без обработки',
    processingHint:
      'Под обработкой понимается проверка качества поступающих данных системой (см. руководство по эксплуатации)',
  },

  wellboreVizualization: {
    title: 'Конструкция скважины',
    close: 'Закрыть',
    params: 'Параметры конструкции',
    confirm: 'Применить',
    equip: 'Компоновка оборудования',
    show: 'Отображать',
    infoIntervals: 'Сведения об интервалах',
    infoElements: 'Сведения об элементах',
    infoEquips: 'Сведения об элементах компоновки',
    disableClicks: 'Отключить кликабельность',
    mm: 'мм',
    m: 'м',
    depth: 'Глубина',
    radius: 'Радиус',
    gradients: 'Градиент',
    noGradient: 'Градиент не выбран',
  },

  calculators: {
    pageTitle: 'Моделирование и расчеты',
    calcs: {
      volume: 'Объем пространств скважины',
      strength: 'Прочность и ресурс компоновок',
      welljam: 'Процесс глушения скважины',
      repair: 'Ремонтно-изоляционные работы',
      circulation: 'Циркуляционная система',
      depth: 'Определение глубины прихвата',
      equipload: 'Сложное нагружение компоновок',
      rheology: 'Реология жидкостей',
      clearing: 'Режимы очистки скважины',
      block: 'Блокирование поглощающего пласта',
    },
    emptyText:
      'Для выполнения расчета необходимо создать месторождение и скважину',
    emptyCreate: 'Приступить к созданию',
    selectWell: 'Выбор скважины',
    goToCalc: 'Перейти к расчету',
    goToConstructor: 'Открыть конструктор',
    inProcess: 'В разработке',
    textRepeat:
      'Вы уже осуществляли подобный расчет {date}. Скопировать ранее введенные исходные данные?',
    newCalculation: 'Создать новый расчет',
    copyInputData: 'Скопировать исходные данные',
    showResult: 'Посмотреть расчет',
  },

  onboarding: {
    welcome: 'Добро пожаловать в систему моделирования внутрискважинных работ',
    fields: 'Месторождения',
    fieldsText:
      'Создавайте и просматривайте месторождения для удобной фильтрации скважин',
    wells: 'Скважины',
    wellsText:
      'Укажите информацию о конструкции скважины и внутрискважинном оборудовании, чтобы приступить к моделированию',
    calcs: 'Моделирование и расчеты',
    calcsText:
      'Создавайте реалистичные модели скважин любой сложности и проводите их симуляции',
    results: 'Результаты и отчеты',
    resultsText: 'Просматривайте результаты симуляций и делитесь отчетами',
    profile: 'Ваш профиль',
    profileText:
      'Для перехода к настройкам профиля и выхода из приложения нажмите на логотип WellPro',
    next: 'Далее',
    skip: 'Начать работу',
  },

  login: {
    error: 'Ошибка',
    toEnterToSystem: 'Для входа в систему используйте вашу учетную запись',
    enterLogin: 'Введите логин',
    login: 'Логин',
    enterPassword: 'Введите пароль',
    password: 'Пароль',
    forgetPassword: 'Забыли пароль?',
    enter: 'Войти',
    register: 'Регистрация',
  },

  passReset: {
    title: 'Создание заявки на восстановление пароля',
    problemType: 'Восстановление пароля',
    cardTitle: 'Укажите данные',
    email: 'Контактный Email',
    emailLabel: 'Укажите ваш email',
    type: 'Тип проблемы',
    typeLabel: 'Выберите тип',
    username: 'Учетная запись',
    usernameLabel: 'Укажите ваш логин',
  },

  passChange: {
    title: 'Изменение пароля',
    newPass: 'Новый пароль',
    newPassLabel: 'Введите новый пароль',
    newPassConfirm: 'Повтор пароля',
    newPassConfirmLabel: 'Повторите новый пароль',
  },

  registration: {
    title: 'Регистрация',
    userRegistered: 'Пользователь зарегистрирован',
    error: 'Ошибка',
    needToRegistration:
      'Для регистрации укажите вашу электронную почту и желаемые логин и пароль',
    email: 'E-mail',
    enterEmail: 'Введите email',
    login: 'Логин',
    enterLogin: 'Введите логин',
    password: 'Пароль',
    enterPassword: 'Введите пароль',
    passwordConfirm: 'Повтор пароля',
    retryPassword: 'Повторите пароль',
    register: 'Зарегистрироваться',
    back: 'Вернуться назад',
    disabledTitle: 'Регистрация недоступна!',
    disabledText:
      'Возможность регистрировать пользователей доступна только  администратору через панель администрирования. Чтобы зарегистрироваться, обратитесь к администратору Well Pro.',
  },

  profile: {
    title: 'Укажите параметры',
    units: 'Единицы измерения',
    locale: 'Язык приложения',
    color: 'Цветовая гамма приложения',
    save: 'Сохранить изменения',
    support: 'Написать в поддержку',
    unitsM: 'Система СИ',
    unitsUS: 'Имперская система',
    digits: 'Разрядность округления',
    warning:
      'Внимание! При сохранении настроек языка страница автоматически перезагрузится. Сохраните ваши данные прежде, чем изменять язык приложения!',
  },

  support: {
    title: 'Опишите проблему',
    type: 'Тип проблемы',
    selectType: 'Выберите тип',
    comment: 'Комментарий',
    enterComment: 'Опишите суть проблемы',
    email: 'Контактный Email',
    enterEmail: 'Укажите ваш email',
  },

  calculatorsLayout: {
    info: 'Информация о скважине',
    initData: 'Исходные данные',
    calculate: 'Осуществить расчет',
    steps: 'Этапы расчета',
    intervalsCount: 'Количество интервалов',
    pipesCount: 'Количество колонн',
    elementsCount: 'Количество элементов колонн',
    equipElementsCount: 'Количество элементов инструмента',
    selectWell: 'Выбрать другую скважину',
    construction: 'Конструкция скважины',
    vizualization: 'Отобразить на конструкции',
    editInitData: 'Изменить исходные данные',
    updown: 'Границы',
    diameter: 'Диаметр долота',
    cavernosity: 'Коэффициент кавернозности',
    cased: 'Обсажен',
    notCased: 'Не обсажен',
    type: 'Тип колонны',
    interval: 'Интервал спуска',
    fullLength: 'От устья до конца интервала',
    intervalCementing: 'Интервал цементирования',
    wallFriction: 'Коэффициент трения о стенки',
    fullLengthPipe: 'На всю длину колонны',
    composition: 'Состав колонны',
    fileName: 'Отчет.docx',
    equipInfo: 'Информация о компоновке',
    visualTitle: 'Настройки отображения',
    wallFrictionCasedDown:
      'Коэффициент трения о стенки обсаженной части интервала (↓)',
    wallFrictionCasedUp:
      'Коэффициент трения о стенки обсаженной части интервала (↑)',
    wallFrictionUp: 'Коэффициент трения о стенки (↑)',
    wallFrictionDown: 'Коэффициент трения о стенки (↓)',
  },

  calcs: {
    initData: 'Исходные данные',
    save: 'Сохранить изменения',
    volume: {
      interval: 'Интервал расчета',
      updown: 'Границы интервала',
      up: 'Верх',
      down: 'Низ',
      stages: {
        mining: 'Объем горной выработки',
        pipes: 'Видимый объем всех обсадных колонн',
        equips: 'Видимый объем всех элементов компоновки оборудования',
        pipesMaterial: 'Объем материала всех обсадных колонн',
        equipsMaterial: 'Объем материала всех компоновок инструмента',
        pipesInner: 'Внутренний объем всех обсадных колонн',
        equipsInner: 'Внутренний объем всех элементов компоновки оборудования',
        well: 'Объем скважины',
        annulus: 'Объем затрубного пространства',
        effective: 'Эффективный объем скважины',
        tool: 'Объем скважины под инструментом',
        annular: 'Объем межколонного пространства',
      },
    },
    strength: {
      conditions: 'Условия для расчета',
      resistanceCoefficientUp: 'Коэффициент сопротивления (↑)',
      resistanceCoefficientDown: 'Коэффициент сопротивления (↓)',
      kzp: 'КЗП',
      wedgeGrip: 'Клиновой захват',
      verifConditions: 'Условия для верификаций',
      predictConditions: 'Условия для прогноза',
      step: 'Шаг расчета',
      addLoads: 'Дополнительная нагрузка',
      addMoments: 'Дополнительные моменты',
      operation: 'Технологическая операция',
      fortress: 'Крепость разрушаемого материала',
      drillingConditions: 'Условия проведения работ (бурение/КРС)',
      circulation: 'Наличие циркуляции',
      pressure: 'Затрубное давление на устье',
      density: 'Плотность твердых частиц',
      concentration: 'Концентрация твердых частиц',
      channelArea: 'Площадь канала штуцера',
      pri: 'Осевая нагрузка на инструмент',
      mudDensity: 'Плотность промывочной жидкости',
      mudConsumption: 'Расход промывочной жидкости',
      rpmEquip: 'Частота вращения компоновки',
      rpmZD: 'Частота вращения ЗД',
      speed: 'Скорость движения инструмента',
      pressureZTS: 'Перепад давления на ЗТС',
      pressureVZD: 'Перепад давления на ЗД',
      pressureDoloto: 'Перепад давления на конце инструмента',
      kzps: 'Коэффициенты запаса прочности',
      kzpFull: 'Коэффициент запаса прочности',
      nippeles: 'Запас прочности ниппелей',
      mufts: 'Запас прочности муфт',
      tightness: 'Запас герметичности соединений',
      customValue: 'Пользовательское значение',
      base: 'Основание',
      method: 'Способ вращения инструмента',
      profile: 'Профиль',
      wedgeGripType: 'Тип клинового захвата',
      wedgeGripTypeSelect: 'Выберите клиновой захват',
      params: 'Параметры прочности',
      angle: 'Угол охвата трубы плашками',
      count: 'Количество клиньев',
      ratio: 'Коэффициент охвата',
      workLength: 'Рабочая длина клина',
      value: 'Величина ctg(α_кл + φ)',
      wedgeAngle: 'Угол наклона клина',
      frictionAngle: 'Угол трения',
      stepFull: 'Шаг для расчетных точек',
      depth: 'Глубина',
      effort: 'Усилие',
      valAdd: 'Значение',
      newLoad: 'Добавить усилие на точке',
      addMomentsFull: 'Дополнительные крутящие моменты',
      torque: 'Кр. момент',
      newMoment: 'Добавить момент на точке',
      counts: 'шт.',
      azimuthType: 'Тип азимута в результатах расчета',
      showAddResutls: 'Показывать основные расчеты при дополнительных расчетах',
      interval: 'Интервал работы компоновки',
      hookTensileForce: 'Текущий вес на крюке',
      rotorTorque: 'Текущий момент на роторе',
      updateParams: 'Обновлять модель трения',
      startDepth: 'Начальная точка расчета',
      drillStep: 'Шаг расчета прогноза',
      selects: {
        up: 'Подъем',
        down: 'Спуск',
        rotating: 'Вращение над забоем после подъема',
        rotatingDown: 'Вращение над забоем после спуска',
        drilling: 'Бурение',
        backreaming: 'Обратная проработка',
        soft: 'Мягкие',
        medium: 'Средние',
        solid: 'Твердые',
        land: 'На суше и на море со стационарных оснований',
        float: 'С плавучих средств',
        vzd: 'С ЗД',
        vsp: 'С ВСП (в т.ч. ротор)',
        vertical: 'Вертикальный',
        directional: 'Наклонно-направленный',
        normal: 'Нормальные',
        complicated: 'Осложненные',
      },
      stages: {
        force: 'Растягивающая сила, баклинг-эффект и боковая сила',
        load: 'Предельная осевая растягивающая нагрузка в клиновом захвате',
        torque: 'Крутящий момент',
        bending: 'Изгибающий момент',
        stress: 'Расчет напряжений',
        fatigue: 'Усталостный износ',
        extension: 'Удлинение и закручивание бурильной колонны',
        coefs: 'Коэффициенты жесткости компоновки',
      },
      info: {
        depth: 'Глубина',
        effort: 'Усилие',
        torque: 'Крутящий момент',
      },
      menuPanels: {
        main: 'Основные условия',
        secondary: 'Дополнительные условия',
        friction: 'Верификация сил и трения',
        forecast: 'Прогноз буримости и дохождения',
        load: 'Осевая нагрузка в клиновом захвате',
      },
    },
  },

  infoElements: {
    repeat: 'Повторяющийся',
    solo: 'Единичный',
    diff: 'Сложный',
    simple: 'Простой',
    length: 'Длина',
    repeatLength: 'Протяженность повторения',
    intervalLength: 'Интервал повторения',
    repeatCount: 'Количество повторений',
    composition: 'Состав элемента',
    outer: 'Наружный диаметр',
    inner: 'Внутренний диаметр',
    thickness: 'Толщина стенки',
  },

  results: {
    title: 'Результаты расчетов',
    empty:
      'Подходящих результатов не нашлось, пожалуйста, измените настройки поиска',
    clear: 'Сбросить фильтры',
    filters: 'Фильтры',
    confirm: 'Применить',
    name: 'По наименованию расчетов',
    field: 'По месторождениям',
    well: 'По скважине',
    wellbore: 'По стволу скважины',
    cluster: 'По кусту',
    equip: 'По компоновке оборудования',
    lang: 'По языку расчета',
    langRu: 'Русский',
    langEn: 'Английский',
    unitSystem: 'По системе единиц измерения',
  },

  result: {
    results: 'Результаты расчетов',
    empty: 'Вы не выбрали ни одного результата расчета для отображения!',
    title: 'Результаты расчетов',
    show: 'Отобразить выбранные',
    interval: 'Выбранный интервал расчета',
    points: 'Выбранные расчетные точки',
    conditions: 'Условия проведения операции',
    emptyResults: 'По данному расчету результатов нет',
    actions: 'Действия',
    getReport: 'Сформировать отчет',
    showChart: 'Отобразить на графике',
    showVisualization: 'Отобразить на конструкции',
    share: 'Поделиться',
    copyInputDate: 'Повторить расчет',
    depth: 'Глубина точки замера',
    down: 'Спуск инструмента',
    up: 'Подъем инструмента',
    overfillDown: 'Вращение над забоем после спуска',
    overfillUp: 'Вращение над забоем после подъема',
    drilling: 'Бурение',
    backreaming: 'Обратная проработка',
    soft: 'Мягкие',
    medium: 'Средние',
    solid: 'Твердые',
    normal: 'Нормальные',
    complicated: 'Осложненные',
    plus: 'Есть',
    minus: 'Нет',
    result: 'Результат',
    count: 'шт.',
    setShared: 'Сделать общедоступным',
    chart: 'Графики',
    emptyChart: 'Для данного расчета нет графиков',
    updateParam: 'Да',
    notUpdateParam: 'Нет',
    showResult: 'Открыть результат расчета',
  },

  elements: {
    edit: 'Редактировать элемент',
    add: 'Добавить элемент',
    addNew: 'Добавить новый элемент',
    numberPipe: 'Номер элемента колонны',
    number: 'Номер элемента оборудования',
    type: 'Тип элемента',
    params: 'Физические свойства',
    meterWeight: 'Вес погонного метра',
    limit: 'Предел выносливости',
    steelgrade: 'Группа прочности / Марка стали',
    steelParams: 'Параметры прочности',
    density: 'Плотность',
    elastic: 'Модуль упругости',
    fluidity: 'Предел текучести',
    limitStrength: 'Предел прочности',
    poisson: 'Коэффициент Пуассона',
    colorPipe: 'Цвет элемента колонны',
    color: 'Цвет элемента оборудования',
    solo: 'Единичный',
    repeat: 'Повторяется',
    lengthPipe: 'Длина элемента колонны',
    length: 'Длина элемента оборудования',
    repeatLength: 'Протяженность повторения',
    fullLength: 'Доводящий до устья',
    fullLengthInterval: 'На всю длину колонны',
    simple: 'Простой',
    complexity: 'Сложный',
    outerDiameter: 'Наружный диаметр элемента',
    methodType: 'Метод заполнения с учетом',
    innerDiameter: 'Внутренний диаметр элемента',
    thickness: 'Толщина стенки',
    save: 'Сохранить элемент',
    selectInner: 'Внутреннего диаметра',
    selectThickness: 'Толщины стенки',
    elementInfo: {
      repeatFullSimple:
        'Повторяется по {length} {lengthUc} на всю длину, простой',
      repeatFullCompl:
        'Повторяется по {length} {lengthUc} на всю длину, сложный',
      soloFullSimple: 'Единичный, простой, на всю длину',
      soloFullCompl: 'Единичный, сложный, на всю длину',
      repeatLengthSimple:
        'Повторяется по {length} {lengthUc} на протяжении {repeat} {repeatUc}, простой',
      repeatLengthCompl:
        'Повторяется по {length} {lengthUc} на протяжении {repeat} {repeatUc}, сложный',
      soloLengthSimple: 'Единичный, простой, {length} {lengthUc}',
      soloLengthCompl: 'Единичный, сложный, {length} {lengthUc}',
    },
    rockDestruction: 'Разрушающий элемент',
  },

  intervals: {
    list: 'Список интервалов',
    k: 'K(каверноз.)',
    d: 'D(долота)',
    composition: 'Конструкция скважины',
    save: 'Сохранить интервал',
    add: 'Добавить интервал',
    number: 'Номер интервала бурения',
    type: 'Тип интервала',
    params: 'Параметры интервала',
    coefWall: 'Коэффициент трения о стенки',
    coefCavernosity: 'Коэффициент кавернозности',
    boundaries: 'Границы интервала',
    diameter: 'Диаметр долота',
    cased: 'Обсажен',
    notCased: 'Не обсажен',
    pipeType: 'Тип колонны',
    pipeInterval: 'Интервал спуска колонны',
    fullLength: 'От устья до конца интервала',
    cementing: 'Цементируется',
    intervalCementingFull: 'Интервал цементирования',
    up: 'Верх',
    down: 'Низ',
    fullLengthPipe: 'На всю длину колонны',
    pipeComposition: 'Состав колонны',
    empty: 'У ствола скважины еще нет интервалов бурения',
    pipe: 'Обсадная колонна',
    intervalCementing: 'Интервал цемент.',
    notCementing: 'Не цементируется',
    count: 'Кол-во элементов',
    from: 'От',
    to: 'до',
    paramsCased: 'Параметры обсадной колонны',
    borderUpdate: 'Автообновление границ',
    addBtnList: 'Добавить интервал',
  },

  connections: {
    edit: 'Изменить соединение',
    add: 'Указать соединение',
    method: 'Метод указания соединения',
    type: 'Тип соединения',
    lockType: 'Тип замка',
    lockParams: 'Параметры замка',
    carvingType: 'Тип резьбы',
    outer: 'Наружный диаметр',
    inner: 'Внутренний диаметр',
    limitStrength: 'Предельная нагрузка на оси',
    carvingCoef: 'Коэффициент трения в резьбе',
    carvingParams: 'Параметры резьбы',
    profile: 'Форма профиля',
    count: 'Кол-во витков нити на дюйм',
    step: 'Шаг резьбы',
    nameApi: 'Тип резьбы по межд. стандарту',
    taper: 'Конусность резьбы',
    diameter: 'Диаметр шага калибр. точки',
    countersink: 'Диаметр зенковки',
    carvingHeight: 'Высота резьбы не усеченная',
    carvingDepth: 'Глубина нити резьбы',
    disembarkation: 'Отсутствие высадки',
    maxRotate: 'Макс. крутящий момент',
    maxStrength: 'Макс. растягивающее усилие',
    customType: 'Тип пользовательского соединения',
    connectionParams: 'Параметры соединения',
    connUp: 'Соединение к устью',
    isConnect: 'Наличие соединения',
    nippeleParams: 'Параметры ниппеля',
    hardness: 'Относительная жесткость',
    nippeleEffort: 'Осевое усилие в ниппеле',
    nippeleCarving: 'Параметр резьбы ниппеля',
    muftParams: 'Параметры муфты',
    muftEffort: 'Осевое усилие в муфте',
    muftButt: 'Параметр торца муфты',
    nippeleLength: 'Длина ниппеля',
    connDown: 'Соединение к забою',
    save: 'Сохранить соединение',
    delete: 'Удалить соединение',
    cancel: 'Отменить',
    selects: {
      together: 'На весь инструмент',
      apart: 'Только на концах элемента',
      nippele: 'Ниппель',
      muft: 'Муфта',
      lock: 'Замок',
      carving: 'Резьба',
      custom: 'Другое',
    },
    symbols: {
      lock: 'З',
      carving: 'Р',
      custom: 'ДР.',
      nippele: '(Н)',
      muft: '(М)',
    },
  },

  mobile: {
    title: 'Внимание!',
    text: 'Мобильная версия WellPro находится в статусе закрытого бета-тестирования. Для авторизации и работы с WellPro, необходимо использовать Desktop браузер',
    continue: 'Остаться на сайте',
    toApp: 'Открыть в приложении',
    toShop: 'Скачать приложение',
  },
}

export default ru
