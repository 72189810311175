
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'

@Component
export default class WPExpCheckbox extends Vue {
  @Prop({ default: '' })
  private readonly title?: string

  @Prop({ default: false })
  private readonly opened?: boolean

  @Prop({ default: false })
  private readonly large?: boolean

  @Prop({ default: false })
  private readonly nopad?: boolean

  @Prop({ default: false })
  private readonly value?: boolean

  @Prop({ default: false })
  private readonly disabled?: boolean

  @Prop({ default: false })
  private readonly showArrow?: boolean

  private isOpened: number | undefined | null = null

  private checkbox = false

  private get isDisabled() {
    return this.disabled || !this.checkbox
  }

  private onClick() {
    this.$emit('click')
  }

  @Watch('opened', { immediate: true })
  private onChangeState(val: boolean) {
    this.$nextTick(() => {
      this.isOpened = val ? 0 : undefined
    })
  }

  @Watch('checkbox', { immediate: true })
  private onChangeCheckbox(val: boolean) {
    if (!this.disabled) {
      this.$emit('onChange', val)
      this.$nextTick(() => {
        this.isOpened = val ? 0 : undefined
      })
    }
  }

  private mounted() {
    if (this.opened) {
      this.isOpened = 0
      this.checkbox = true
      return
    }
    this.checkbox = this.value || false
  }
}
