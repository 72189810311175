import { Component, Vue } from 'vue-property-decorator'

import { Field } from '@/store/field'

@Component
class FieldsSearchMixin extends Vue {
  public selectLoadingFields = false

  public searchTextFields: string | null = null

  public get queryField() {
    return this.searchTextFields ? { name: this.searchTextFields } : undefined
  }

  public async onSearchFields(search = '') {
    this.searchTextFields = search
    this.selectLoadingFields = true
    void Field.CLEAR_DATA('fields')
    await this.onLoadFields()
    this.selectLoadingFields = false
  }

  public async onLoadFields() {
    await Field.GET_FIELDS(this.queryField)
  }
}

export default FieldsSearchMixin
