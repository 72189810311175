import { Field } from '@/store/field'
import { Calculator } from '@/store/calculators'
import { Wellbore } from '@/store/wellbore'
import { Unit } from '@/store/units'
import { TCheckStructResult } from '@/types'

export const clearAllStates = (): void => {
  Field.CLEAR_FIELD_STATES()
  Calculator.CLEAR_CALC_STATES()
  Wellbore.CLEAR_WELLBORE_STATES()
  Unit.CLEAR_UNIT_STATES()
}

export const copyToClipboard = (textToCopy: string) => {
  // navigator clipboard api needs a secure context (https)
  if (navigator.clipboard && window.isSecureContext) {
    // navigator clipboard api method'
    return navigator.clipboard.writeText(textToCopy)
  } else {
    // text area method
    const textArea = document.createElement('textarea')
    textArea.value = textToCopy
    // make the textarea out of viewport
    textArea.style.position = 'fixed'
    textArea.style.left = '-999999px'
    textArea.style.top = '-999999px'
    document.body.appendChild(textArea)
    textArea.focus()
    textArea.select()
    return new Promise<void>((res, rej) => {
      document.execCommand('copy') ? res() : rej()
      textArea.remove()
    })
  }
}

export const isArray = (someVar: any) => {
  return Object.prototype.toString.call(someVar) === '[object Array]'
}

export const getPage = (counts: number | null, length: number) => {
  if (counts !== null) {
    const PAGE_SIZE = 30
    const pagesLoaded = Math.ceil(length / PAGE_SIZE)
    if (pagesLoaded * PAGE_SIZE >= counts) return null
    const nextPage = pagesLoaded + 1
    return nextPage
  } else return 1
}

export const timeout = async (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

export const checkStructText = (
  result: TCheckStructResult,
  warnText: string,
  errorText: string,
  criticalText: string
) => {
  const text = `
    <div class="check-struct">
      <div class="check-struct__info">
        <div class="check-struct__info-block check-struct__info-block_warning">${warnText}</div>
        <div class="check-struct__info-block check-struct__info-block_error">${errorText}</div>
        <div class="check-struct__info-block check-struct__info-block_critical">${criticalText}</div>
      </div>
      ${
        result
          ?.map(
            (item) =>
              `<div class="check-struct__item"><b class="check-struct__item_${
                item.status === 1
                  ? 'warning'
                  : item.status === 2
                  ? 'error'
                  : 'critical'
              }">${item.code}: </b>${item.message}</div>`
          )
          .join('') || ''
      }
    </div>
  `
  return text
}

export const isObjectEmpty = (object: any) => {
  return !!object ? Object.keys(object).length === 0 : true
}

export const isNotNull = (val: any) => {
  return val !== undefined && val !== null
}
