
import moment from 'moment'

import { Component, Prop, Vue } from 'vue-property-decorator'
import WPBaseTextfield from './WPBaseTextfield.vue'
import WPButton from '../WPButton.vue'

@Component({
  components: {
    WPBaseTextfield,
    WPButton,
  },
})
export default class WPBaseDatepicker extends Vue {
  @Prop({ default: null })
  public readonly value?: string | string[] | null

  @Prop({ default: true })
  public readonly range?: boolean

  private menu = false

  private get computedValue() {
    return this.value
  }

  private set computedValue(value) {
    this.emitValue(value)
  }

  private get computedValueFormatted() {
    if (this.value?.length) {
      if (this.value?.length === 1)
        return moment(this.value[0]).format('DD.MM.YYYY')
      if (this.value?.length === 2)
        return (
          moment(this.value[0]).format('DD.MM.YYYY') +
          ' – ' +
          moment(this.value[1]).format('DD.MM.YYYY')
        )
    }
  }

  private set computedValueFormatted(value) {
    this.computedValue = value
  }

  private emitValue(value: any) {
    this.$emit('input', value)
  }
}
