
import { Component, Vue, Prop } from 'vue-property-decorator'

import { IDataHeaderText } from '@/types'

@Component
export default class WPTextInfoHeader extends Vue {
  @Prop({ default: null })
  public readonly title?: IDataHeaderText | null

  @Prop({ default: null })
  public readonly subtitle?: IDataHeaderText | null

  private get lockIcon() {
    return this.title?.type?.includes('connection')
  }

  private get angleIcon() {
    return this.subtitle?.type?.includes('corner')
  }

  private get isTransition() {
    return this.title?.type?.includes('transition')
  }
}
