
import { Component, Prop, Vue } from 'vue-property-decorator'
import { eventBus } from '@/helpers/eventBus'

import WPDivider from './WPDivider.vue'

@Component({
  components: {
    WPDivider,
  },
})
export default class WPMenu extends Vue {
  @Prop({ default: () => [] })
  private items?: Record<string, string>[]

  @Prop({ default: '' })
  private login?: string

  private isActive(name: string) {
    return this.$route.matched[0]?.name?.includes(name)
  }

  private openSettings() {
    void eventBus.$emit('openSettings')
  }

  private openSupport() {
    void eventBus.$emit('openSupport')
  }

  private logout() {
    this.$emit('on-logout')
  }
}
