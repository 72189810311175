/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
const browser = {
  getUserAgent: function () {
    return window.navigator.userAgent
  },

  userAgentContains: function (browserName: string) {
    browserName = browserName.toLowerCase()
    return this.getUserAgent().toLowerCase().indexOf(browserName) > -1
  },

  isOSX: function () {
    return (
      this.userAgentContains('Macintosh') ||
      this.userAgentContains('iPhone') ||
      this.userAgentContains('iPad') ||
      this.userAgentContains('iPod')
    )
  },

  isFirefox: function () {
    return this.userAgentContains('firefox') || this.userAgentContains('FxiOS')
  },

  isInternetExplorer: function () {
    return this.userAgentContains('trident')
  },
  /**
   * Detects IE 11 and older
   * @return {Boolean} Returns true when IE 11 and older
   */
  isIE: function () {
    const ua = this.getUserAgent().toLowerCase()

    // Test values.
    // Uncomment to check result

    // IE 10
    // ua = 'Mozilla/5.0 (compatible; MSIE 10.0; Windows NT 6.2; Trident/6.0)';

    // IE 11
    // ua = 'Mozilla/5.0 (Windows NT 6.3; Trident/7.0; rv:11.0) like Gecko/20100101 Firefox/12.0';

    const msie = ua.indexOf('msie')
    if (msie > 0) {
      // IE 10 or older
      return true
    }

    const trident = ua.indexOf('trident/')
    if (trident > 0) {
      // IE 11
      return true
    }

    // other browser
    return false
  },

  isEdge: function () {
    const ua = this.getUserAgent().toLowerCase()

    // Test values.
    // Uncomment to check result

    // Edge
    // ua = 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/42.0.2311.135 Safari/537.36 Edge/12.10240';

    const edge = ua.indexOf('edge')
    if (edge > 0) {
      return true
    }

    return false
  },

  isChrome: function () {
    // IE11 returns undefined for window.chrome
    // and new Opera 30 outputs true for window.chrome
    // but needs to check if window.opr is not undefined
    // and new IE Edge outputs to true for window.chrome
    // and if not iOS Chrome check
    const isChromium = (window as any).chrome
    const winNav = window.navigator
    const vendorName = winNav.vendor
    const isOpera = typeof (window as any).opr !== 'undefined'
    const isIEedge = winNav.userAgent.indexOf('Edge') > -1
    const isIOSChrome = /CriOS/.exec(winNav.userAgent)
    return (
      (isChromium !== null &&
        typeof isChromium !== 'undefined' &&
        vendorName === 'Google Inc.' &&
        isOpera === false &&
        isIEedge === false) ||
      isIOSChrome
    )
  },

  isOpera: function () {
    return this.userAgentContains(' OPR/')
  },
}

let DEFAULT_CUSTOM_PROTOCOL_FAIL_CALLBACK_TIMEOUT: number

const registerEvent = (target: any, eventType: any, cb: any) => {
  if (target.addEventListener) {
    target.addEventListener(eventType, cb)
    return {
      remove: function () {
        target.removeEventListener(eventType, cb)
      },
    }
  } else {
    target.attachEvent(eventType, cb)
    return {
      remove: function () {
        target.detachEvent(eventType, cb)
      },
    }
  }
}

const createHiddenIframe = (target: any, uri: any) => {
  const iframe = document.createElement('iframe')
  iframe.src = uri
  iframe.id = 'hiddenIframe'
  iframe.style.display = 'none'
  target.appendChild(iframe)

  return iframe
}

const openUriWithHiddenFrame = (uri: any, failCb: any, successCb: any) => {
  const timeout = setTimeout(function () {
    failCb()
    handler.remove()
  }, DEFAULT_CUSTOM_PROTOCOL_FAIL_CALLBACK_TIMEOUT)

  let iframe: HTMLIFrameElement | null = document.querySelector('#hiddenIframe')
  if (!iframe) {
    iframe = createHiddenIframe(document.body, 'about:blank')
  }

  const onBlur = () => {
    clearTimeout(timeout)
    handler.remove()
    successCb()
  }
  const handler = registerEvent(window, 'blur', onBlur)

  if (iframe.contentWindow) iframe.contentWindow.location.href = uri
}

const openUriWithTimeoutHack = (uri: any, failCb: any, successCb: any) => {
  const timeout = setTimeout(function () {
    failCb()
    handler.remove()
  }, DEFAULT_CUSTOM_PROTOCOL_FAIL_CALLBACK_TIMEOUT)

  //handle page running in an iframe (blur must be registered with top level window)
  let target: Window = window
  while (target.parent && target != target.parent) {
    target = target.parent
  }

  const onBlur = () => {
    clearTimeout(timeout)
    handler.remove()
    successCb()
  }

  const handler = registerEvent(target, 'blur', onBlur)

  window.location.href = uri
}

const openUriUsingFirefox = (uri: any, failCb: any, successCb: any) => {
  let iframe: HTMLIFrameElement | null = document.querySelector('#hiddenIframe')

  if (!iframe) {
    iframe = createHiddenIframe(document.body, 'about:blank')
  }

  try {
    if (iframe.contentWindow) iframe.contentWindow.location.href = uri

    successCb()
  } catch (e: any) {
    if (e.name == 'NS_ERROR_UNKNOWN_PROTOCOL') {
      failCb()
    }
  }
}

const openUriWithMsLaunchUri = (uri: any, failCb: any, successCb: any) => {
  ;(navigator as any).msLaunchUri(uri, successCb, failCb)
}

const getBrowserVersion = () => {
  const ua = window.navigator.userAgent
  let tem
  let M: any =
    /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i.exec(ua) ||
    []
  if (/trident/i.test(M[1])) {
    tem = /\brv[ :]+(\d+)/g.exec(ua) || []
    return parseFloat(tem[1]) || ''
  }
  if (M[1] === 'Chrome') {
    tem = /\b(OPR|Edge)\/(\d+)/.exec(ua)
    if (tem != null) {
      return parseFloat(tem[2])
    }
  }
  M = M[2]
    ? [M[1], M[2]]
    : [window.navigator.appName, window.navigator.appVersion, '-?']
  if ((tem = /version\/(\d+)/i.exec(ua)) != null) M.splice(1, 1, tem[1])
  return parseFloat(M[1])
}

const protocolCheck = (
  uri: any,
  failCb: any,
  successCb: any,
  timeout = 2000,
  unsupportedCb: any
) => {
  const failCallback = () => {
    failCb && failCb()
  }

  const successCallback = () => {
    successCb && successCb()
  }

  const unsupportedCallback = () => {
    unsupportedCb && unsupportedCb()
  }

  const openUri = () => {
    if (browser.isFirefox()) {
      const browserVersion = getBrowserVersion()
      if (browserVersion >= 64) {
        openUriWithHiddenFrame(uri, failCallback, successCallback)
      } else {
        openUriUsingFirefox(uri, failCallback, successCallback)
      }
    } else if (browser.isChrome()) {
      openUriWithTimeoutHack(uri, failCallback, successCallback)
    } else if (browser.isOSX()) {
      openUriWithHiddenFrame(uri, failCallback, successCallback)
    } else {
      //not supported, implement please
      unsupportedCallback()
    }
  }

  if (timeout) {
    DEFAULT_CUSTOM_PROTOCOL_FAIL_CALLBACK_TIMEOUT = timeout
  }

  if (browser.isEdge() || browser.isIE()) {
    //for IE and Edge in Win 8 and Win 10
    openUriWithMsLaunchUri(uri, failCb, successCb)
  } else {
    if (document.hasFocus()) {
      openUri()
    } else {
      const focusHandler = registerEvent(window, 'focus', () => {
        focusHandler.remove()
        openUri()
      })
    }
  }
}

export default protocolCheck
