
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class WPProgressBar extends Vue {
  @Prop({ default: false })
  private value?: boolean

  // private get getValue() {
  //   const val = Number(this.value) || 0
  //   if (val > 100) return 100
  //   if (val < 0) return 0
  //   return val
  // }
}
