import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import { Auth } from '@/store/auth'

import {
  Login,
  Onboarding,
  Password,
  PasswordChange,
  Registration,
  Calculators,
  CalculatorsEmpty,
  CalculatorsSelects,
  CalculatorsVolume,
  CalculatorsStrength,
  ResultsCalculation,
  Results,
  Fields,
  TheField,
  Wells,
  TheWell,
  TheWellbore,
  TheWellboreMain,
  TheWellboreParams,
  TheWellboreProfile,
  TheWellboreIntervals,
  TheWellboreEquip,
  TheWellboreVisualization,
  TheWellboreProfileGraph,
  PrivacyPolicy,
  Page404,
  Page403,
  PageMobile,
} from '@/views'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Fields',
    component: Fields,
  },
  {
    path: '/field/:id',
    name: 'TheField',
    component: TheField,
  },
  {
    path: '/field/:id/well/:wellId',
    name: 'TheWell',
    component: TheWell,
  },
  {
    path: '/field/:id/well/:wellId/wellbore/:wellboreId',
    name: 'TheWellbore',
    component: TheWellbore,
    children: [
      {
        path: 'main',
        component: TheWellboreMain,
        name: 'TheWellboreMain',
      },
      {
        path: 'params',
        component: TheWellboreParams,
        name: 'TheWellboreParams',
      },
      {
        path: 'profile',
        component: TheWellboreProfile,
        name: 'TheWellboreProfile',
      },
      {
        path: 'intervals',
        component: TheWellboreIntervals,
        name: 'TheWellboreIntervals',
      },
      {
        path: 'equip',
        component: TheWellboreEquip,
        name: 'TheWellboreEquip',
      },
      {
        path: 'visualization',
        component: TheWellboreVisualization,
        name: 'TheWellboreVisualization',
      },
      {
        path: 'graph',
        component: TheWellboreProfileGraph,
        name: 'ThwWellboreProfileGraph',
      },
    ],
  },
  {
    path: '/visualization',
    component: TheWellboreVisualization,
    name: 'TheWellboreVisualizationGradient',
  },
  {
    path: '/wells',
    name: 'Wells',
    component: Wells,
  },
  {
    path: '/calculators',
    name: 'Calculators',
    component: Calculators,
    children: [
      {
        path: 'empty',
        component: CalculatorsEmpty,
        name: 'CalculatorsEmpty',
      },
      {
        path: 'selects',
        component: CalculatorsSelects,
        name: 'CalculatorsSelects',
      },
    ],
  },
  {
    path: '/calculator/volume',
    name: 'CalculatorsConstructionSpaceVolumes',
    component: CalculatorsVolume,
  },
  {
    path: '/calculator/strength',
    name: 'CalculatorsEquipmentStrengthService',
    component: CalculatorsStrength,
  },
  {
    path: '/results',
    name: 'Results',
    component: Results,
  },
  {
    path: '/results/:calcId',
    name: 'ResultsCalculation',
    component: ResultsCalculation,
  },
  {
    path: '/privacy',
    name: 'PrivacyPolicy',
    component: PrivacyPolicy,
    meta: { all: true, guest: true },
  },
  {
    path: '/onboarding',
    name: 'Onboarding',
    component: Onboarding,
    meta: { guest: true },
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: { guest: true },
  },
  {
    path: '/registration',
    name: 'Registration',
    component: Registration,
    meta: { guest: true },
  },
  {
    path: '/password',
    name: 'Password',
    component: Password,
    meta: { guest: true },
  },
  {
    path: '/password-change',
    name: 'PasswordChange',
    component: PasswordChange,
    meta: { guest: true },
  },
  {
    path: '/404',
    name: 'PageNotFound',
    component: Page404,
    meta: { all: true },
  },
  // {
  //   path: '/403',
  //   name: 'PageNoPermission',
  //   component: Page403,
  //   meta: { all: true },
  // },
  {
    path: '/no-mobile',
    name: 'PageMobile',
    component: PageMobile,
    meta: { all: true },
  },
  { path: '*', redirect: '/404' },
]

const router = new VueRouter({
  mode: 'history',
  routes,
})

router.beforeEach(async (to, from, next) => {
  if (!Auth.isAuthentication) {
    await Auth.UPDATE_AUTHENTICATION()
  }

  if (to.matched.some((record) => record.meta.all)) {
    next()
    return
  }

  if (to.matched.some((record) => !record.meta.guest)) {
    if (to.name === from.name) {
      next(false)
    }
    if (!Auth.isAuthentication) {
      if (
        to.path === '/' ||
        to.path === '' ||
        to.path === '/404' ||
        to.path === '/403'
      ) {
        next({ name: 'Onboarding' })
      } else {
        next({ name: 'Login', query: { redirect: to.path, ...to.query } })
      }
    }
  } else {
    if (Auth.isAuthentication) {
      next({ name: 'Fields' })
    }
  }

  if (
    to.query?.viewtype !== 'mobile' &&
    (/Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    ) ||
      navigator.appVersion.includes('iPhone') ||
      navigator.appVersion.includes('iPad') ||
      navigator.appVersion.includes('Android'))
  ) {
    next({ name: 'PageMobile' })
  } else {
    next()
  }
})

export default router
