var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (!_vm.checkbox)?_c('div',{staticClass:"wp-exp-checkbox__header",class:{
    'wp-exp-checkbox__header_large': _vm.large,
  },attrs:{"hide-actions":_vm.checkbox}},[_c('VCheckbox',{staticClass:"wp-exp-checkbox__checkbox",attrs:{"dense":"","hide-details":"","disabled":_vm.disabled,"label":_vm.title},model:{value:(_vm.checkbox),callback:function ($$v) {_vm.checkbox=$$v},expression:"checkbox"}}),_c('span',{staticClass:"wp-exp-checkbox__header-minus"})],1):_c('VExpansionPanels',{staticClass:"wp-exp-checkbox",class:{
    'wp-exp-checkbox_active': _vm.isOpened === 0,
  },attrs:{"value":_vm.isOpened,"disabled":_vm.disabled && !_vm.showArrow}},[_c('VExpansionPanel',{staticClass:"wp-exp-checkbox__wrapper"},[_c('VExpansionPanelHeader',{staticClass:"wp-exp-checkbox__header",class:{
        'wp-exp-checkbox__header_large': _vm.large,
      }},[_c('VCheckbox',{staticClass:"wp-exp-checkbox__checkbox",attrs:{"dense":"","hide-details":"","disabled":_vm.disabled,"label":_vm.title},model:{value:(_vm.checkbox),callback:function ($$v) {_vm.checkbox=$$v},expression:"checkbox"}})],1),_c('VExpansionPanelContent',{staticClass:"wp-exp-checkbox__content",class:{
        'wp-exp-checkbox__content_nopad': _vm.large || _vm.nopad,
      }},[_vm._t("default")],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }