
import { Component, Mixins, Watch } from 'vue-property-decorator'
import { Field } from '@/store/field'
import { Wellbore } from '@/store/wellbore'
import { Calculator } from '@/store/calculators'
import { User } from '@/store/user'
import {
  WPCard,
  WPButton,
  WPTextfield,
  WPCheckbox,
  WPSelect,
} from '@/components'
import { RouteParamsMixin } from '@/mixins'

import panzoom, { PanZoom } from 'panzoom'

interface ILinkElement {
  id: string
  type: string
}

interface IGradient {
  text: string | null
  value: string | null
}

type TGradient = IGradient[]

interface ILabels {
  [key: string]: boolean
}

const MIN_HEIGHT_SVG = 50

const COEF_AXIS = 10

const getAxis = (
  index: number,
  scale: number,
  last = false,
  langCoef: number,
  unit: string
) => {
  const num = Math.round(index * COEF_AXIS * 10)
  if (num >= 0) {
    const valueY = (index * COEF_AXIS * scale) / langCoef + 20 * scale
    let classAxis = 'axis_base'
    if (index === 0 || last) classAxis = 'axis_side'
    if (index !== 0 && num % 500 === 0) classAxis = 'axis_half-thousand'
    if (index !== 0 && num % 1000 === 0) classAxis = 'axis_thousand'
    const text = `<text class="${classAxis}" fill="black" font-size="5" x="7" y="${
      valueY + 2
    }">${num === 0 ? String(num) + ' (' + unit + ')' : num}</text>`
    const line = `<line class="${classAxis}" stroke="black" stroke-width="0.5" x1="0" x2="5" y1="${valueY}" y2="${valueY}"></line>`
    return line + text
  } else return ''
}

@Component({
  components: {
    WPCard,
    WPButton,
    WPTextfield,
    WPCheckbox,
    WPSelect,
  },
})
export default class TheWellboreVisualization extends Mixins(RouteParamsMixin) {
  public wellboreSvg = ''

  private panZoomInstance?: PanZoom

  private equipId: number | null = null

  private equipChanged = false

  private dialog = false

  private isFullscreen = true

  private isMobileView = false

  private svgClasses: string[] = []

  private heightSvg = MIN_HEIGHT_SVG

  private zoomCoef = 1

  private timer = 0

  private gradientSelected: number | null = null

  private gradientsItems: TGradient = []

  private axises = ''

  private labels: ILabels = {
    intervals: true,
    elements: true,
    equips: true,
  }

  private showCoords = false

  private coordsValuesConverted = {
    x: '',
    y: '',
  }

  private getCoordsText = ''

  private disableClicks = false

  private axisTransform = {
    scale: 1,
    x: 0,
    y: 0,
  }

  private mouseHoldInit = {
    x: 0,
    y: 0,
  }

  private mouseHoldMove = {
    x: 0,
    y: 0,
  }

  private get mouseMoveDiff() {
    return {
      x: this.mouseHoldMove.x - this.mouseHoldInit.x,
      y: this.mouseHoldMove.y - this.mouseHoldInit.y,
    }
  }

  private get equips() {
    return Wellbore.equips
  }

  private get axisStyleTransform() {
    return `transform: translateY(${this.axisTransform.y}px)`
  }

  private get axisViewBox() {
    const viewBox = this.isFullscreen ? '0 0 490' : '0 0 370'
    return `${viewBox} ${this.heightSvg * this.axisTransform.scale + 50}`
  }

  private get svgViewBox() {
    const viewBox = this.isFullscreen ? '-280 -20 490' : '-160 -20 370'
    return `${viewBox} ${this.heightSvg}`
  }

  private get miniAxis() {
    return this.axisTransform.scale < (this.langCoef === 1 ? 0.4 : 1.3)
  }

  private get microAxis() {
    return this.langCoef !== 1 ? this.axisTransform.scale < 0.25 : false
  }

  private get isCalculationScheme() {
    return !!this.$route.query?.calculation
  }

  private get langCoef() {
    const type = this.isCalculationScheme
      ? Number(this.$route.query?.units)
      : Number(User.settings.measure_type)
    return type === 2 ? 3.281 : 1
  }

  private get axisUnit() {
    return this.langCoef === 1
      ? User.settings.language === 'en-us'
        ? 'm'
        : 'м'
      : 'ft'
  }

  private gradientSet() {
    this.gradientsItems.map((item) => {
      let style = 'none'
      if (item.value === this.gradientSelected) {
        style = 'block'
      }
      document
        .querySelector(`svg g[inkscape\\:label="${String(item.value)}"]`)
        ?.setAttribute('style', `display: ${style}`)
    })
  }

  private toggleDialog() {
    this.dialog = !this.dialog
  }

  private toggleSvgDialog() {
    this.isFullscreen = !this.isFullscreen
    this.toggleClass('fullscreen', this.isFullscreen)
    this.getAxises()
    this.emitZoomSvg()
  }

  private emitZoomSvg() {
    if (this.isFullscreen) {
      this.panZoomInstance?.zoomAbs(0, 0, 1)
      this.panZoomInstance?.moveTo(0, 30)

      if (this.zoomCoef === this.axisTransform.scale) this.onChangeScale()
      this.axisTransform.scale = this.zoomCoef

      this.panZoomInstance?.zoomAbs(0, 0, this.zoomCoef)
    } else {
      this.panZoomInstance?.moveTo(0, 0)
      this.panZoomInstance?.zoomAbs(0, 0, 1)
    }
  }

  private calculateZoom() {
    this.axisTransform.scale = 1
    this.axisTransform.x = 0
    this.axisTransform.y = 0

    const windowHeight = window.innerHeight - 100
    const svgHeight =
      document.getElementById('mainSvg')?.clientHeight || windowHeight
    const svgHeightUnit = svgHeight / this.langCoef
    const svgFullscreenScale = Number((windowHeight / svgHeightUnit).toFixed(3))
    const coef = svgFullscreenScale / this.axisTransform.scale

    const windowWidth = window.innerWidth - 100
    const svgWidth =
      document.getElementById('mainSvg')?.clientWidth || windowWidth
    const svgFullscreenScaleWidth =
      windowWidth < 1300 ? Number((windowWidth / svgWidth).toFixed(3)) : 1
    const coefWidth = svgFullscreenScaleWidth / this.axisTransform.scale

    const minCoef = coef < coefWidth ? coef : coefWidth

    this.zoomCoef = minCoef
    this.emitZoomSvg()
  }

  private cancel() {
    if (this.isCalculationScheme) {
      void this.$router.push({
        name: 'ResultsCalculation',
        params: {
          calcId: String(this.$route.query?.calculation),
        },
      })
    } else {
      void this.$router.push({
        name: 'TheWellbore',
        params: {
          id: String(this.fieldId),
          wellId: String(this.wellId),
          wellboreId: String(this.wellboreId),
        },
      })
    }
  }

  private prepareSvg(svg: string) {
    const index = svg.indexOf('<defs>')
    let result = svg.slice(index, svg.length - 6)
    this.wellboreSvg = result

    const elem = document.getElementById('mainSvg')
    if (elem) {
      const instance = panzoom(elem, {
        maxZoom: 5,
        minZoom: 0.1,
        bounds: true,
        boundsPadding: 0.01,
        smoothScroll: false,
        onTouch: function () {
          return false
        },
      })

      this.panZoomInstance = instance

      instance.on('panstart', (e: PanZoom) => {
        this.syncAxisTransform(e)
      })

      instance.on('pan', (e: PanZoom) => {
        this.syncAxisTransform(e)
      })

      instance.on('panend', (e: PanZoom) => {
        this.syncAxisTransform(e)
      })

      instance.on('zoomend', (e: PanZoom) => {
        this.syncAxisTransform(e)
      })

      instance.on('transform', (e: PanZoom) => {
        this.syncAxisTransform(e)
      })
    }

    this.timer = setTimeout(() => {
      this.calculateZoom()
    }, 0)
  }

  private syncAxisTransform(instance: PanZoom) {
    const result = instance.getTransform()
    this.axisTransform = { ...result }
  }

  private getHeightSvg() {
    const result = document.querySelector(
      '.the-wellbore-visualization__wrapper svg.the-wellbore-visualization__svg > g:last-child > g > g > text:last-of-type'
    )

    return result ? Number(result.innerHTML) / 10 + 35 : MIN_HEIGHT_SVG
  }

  private getAxises() {
    const coefAxisForCeil = COEF_AXIS * 5
    const lineHeight =
      Math.ceil(this.heightSvg / coefAxisForCeil) * coefAxisForCeil
    const shiftLineY = this.langCoef === 1 ? 15 : 65
    const line = `<line stroke="white" stroke-width="0.5" x1="2.5" x2="2.5" y1="0" y2="${
      20 * this.axisTransform.scale
    }"/><line stroke="black" stroke-width="0.5" x1="2.5" x2="2.5" y1="${
      20 * this.axisTransform.scale
    }" y2="${
      ((lineHeight + shiftLineY) * this.axisTransform.scale) / this.langCoef
    }" />`
    let axises = []
    const count = lineHeight / COEF_AXIS

    for (let i = 0; i < count; i++) {
      const truncCount =
        count === Math.trunc(count) ? count - 1 : Math.trunc(count)
      const isLast = i === truncCount
      const line = getAxis(
        i,
        this.axisTransform.scale,
        isLast,
        this.langCoef,
        this.axisUnit
      )
      axises.push(line)
    }
    return line + axises.join()
  }

  private get getPageTitle() {
    let title = String(this.$t('wellboreVizualization.title'))
    if (!this.isCalculationScheme) {
      return title
    } else {
      const name = this.gradientsItems.find(
        (item) => item.value === this.gradientSelected
      )?.text
      return name
        ? `${title} – ${name}`
        : `${title} – ${String(this.$t('wellboreVizualization.noGradient'))}`
    }
  }

  private checkIdElement(elem: HTMLElement): ILinkElement | undefined {
    let result = null
    let type = ''
    while (result === null) {
      if (elem.hasAttribute('interval')) {
        result = elem.getAttribute('interval')
        type = 'interval'
      }
      if (elem.hasAttribute('element')) {
        result = elem.getAttribute('element')
        type = 'element'
      }
      if (elem.hasAttribute('pipe')) {
        result = elem.getAttribute('pipe')
        type = 'pipe'
      }
      if (elem.hasAttribute('equipe-element')) {
        result = elem.getAttribute('equipe-element')
        type = 'equipe-element'
      }
      if (result) {
        return { id: result, type }
      } else if (elem.parentElement) {
        return this.checkIdElement(elem.parentElement)
      } else return undefined
    }
  }

  private toggleClass(type: string, value: boolean) {
    const typeClass = `the-wellbore-visualization__svg_show-${type}`
    const index = this.svgClasses.findIndex((item) => item === typeClass)
    if (index === -1 && value) {
      this.svgClasses.push(typeClass)
    } else if (index !== -1 && !value) {
      this.svgClasses.splice(index, 1)
    }
  }

  private getGradients() {
    const g = document.querySelector(
      `svg g[inkscape\\:label="equipe_gradients"]`
    )
    if (g) {
      let result: TGradient = []
      const nodes: HTMLCollection = g.children
      for (let i = 0; i < nodes.length; i++) {
        const obj = {
          text: nodes[i].getAttribute('display-name'),
          value: nodes[i].getAttribute('inkscape:label'),
        }
        result.push(obj)
      }
      this.gradientsItems = result
    }
  }

  private async confirmLabels(init: boolean) {
    const { intervals, elements, equips } = this.labels
    this.toggleClass('intervals', intervals)
    this.toggleClass('pipes', elements)
    this.toggleClass('elements', elements)
    this.toggleClass('equips', equips)
    if (this.equipChanged) {
      this.prepareSvg(
        String(
          await Wellbore.GET_WELLBORE_VISUALIZATION({
            wellboreId: Number(this.wellboreId),
            equipId: Number(this.equipId),
          })
        )
      )
      if (this.equipId !== null)
        void this.$router
          .push({
            query: { ...this.$route.query, equip: String(this.equipId) },
          })
          .catch(() => {
            return
          })
      else
        void this.$router
          .push({
            query: { ...this.$route.query, equip: undefined },
          })
          .catch(() => {
            return
          })
      this.equipChanged = false
    }
    if (!init) this.toggleDialog()
  }

  @Watch('equipId')
  private onChangeEquip(val: number | null) {
    this.equipChanged = true
  }

  @Watch('gradientSelected')
  private onChangeGradient() {
    this.gradientSet()
  }

  @Watch('axisTransform.scale')
  private onChangeScale() {
    this.axises = this.getAxises()
  }

  @Watch('disableClicks', {
    immediate: true,
  })
  private onChangeClickable(val: boolean) {
    if (!val) {
      this.svgClasses.push('the-wellbore-visualization__svg_clickable')
    } else {
      const index = this.svgClasses.findIndex(
        (item) => item === 'the-wellbore-visualization__svg_clickable'
      )
      this.svgClasses.splice(index, 1)
    }
  }

  private onScrollSvg(event: MouseEvent) {
    // Y: 1 px = 10 m
    // X: 35 px = 100 mm

    if (this.showCoords) {
      const x = Math.round(((event.offsetX - 560) * 100) / 70)
      const y = Math.round(((event.offsetY - 69) * 10) / 3.5)

      this.coordsValuesConverted.x = `${x} ${String(
        this.$t('wellboreVizualization.mm')
      )}`
      this.coordsValuesConverted.y = `${y} ${String(
        this.$t('wellboreVizualization.m')
      )}`
    }
  }

  private async onLoadEquips() {
    await Wellbore.GET_EQUIPS({ wellboreId: Number(this.wellboreId) })
  }

  private onClick(event: MouseEvent) {
    if (!this.disableClicks && event.target) {
      const clickResult = this.checkIdElement(event.target as HTMLElement)

      if (clickResult && this.isMobileView) {
        const data = {
          ...clickResult,
          equip: this.equipId ? String(this.equipId) : null,
        }
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
        ;(window as any).ReactNativeWebView.postMessage(JSON.stringify(data))
      } else {
        if (clickResult?.type === 'equipe-element') {
          const routeData = this.$router.resolve({
            name: 'TheWellboreEquip',
            query: { id: String(this.equipId), elem: clickResult.id },
          })
          if (this.equipId) window.open(routeData.href, '_blank')
        } else if (clickResult && this.labels[`${clickResult.type}s`]) {
          const routeData = this.$router.resolve({
            name: 'TheWellboreIntervals',
            query: { type: clickResult.type, id: clickResult.id },
          })
          window.open(routeData.href, '_blank')
        }
      }
    }
  }

  private async mounted() {
    if (this.$route.query?.viewtype === 'mobile') {
      this.isMobileView = true
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
      ;(window as any).ReactNativeWebView.postMessage(
        JSON.stringify({ isLoaded: true })
      )
    } else if (!this.$route.query?.viewtype) {
      this.isMobileView = false
    }

    if (this.isCalculationScheme) {
      this.disableClicks = true
      this.labels.elements = false
      this.labels.equips = false
      const calcId = String(this.$route.query?.calculation)
      const scheme = String(await Calculator.GET_SCHEME(calcId))
      this.prepareSvg(scheme)
      this.$nextTick(function () {
        this.getGradients()
      })
    } else {
      if (!this.field) await Field.GET_FIELDS()
      if (!this.well)
        await Field.GET_WELLS({ fieldId: Number(this.$route.params.id) })
      if (!this.wellbore)
        await Field.GET_WELLBORES({ wellId: Number(this.$route.params.wellId) })
      await Wellbore.GET_EQUIPS({ wellboreId: Number(this.wellboreId) })
      this.equipId = this.$route.query?.equip
        ? Number(this.$route.query?.equip)
        : null

      this.prepareSvg(
        String(
          await Wellbore.GET_WELLBORE_VISUALIZATION({
            wellboreId: Number(this.wellboreId),
            equipId: Number(this.equipId),
          })
        )
      )
    }

    void this.confirmLabels(true)
  }

  private updated() {
    const height = this.getHeightSvg()
    this.heightSvg = height && height > MIN_HEIGHT_SVG ? height : MIN_HEIGHT_SVG
  }

  private beforeDestroy() {
    clearTimeout(this.timer)
  }
}
