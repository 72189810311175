import { Component, Vue } from 'vue-property-decorator'

import { Field } from '@/store/field'

@Component
class ClustersSearchMixin extends Vue {
  public selectLoadingClusters = false

  public searchTextClusters: string | undefined = ''

  public get queryClusters() {
    return this.searchTextClusters
      ? { name: this.searchTextClusters }
      : undefined
  }

  public async onSearchClusters(search = '') {
    this.searchTextClusters = search
    this.selectLoadingClusters = true
    void Field.CLEAR_DATA('clusters')
    await this.onLoadClusters()
    this.selectLoadingClusters = false
  }

  public async onLoadClusters() {
    await Field.GET_CLUSTERS({
      name: this.searchTextClusters,
    })
  }
}

export default ClustersSearchMixin
