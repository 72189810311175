
import { Component, Prop, Vue } from 'vue-property-decorator'
import WPBaseButton from './base/WPBaseButton.vue'

import { IConnectionSymbol } from '@/types'

const TYPES = ['equip', 'interval', 'point', 'element', 'area']

interface IFooterInfo {
  text: string
  value: string | number
}

type TFooterInfo = IFooterInfo[]

@Component({
  components: { WPBaseButton },
})
export default class WPInfoBlock extends Vue {
  @Prop({ default: null })
  private index?: number

  @Prop({ default: '' })
  private title?: string

  @Prop({ default: '' })
  private subtitle?: string

  @Prop({ default: '' })
  private subinfoFirst?: string

  @Prop({ default: '' })
  private subinfoSecond?: string

  @Prop({ default: () => [] })
  private subinfoFooter?: TFooterInfo

  @Prop({ default: '' })
  private type?: string

  @Prop({ default: false })
  private editing?: boolean

  @Prop({ default: '' })
  private color?: string

  @Prop({ default: false })
  private icon?: boolean

  @Prop({ default: false })
  private isCased?: boolean

  @Prop({ default: false })
  private disabled?: boolean

  @Prop({ default: 0 })
  private symbolsKey?: number

  @Prop({ default: () => ({ up: '', down: '' }) })
  private symbols?: IConnectionSymbol | null

  private get correctType() {
    return TYPES.includes(String(this.type))
  }

  private get slots(): boolean {
    return !!Object.keys(this.$slots).length
  }

  private onClick($event: MouseEvent) {
    this.$emit('click', $event)
  }
}
