
import { Component, Vue } from 'vue-property-decorator'
import { WPTextfield } from '@/components'
import WPTextfieldPassword from '@/components/WPTextfieldPassword.vue'
import WPButton from '@/components/WPButton.vue'

import { Auth } from '@/store/auth'
import { IRegisterData } from '@/types'
import { Init } from '@/store/init'

@Component({
  components: { WPButton, WPTextfieldPassword, WPTextfield },
})
export default class Registration extends Vue {
  private login = ''

  private email = ''

  private password = ''

  private passwordRepeat = ''

  private valid = false

  private get isValid() {
    return this.valid && this.password === this.passwordRepeat
  }

  private get initOptions() {
    return Init.settings
  }

  private async register() {
    const regData: IRegisterData = {
      username: this.login,
      password: this.password,
      email: this.email,
      initLang: this.$i18n.locale,
    }

    const correctAuth = await Auth.REGISTER(regData)
    if (correctAuth) await this.$router.push('/')
  }
}
