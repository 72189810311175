
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class WPBlockInfo extends Vue {
  @Prop({ default: '' })
  private index?: string | number

  @Prop({ default: '' })
  private title?: string

  @Prop({ default: '' })
  private subtitle?: string

  @Prop({ default: 'element' })
  private type?: string

  @Prop({ default: '' })
  private up?: string

  @Prop({ default: '' })
  private down?: string
}
