
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import WPButton from './WPButton.vue'
import WPEmptyList from './WPEmptyList.vue'
import WPBlockBtn from './WPBlockBtn.vue'
import WPTextfield from './WPTextfield.vue'
import WPDivider from './WPDivider.vue'
import WPLoader from './WPLoader.vue'

import { Field } from '@/store/field'

import { TFieldCounts } from '@/types'

@Component({
  components: {
    WPButton,
    WPEmptyList,
    WPBlockBtn,
    WPTextfield,
    WPDivider,
    WPLoader,
  },
})
export default class WPList extends Vue {
  @Prop({ default: () => [] })
  private items?: Record<string, any>[]

  @Prop({ default: '' })
  private emptyTitle?: string

  @Prop({ default: 'mdi-content-copy' })
  private actionBtnIcon?: string

  @Prop({ default: 'mdi-arrow-collapse-vertical' })
  private multiActionBtnIcon?: string

  @Prop({ default: 'mdi-trash-can-outline' })
  private clickBtnIcon?: string

  @Prop({ default: '' })
  private itemsSubtitle?: string

  @Prop({ default: 'plus' })
  private btnIcon?: string

  @Prop({ default: 'name' })
  private searchProp?: string

  @Prop({ default: false })
  private searchFilter?: boolean

  @Prop({ default: '' })
  private searchLabel?: string

  @Prop({ default: true })
  private isAddBtn?: boolean

  @Prop({ default: true })
  private actionBtnConfirm?: boolean

  @Prop({ default: false })
  private withActions?: boolean

  @Prop({ default: true })
  private isTwoActionButtons?: boolean

  @Prop({ default: false })
  private isMultiActionButtons?: boolean

  @Prop({ default: false })
  private busy?: boolean

  @Prop({ default: false })
  private loading?: boolean

  @Prop({ default: 'fields' })
  private dataType?: TFieldCounts

  @Prop({ default: null })
  private emptyCount?: number | null

  @Prop({ default: false })
  private isWellboreStatusIcon?: boolean

  private search = ''

  private timerId?: number = undefined

  private get label() {
    return this.searchLabel ? this.searchLabel : this.$t('common.search')
  }

  private clickItem(id: number) {
    this.$emit('on-click-item', id)
  }

  private openFilters() {
    this.$emit('filters')
  }

  private onLoad() {
    this.$emit('onLoad')
  }

  private onClick(id: number) {
    this.$emit('on-main-action-item', id)
  }

  private onAction(id: number) {
    this.$emit('on-action-item', id)
  }

  private onMulti(id: number) {
    this.$emit('on-multi-action-item', id)
  }

  private onSearch(val: string) {
    this.search = val
    clearTimeout(this.timerId)

    this.timerId = setTimeout(() => {
      this.$emit('onSearch', val)
    }, 300)
  }

  @Watch('search')
  private onChangeSearch() {
    if (this.dataType) void Field.CLEAR_DATA(this.dataType)
  }

  private mounted() {
    if (this.$route.query.search) {
      this.search = String(this.$route.query.search)
    }
  }
}
