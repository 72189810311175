
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class WPCheckbox extends Vue {
  @Prop({ default: '' })
  private title?: string

  @Prop({ default: '' })
  private name?: string

  @Prop({ default: false })
  private value?: boolean

  @Prop({ default: false })
  private solo?: boolean

  @Prop({ default: false })
  private transparent?: boolean

  @Prop({ default: false })
  private readonly disabled?: boolean

  private get state() {
    return this.value
  }

  private set state(value) {
    this.$emit('input', value)
  }

  private change() {
    if (!this.disabled) this.state = !this.state
  }
}
