
import { Component, Mixins, Prop } from 'vue-property-decorator'

import WPBaseDatepicker from './base/WPBaseDatepicker.vue'
import { ValidationMixin } from '@/mixins'

@Component({
  components: { WPBaseDatepicker },
})
export default class WPDatepicker extends Mixins(ValidationMixin) {
  @Prop({ default: null })
  public readonly value?: string | string[] | null

  @Prop({ default: true })
  public readonly range?: boolean

  private get computedValue() {
    return this.value
  }

  private set computedValue(value) {
    this.$emit('input', value)
  }
}
