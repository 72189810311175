
import { Component, Vue, Prop } from 'vue-property-decorator'
import WPInputLabel from './WPInputLabel.vue'

@Component({
  components: {
    WPInputLabel,
  },
})
export default class WPGroup extends Vue {
  @Prop({ default: '' })
  public readonly label?: string

  @Prop({ default: false })
  public readonly flat?: boolean
}
