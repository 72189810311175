
import { Component, Vue, Prop } from 'vue-property-decorator'
import {
  WPButtonIcon,
  WPTextfield,
  WPTextfieldMeasure,
  WPRadioGroup,
  WPBlockBtn,
  WPDivider,
  WPInfoBlock,
  WPGroup,
  WPSwitch,
  WPGroupMultiline,
  WPCheckbox,
  WPColorPicker,
  WPLayoutBorder,
  WPExpPanel,
} from '@/components'
import { Wellbore } from '@/store/wellbore'
import { Unit } from '@/store/units'

import { TEquipAreas, IEquipArea } from '@/types'

import { eventBus } from '@/helpers/eventBus'

export interface IEquipAreaUnitKeys {
  length_uc: number
  outer_diameter_uc: number
  inner_diameter_uc: number
}

const mockElement: IEquipArea = {
  number: 1,
  length: null,
  length_uc: 100,
  outer_diameter: null,
  outer_diameter_uc: 100,
  inner_diameter: null,
  inner_diameter_uc: 100,
}

@Component({
  components: {
    WPButtonIcon,
    WPTextfield,
    WPTextfieldMeasure,
    WPRadioGroup,
    WPBlockBtn,
    WPDivider,
    WPInfoBlock,
    WPGroup,
    WPSwitch,
    WPGroupMultiline,
    WPCheckbox,
    WPColorPicker,
    WPLayoutBorder,
    WPExpPanel,
  },
})
export default class TheWellboreEquipElementEquipAreas extends Vue {
  @Prop({ type: Number, required: true })
  private element?: number

  private valid = false

  private editIndex: number | null = null

  private editId?: number = undefined

  private isFormOpened = false

  private isShowAddBtnList = false

  private isShowAddBtnListId = 0

  private isShowEditBtnList = false

  private isShowEditBtnListId = 0

  private loading = false

  private area: IEquipArea = {
    ...mockElement,
  }

  private get isEdit() {
    return this.editIndex !== null
  }

  private get areas(): TEquipAreas {
    return Wellbore.equipeAreas
  }

  private get listAreas(): TEquipAreas {
    return [...this.areas].sort((a, b) => Number(a.number) - Number(b.number))
  }

  private changeMeasure(val: number, key: keyof IEquipAreaUnitKeys) {
    this.area[key] = val
  }

  private resetForm() {
    const form = this.$refs.equipAreasForm as Array<
      Vue & { resetValidation: () => void }
    >
    if (form && form[0]) form[0].resetValidation()

    this.isFormOpened = false
    this.toggleEditBtnList(false, 0)
    this.toggleAddBtnList(false, 0)
  }

  private getUnitSymbol(code: number) {
    return Unit.units.find((item) => item.code === code)?.symbol
  }

  private toggleAddBtnList(val: boolean, id: number) {
    this.isShowAddBtnList = val
    this.isShowAddBtnListId = id
  }

  private toggleEditBtnList(val: boolean, id: number) {
    this.isShowEditBtnList = val
    this.isShowEditBtnListId = id
  }

  private showAddBtnList(id: number, index: number) {
    const currentBtn = this.isShowAddBtnList && this.isShowAddBtnListId === id
    const currentEditBtn =
      this.isShowEditBtnList && this.isShowEditBtnListId === id

    const isLast = this.listAreas.length
      ? index !== this.listAreas.length - 1
      : false
    let isHasEmptyNumber = false

    if (isLast) {
      const numCurr = this.listAreas[index].number || 0
      const numNext = this.listAreas[index + 1].number || 0
      isHasEmptyNumber = numNext - numCurr > 1
      return (currentBtn && isHasEmptyNumber) || currentEditBtn
    } else return true
  }

  private visibleAddBtnList(id: number, index: number) {
    if (this.listAreas.length) {
      const isLast = index !== this.listAreas.length - 1
      let isHasEmptyNumber = false
      if (isLast) {
        const numCurr = this.listAreas[index].number || 0
        const numNext = this.listAreas[index + 1].number || 0
        isHasEmptyNumber = numNext - numCurr > 1
        return isHasEmptyNumber
      } else return true
    } else return false
  }

  private getAreaInfo(item: IEquipArea) {
    const unitLength = String(this.getUnitSymbol(item.length_uc))
    return `${String(this.$t('areas.lengthInfo'))} ${String(
      item.length
    )} ${unitLength}`
  }

  private getAreaSubInfo(item: IEquipArea) {
    const unitLengthOuter = String(this.getUnitSymbol(item.outer_diameter_uc))
    const unitLengthInner = String(this.getUnitSymbol(item.inner_diameter_uc))
    return `Ø = ${String(item.outer_diameter)} ${unitLengthOuter} x ${String(
      item.inner_diameter
    )} ${unitLengthInner}`
  }

  private editPoint(item: IEquipArea, index: number) {
    this.toggleEditBtnList(true, Number(item.id))
    this.toggleAddBtnList(true, Number(item.id))
    this.isFormOpened = true
    this.editId = item.id
    this.editIndex = index
    this.area = { ...item }
  }

  private async addArea() {
    this.loading = true
    const checkedLength = await this.checkLength()
    if (typeof checkedLength === 'number') {
      eventBus.$emit('showError', `areasLength | ${checkedLength}`)
      this.area.length = null
      this.loading = false
      return
    }

    if (this.isEdit) {
      void this.saveArea()
      return
    }

    const area = {
      ...this.area,
      equipe_element: this.element,
    }

    const noNumber = this.areas.find(
      (item) => item.number === Number(area?.number) + 1
    )

    let number = 0
    const resp = await Wellbore.ADD_EQUIP_AREA(area)

    if (resp) {
      if (noNumber) {
        number = Number(this.listAreas[this.listAreas.length - 1].number) + 1
        this.toggleAddBtnList(
          true,
          Number(this.listAreas[this.listAreas.length - 1].id)
        )
      } else {
        number = Number(area?.number) + 1
        this.toggleAddBtnList(true, Number(area?.id))
      }
      this.area = {
        ...mockElement,
        number: number,
        length_uc: this.area.length_uc,
        outer_diameter_uc: this.area.outer_diameter_uc,
        inner_diameter_uc: this.area.inner_diameter_uc,
      }
      this.resetForm()
    }
    this.loading = false
  }

  private async saveArea() {
    const area = {
      ...this.area,
      equipe_element: this.element,
    }
    const resp = await Wellbore.SAVE_EQUIP_AREA(area)

    if (resp) {
      this.editIndex = null
      this.editId = undefined
      this.area = {
        ...mockElement,
      }
      this.resetForm()
    }
    this.loading = false
  }

  private async checkLength() {
    const result = Wellbore.equipeElements.find(
      (item) => item.id === this.element
    )
    if (result) {
      const elemLength = await Unit.CONVERSE_UNITS({
        sourceVal: result.length,
        sourceUnit: result.length_uc,
        destUnit: 100,
      })

      let areasLength = 0
      let promises: Promise<number | null>[] = []
      const currentAreaLength = await Unit.CONVERSE_UNITS({
        sourceVal: this.area.length,
        sourceUnit: this.area.length_uc,
        destUnit: 100,
      })
      this.areas.forEach((item, index) => {
        if (!(this.editIndex !== null && index >= this.editIndex))
          promises.push(
            Unit.CONVERSE_UNITS({
              sourceVal: item.length,
              sourceUnit: item.length_uc,
              destUnit: 100,
            })
          )
      })
      await Promise.all(promises).then((values) => {
        values.map((value) => {
          if (value) areasLength = areasLength + value
        })
      })
      const fullLength = areasLength + Number(currentAreaLength)

      return Number(elemLength) >= fullLength
        ? false
        : Number(elemLength) - areasLength
    } else return false
  }

  private onBlurBlockBtnList() {
    if (!this.isFormOpened && !this.isEdit) this.toggleAddBtnList(false, 0)
  }

  private onHoverBlockBtnList(value: boolean, id: number) {
    if (!this.isFormOpened && !this.isEdit) this.toggleAddBtnList(value, id)
  }

  private onClickExpPanel(value: boolean, item: IEquipArea) {
    if (value !== undefined && item.id) this.toggleAddBtnList(value, item.id)
    if (this.isFormOpened) {
      this.isFormOpened = false
    } else {
      this.isFormOpened = true
      if (!this.isEdit) this.area.number = Number(item.number) + 1
    }
  }

  private onClickEmptyExpPanel(value: boolean) {
    if (this.isFormOpened) {
      this.isFormOpened = false
    } else {
      this.isFormOpened = true
      this.area.number = 1
    }
  }

  private async onDelete(id: number) {
    await Wellbore.DELETE_EQUIP_AREA(id)
  }

  private async mounted() {
    await Wellbore.GET_EQUIP_AREAS(Number(this.element))
    if (this.areas.length === 0) this.isFormOpened = true
  }
}
