
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class WPBaseButton extends Vue {
  @Prop({ default: false })
  private disabled?: boolean

  @Prop({ default: false })
  private outlined?: boolean

  @Prop({ default: false })
  private fab?: boolean

  @Prop({ default: false })
  private text?: boolean

  private onButtonClick($event: MouseEvent) {
    this.$emit('click', $event)
  }
}
