
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class WPBlockHint extends Vue {
  @Prop({ default: 'mdi-help-circle' })
  private icon?: string

  @Prop({ default: '' })
  private text?: string
}
