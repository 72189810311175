var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"wp-button-menu",class:{
    'wp-button-menu_selected': _vm.selected,
    'wp-button-menu_ready': _vm.ready,
    'wp-button-menu_mini': _vm.mini,
    'wp-button-menu_error': _vm.error,
    'mb-4': _vm.margin && !_vm.mini,
    'mb-2': _vm.margin && _vm.mini,
  },on:{"click":function($event){return _vm.$emit('click')}}},[_vm._t("default"),(_vm.index)?_c('div',{staticClass:"wp-button-menu__index",class:{ 'wp-button-menu__index_ready': _vm.ready }},[_vm._v(" "+_vm._s(_vm.index)+" ")]):_vm._e(),(_vm.error)?_c('img',{staticClass:"wp-button-menu__error",attrs:{"src":require("@/assets/icons/asterisk.svg")}}):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }