import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import {
  requiredRule,
  minRule,
  maxRule,
  emailRule,
  zeroRule,
  integerRule,
} from '@/helpers/rules'

@Component
class ValidationMixin extends Vue {
  @Prop({ default: false })
  public readonly required?: boolean

  @Prop({ default: null })
  public readonly value?: any

  @Prop({ default: null })
  public readonly min?: number

  @Prop({ default: null })
  public readonly max?: number

  @Prop({ default: false })
  public readonly email?: boolean

  @Prop({ default: false })
  public readonly integer?: boolean

  @Prop({ default: false })
  public readonly notZero?: boolean

  public get rules() {
    const result = []
    const val = this.value !== null ? String(this.value) : ''

    if (this.required && requiredRule(val)) {
      result.push(this.$t('validations.required'))
    }
    if (this.notZero && zeroRule(val)) {
      result.push(this.$t('validations.zero'))
    }
    if (this.min && minRule(val, this.min)) {
      result.push(this.$t('validations.min', { min: this.min }))
    }
    if (this.max && maxRule(val, this.max)) {
      result.push(this.$t('validations.max', { max: this.max }))
    }
    if (this.email && emailRule(String(val))) {
      result.push(this.$t('validations.email'))
    }

    if (this.integer && integerRule(val)) {
      result.push(this.$t('validations.integer'))
    }
    return result
  }
}

export default ValidationMixin
