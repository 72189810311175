
import { Component, Vue, Prop } from 'vue-property-decorator'
import { ICalculatorResult, ICalculator } from '@/types'

@Component
export default class WPCalculationResult extends Vue {
  @Prop({ default: null })
  private readonly item?: ICalculatorResult | null

  @Prop({ default: null })
  private readonly calc?: ICalculator | null

  @Prop({ default: '' })
  private readonly date?: string

  @Prop({ default: false })
  private readonly selected?: boolean

  private get loaded() {
    return this.item && this.info && this.calc
  }

  private getLanguage(lang?: string) {
    return lang === 'ru-ru' ? 'РУС' : 'ENG'
  }

  private get info() {
    return this.item?.well_equipmentset_info
      ? this.item?.well_equipmentset_info
      : false
  }

  private click(id?: number) {
    if (id) {
      this.$emit('click', {
        id: String(id),
        calc: this.calc?.display_name,
        code: this.calc?.code,
        user: this.item?.user,
        measure: this.item?.measure_type,
      })
    }
  }
}
