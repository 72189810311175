var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"wp-calculators"},[(!_vm.isNotMainPage || _vm.isEmptyPage)?_c('WPTitle',[_vm._v(_vm._s(_vm.$t('calculators.pageTitle')))]):_vm._e(),_c('div',{staticClass:"wp-calculators__content"},[_c('WPOpacity',{attrs:{"opacity":_vm.isNotMainPage || _vm.loading}},[_c('WPCard',{staticClass:"wp-calculators__main-card",attrs:{"cancel-btn":false,"add-btn":false}},[_c('WPButtonMenu',{attrs:{"mini":true,"selected":_vm.checkSelectedCalc('ConstructionSpaceVolumes')},on:{"click":function($event){_vm.goTo(
              'ConstructionSpaceVolumes',
              _vm.$t('calculators.calcs.volume'),
              1
            )}}},[_vm._v(" 1. "+_vm._s(_vm.$t('calculators.calcs.volume'))+" ")]),_c('WPButtonMenu',{attrs:{"mini":true,"selected":_vm.checkSelectedCalc('EquipmentStrengthService')},on:{"click":function($event){_vm.goTo(
              'EquipmentStrengthService',
              _vm.$t('calculators.calcs.strength'),
              2
            )}}},[_vm._v(" 2. "+_vm._s(_vm.$t('calculators.calcs.strength'))+" ")]),_c('WPLayoutInDev',{staticClass:"mt-4"},[_c('WPButtonMenu',{attrs:{"mini":true}},[_vm._v(" 3. "+_vm._s(_vm.$t('calculators.calcs.welljam'))+" ")]),_c('WPButtonMenu',{attrs:{"mini":true}},[_vm._v(" 4. "+_vm._s(_vm.$t('calculators.calcs.repair'))+" ")]),_c('WPButtonMenu',{attrs:{"mini":true}},[_vm._v(" 5. "+_vm._s(_vm.$t('calculators.calcs.circulation'))+" ")]),_c('WPButtonMenu',{attrs:{"mini":true}},[_vm._v(" 6. "+_vm._s(_vm.$t('calculators.calcs.depth'))+" ")]),_c('WPButtonMenu',{attrs:{"mini":true}},[_vm._v(" 7. "+_vm._s(_vm.$t('calculators.calcs.equipload'))+" ")]),_c('WPButtonMenu',{attrs:{"mini":true}},[_vm._v(" 8. "+_vm._s(_vm.$t('calculators.calcs.rheology'))+" ")]),_c('WPButtonMenu',{attrs:{"mini":true}},[_vm._v(" 9. "+_vm._s(_vm.$t('calculators.calcs.clearing'))+" ")]),_c('WPButtonMenu',{attrs:{"mini":true}},[_vm._v(" 10. "+_vm._s(_vm.$t('calculators.calcs.block'))+" ")])],1)],1)],1),_c('WPOpacity',[_c('RouterView',{attrs:{"calculator":_vm.selectedCalculator,"code":_vm.codeSelectedCalculator}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }