
import { Component, Vue, Prop } from 'vue-property-decorator'

export type TColors = string[]

@Component
export default class WPPalettePicker extends Vue {
  @Prop({ default: '' })
  private readonly title?: string

  @Prop({ default: () => [] })
  private readonly colors?: TColors

  @Prop({ default: '' })
  private readonly value?: string

  private classes(val: string) {
    let result = [`wp-palette-picker__color_${val}`]
    if (val === this.value) result.push('wp-palette-picker__color_active')
    return result
  }

  private onClick(val: string) {
    this.$emit('input', val)
  }
}
