import Vue from 'vue'
import Vuex from 'vuex'
import { IAuthState } from './auth'
import { IFieldState } from './field'
import { IUnitState } from './units'
import { IWellboreState } from './wellbore'
import { ICalculatorState } from './calculators'
import { IUserState } from './user'
import { ICommonState } from './common'
import { IInitState } from './init'

Vue.use(Vuex)

export interface IRootState {
  auth: IAuthState
  field: IFieldState
  units: IUnitState
  wellbore: IWellboreState
  calculator: ICalculatorState
  user: IUserState
  common: ICommonState
  init: IInitState
}

export default new Vuex.Store<IRootState>({})
