
import { Component, Prop, Vue } from 'vue-property-decorator'
import WPBaseButton from './base/WPBaseButton.vue'

@Component({
  components: { WPBaseButton },
})
export default class WPInfoProfileBlock extends Vue {
  @Prop({ default: '' })
  private title?: string

  @Prop({ default: '' })
  private subinfoFirst?: string

  @Prop({ default: '' })
  private subinfoSecond?: string

  @Prop({ default: false })
  private editing?: boolean

  @Prop({ default: '#007db8' })
  private color?: string

  @Prop({ default: 1 })
  private status?: number

  @Prop({ default: false })
  private actual?: boolean

  @Prop({ default: false })
  private doubtful?: boolean

  @Prop({ default: false })
  private versionDifference?: boolean

  private get slots(): boolean {
    return !!Object.keys(this.$slots).length
  }

  private get getStyleBlock() {
    const bg = {
      background: `linear-gradient(white, white) padding-box, linear-gradient(to right, ${String(
        this.color
      )} 60%, ${
        this.doubtful ? 'red' : this.versionDifference ? 'orange' : ''
      }) border-box`,
    }
    return this.status === 2 && (this.doubtful || this.versionDifference)
      ? bg
      : { borderColor: this.color }
  }

  private onClick($event: MouseEvent) {
    this.$emit('click', $event)
  }
}
