import { Component, Vue } from 'vue-property-decorator'

import { Field } from '@/store/field'

@Component
class WellsSearchMixin extends Vue {
  public selectLoadingWells = false

  public searchTextWells: string | undefined = ''

  public get queryWells() {
    return this.searchTextWells ? { name: this.searchTextWells } : undefined
  }

  public async onSearchWells(search = '') {
    this.searchTextWells = search
    this.selectLoadingWells = true
    void Field.CLEAR_DATA('wells')
    await this.onLoadWells()
    this.selectLoadingWells = false
  }

  public async onLoadWells() {
    await Field.GET_CLUSTERS({
      name: this.searchTextWells,
    })
  }
}

export default WellsSearchMixin
