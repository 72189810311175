import axiosInstance from '@/services/api'
import {
  AxiosLoginResponse,
  ILoginData,
  AxiosRegisterResponse,
  IResetPassword,
  IResetPasswordConfirm,
  IRegisterData,
} from '@/types'

import { eventBus, getError } from '@/helpers/eventBus'

export async function login(
  loginData: ILoginData
): Promise<AxiosLoginResponse> {
  try {
    const headers = {
      'Accept-Language':
        loginData.initLang && loginData.initLang === 'en-us'
          ? 'en,ru;q=0.9'
          : 'ru,en;q=0.9',
    }
    const response = await axiosInstance.post<AxiosLoginResponse>(
      '/accounts/jwt/create/',
      loginData,
      {
        headers: headers,
      }
    )

    if (response?.data) {
      return {
        access: response.data.access,
        refresh: response.data.refresh,
      }
    } else return {}
  } catch (error: unknown) {
    eventBus.$emit('showError', getError(error))
    return {}
  }
}

export async function register(
  regData: IRegisterData
): Promise<AxiosRegisterResponse> {
  try {
    const headers = {
      'Accept-Language':
        regData.initLang && regData.initLang === 'en-us'
          ? 'en,ru;q=0.9'
          : 'ru,en;q=0.9',
    }
    const response = await axiosInstance.post<AxiosRegisterResponse>(
      '/accounts/users/',
      regData,
      {
        headers: headers,
      }
    )

    if (response?.data) {
      return {
        id: response.data.id,
        username: response.data.username,
      }
    } else return {}
  } catch (error: unknown) {
    eventBus.$emit('showError', getError(error))
    return {}
  }
}

export async function resetPassword(
  data: IResetPassword
): Promise<IResetPassword | null> {
  try {
    const headers = {
      'Accept-Language':
        data.initLang && data.initLang === 'en-us'
          ? 'en,ru;q=0.9'
          : 'ru,en;q=0.9',
    }
    const response = await axiosInstance.post<IResetPassword>(
      '/accounts/users/reset_password/',
      data,
      {
        headers: headers,
      }
    )
    eventBus.$emit('showAlert', 'resetPass')
    return response?.data
  } catch (error: unknown) {
    eventBus.$emit('showError', getError(error))
    return null
  }
}

export async function resetPasswordConfirm(
  data: IResetPasswordConfirm
): Promise<IResetPasswordConfirm | null> {
  try {
    const headers = {
      'Accept-Language':
        data.initLang && data.initLang === 'en-us'
          ? 'en,ru;q=0.9'
          : 'ru,en;q=0.9',
    }
    const response = await axiosInstance.post<IResetPasswordConfirm>(
      '/accounts/users/reset_password_confirm/',
      data,
      {
        headers: headers,
      }
    )
    eventBus.$emit('showAlert', 'newPass')
    return response?.data
  } catch (error: unknown) {
    eventBus.$emit('showError', getError(error))
    return null
  }
}
