
import { Component, Mixins } from 'vue-property-decorator'
import { Field } from '@/store/field'
import {
  WPList,
  WPOpacity,
  WPCard,
  WPTextfield,
  WPCombobox,
  WPDivider,
  WPSelect,
  WPButton,
} from '@/components'
import {
  ClustersSearchMixin,
  FieldsSearchMixin,
  RouteParamsMixin,
  WellTypesSearchMixin,
} from '@/mixins'

import { ICluster, IWell, IWellTypes } from '@/types'
import { Dictionary } from 'vue-router/types/router'

@Component({
  components: {
    WPCombobox,
    WPSelect,
    WPTextfield,
    WPCard,
    WPOpacity,
    WPList,
    WPDivider,
    WPButton,
  },
})
export default class TheField extends Mixins(
  RouteParamsMixin,
  FieldsSearchMixin,
  ClustersSearchMixin,
  WellTypesSearchMixin
) {
  private selectedWellType: string | number | null | IWellTypes = null

  private wellName = ''

  private selectedWellId = 0

  private selectedCluster: number | ICluster | null = null

  private isAddDialog = false

  private isEdit = false

  private isShownWellList = false

  private busy = false

  private loading = false

  private search = ''

  private filterType: number | null = null

  private filterCluster: number | null = null

  private isFiltersOpened = false

  private get wells() {
    return Field.wells
  }

  private get welltypes() {
    return Field.welltypes
  }

  private get clusters() {
    return Field.clusters
  }

  private get emptyCountWells() {
    return Field.counts.wells === 0
  }

  private get fieldName() {
    if (this.field) {
      return this.field.name
    }
    return ''
  }

  private get title() {
    return this.$t('wells.empty', { msg: this.fieldName })
  }

  private get query() {
    if (this.search || this.filterType || this.filterCluster) {
      let result: Dictionary<string> = {}
      if (this.search) result.name = this.search
      if (this.filterType) result.welltype = String(this.filterType)
      if (this.filterCluster) result.cluster = String(this.filterCluster)
      return result
    } else {
      return undefined
    }
  }

  private openAddDialog() {
    this.wellName = ''
    this.isFiltersOpened = false
    this.isAddDialog = true
  }

  private toggleFilters() {
    this.isFiltersOpened = !this.isFiltersOpened
  }

  private async clearFilter() {
    this.filterType = null
    this.filterCluster = null
    this.isFiltersOpened = false
    await this.confirmFilter()
  }

  private async confirmFilter() {
    void Field.CLEAR_DATA('wells')
    await this.onLoad()
  }

  private async addWell() {
    this.loading = true
    if (this.selectedWellType) {
      let type = 0
      if (typeof this.selectedWellType === 'string') {
        const result = await Field.ADD_WELL_TYPE(this.selectedWellType)
        type = result ? result.id : 0
      } else if (typeof this.selectedWellType === 'number') {
        type = this.selectedWellType
      } else {
        type = this.selectedWellType.id
      }

      let cluster: number | null = null
      if (this.selectedCluster !== null) {
        if (
          typeof this.selectedCluster === 'string' &&
          this.selectedCluster === ''
        ) {
          cluster = null
        } else if (typeof this.selectedCluster === 'string') {
          const result = await Field.ADD_CLUSTER({
            name: this.selectedCluster,
            field: Number(this.fieldId),
          })
          cluster = result && result.id ? result.id : null
        } else if (typeof this.selectedCluster === 'number') {
          cluster = this.selectedCluster
        } else {
          cluster = this.selectedCluster.id || null
        }
      }

      if (this.isEdit) {
        const data: IWell = {
          id: this.selectedWellId,
          name: this.wellName,
          field: Number(this.fieldId),
          welltype: type,
          cluster,
        }
        await Field.SAVE_WELL(data)
      } else {
        const data: IWell = {
          name: this.wellName,
          field: Number(this.fieldId),
          welltype: type,
          cluster,
        }
        await Field.ADD_WELL(data)
      }
      await this.onLoad()
    }
    this.cancel()
    if (this.search) {
      void Field.CLEAR_DATA('wells')
      await this.onSearch(this.search, true)
    }
    this.loading = false
  }

  private cancel() {
    this.isAddDialog = false
    this.selectedWellType = null
    this.wellName = ''
    this.selectedWellId = 0
    this.selectedCluster = null
    this.isEdit = false
  }

  private cancelFilters() {
    this.isFiltersOpened = false
  }

  private async onLoadWellTypes() {
    if (!this.busy) {
      this.busy = true
      await Field.GET_WELL_TYPES()
      this.busy = false
    }
  }

  public async onLoadClusters() {
    if (!this.busy) {
      this.busy = true
      await Field.GET_CLUSTERS({
        field: Number(this.fieldId),
        name: this.searchTextClusters,
      })
      this.busy = false
    }
  }

  private async onLoad() {
    if (!this.busy) {
      this.busy = true
      await Field.GET_WELLS({
        fieldId: Number(this.fieldId),
        query: this.query,
      })
      this.busy = false
    }
  }

  private async onDeleteItem(id: number) {
    await Field.DELETE_WELL(id)
    if (this.search) {
      void Field.CLEAR_DATA('wells')
      await this.onSearch(this.search, true)
    }
  }

  private onEditItem(id: number) {
    const well = this.wells.find((item) => item.id === id)
    if (well) {
      this.selectedWellType = well.welltype
      this.wellName = well.name
      this.selectedWellId = id
      if (well.cluster) this.selectedCluster = well.cluster
      this.isEdit = true
      this.isAddDialog = true
    }
  }

  private async onSearch(val: string, force?: boolean) {
    if (this.search !== val || force) {
      this.search = val
      await this.onLoad()
    }
  }

  private onClickItem(id: string) {
    void Field.CLEAR_DATA('wellbores')
    void this.$router.push({
      name: 'TheWell',
      params: { id: String(this.fieldId), wellId: id },
    })
  }

  private async mounted() {
    if (this.$route.query && this.$route.query.search)
      this.search = String(this.$route.query.search)
    await this.onLoad()
    await Field.GET_WELL_TYPES()
    await Field.GET_CLUSTERS({ field: Number(this.fieldId) })
    this.isShownWellList = true
  }

  private beforeDestroy() {
    if (this.search) void Field.CLEAR_DATA('wells')
  }
}
