
import { Component, Vue } from 'vue-property-decorator'
import WPGroup from './WPGroup.vue'

@Component({
  components: {
    WPGroup,
  },
})
export default class WPGroupMultiline extends Vue {}
