
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class WPButtonIcon extends Vue {
  @Prop({ default: '' })
  private readonly icon?: string

  @Prop({ default: '26' })
  private readonly iconSize?: string

  @Prop({ default: false })
  private readonly ready?: boolean

  @Prop({ default: false })
  private readonly selected?: boolean

  @Prop({ default: false })
  private readonly disabled?: boolean

  @Prop({ default: false })
  private readonly inGroup?: boolean

  @Prop({ default: false })
  private readonly customIcon?: boolean

  @Prop({ default: false })
  private readonly loading?: boolean

  @Prop({ default: true })
  private readonly margin?: boolean

  private get notClickable() {
    return this.disabled || this.loading
  }

  private onClick() {
    if (!this.notClickable) this.$emit('click')
  }
}
