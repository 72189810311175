
import moment from 'moment'

import { Component, Prop, Vue } from 'vue-property-decorator'
import WPDivider from './WPDivider.vue'
import WPButton from './WPButton.vue'

@Component({
  components: {
    WPDivider,
    WPButton,
  },
})
export default class WPBlockHint extends Vue {
  @Prop({ default: '' })
  private date?: string

  private getDate(date: string) {
    return moment.utc(date, 'YYYY-MM-DD[T]HH:mm:ss').format('DD.MM.YYYY, HH:mm')
  }
}
