
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class WPBaseSwitch extends Vue {
  @Prop({ default: false })
  private readonly value?: boolean

  @Prop({ default: '' })
  private readonly left?: string

  @Prop({ default: '' })
  private readonly right?: string

  @Prop({ default: '' })
  private readonly name?: string

  @Prop({ default: false })
  private readonly disabled?: boolean

  @Prop({ default: false })
  private readonly lefttrue?: boolean

  @Prop({ default: false })
  private readonly borderless?: boolean

  private toggle(value: boolean) {
    this.computedValue = value
  }

  private get computedValue() {
    return this.value
  }

  private set computedValue(value) {
    this.$emit('input', value)
  }
}
