
import { Component, Prop, Vue } from 'vue-property-decorator'
import WPDivider from './WPDivider.vue'

@Component({
  components: { WPDivider },
})
export default class WPEmptyList extends Vue {
  @Prop({ default: '' })
  private emptyTitle?: string

  @Prop({ default: false })
  private noPadding?: boolean

  private clickItem(id: string) {
    this.$emit('on-click-item', id)
  }
}
