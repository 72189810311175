
import { Component, Vue } from 'vue-property-decorator'

import { WPAccordion, WPButton, WPLocaleSwitcher, WPLogo } from '@/components'

@Component({
  components: {
    WPLogo,
    WPAccordion,
    WPButton,
    WPLocaleSwitcher,
  },
})
export default class Onboarding extends Vue {
  private activeData = 0

  private get items() {
    return [
      {
        title: this.$t('onboarding.fields'),
        description: this.$t('onboarding.fieldsText'),
        icon: 'field',
      },
      {
        title: this.$t('onboarding.wells'),
        description: this.$t('onboarding.wellsText'),
        icon: 'well',
      },
      {
        title: this.$t('onboarding.calcs'),
        description: this.$t('onboarding.calcsText'),
        icon: 'calc',
      },
      {
        title: this.$t('onboarding.results'),
        description: this.$t('onboarding.resultsText'),
        icon: 'results',
      },
      {
        title: this.$t('onboarding.profile'),
        description: this.$t('onboarding.profileText'),
        icon: 'logo',
      },
    ]
  }

  private get isLast() {
    return this.items.length - 1 === this.activeData
  }

  private goToLogin() {
    void this.$router.push({ name: 'Login' })
  }

  private handleMessage(event: any) {
    try {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-assignment
      const message = JSON.parse(event.data)
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      if (message.type === 'navigateToLogin') {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
        void this.goToLogin()
      }
    } catch (error) {
      return
    }
  }

  private onChange(index?: number) {
    this.activeData = index || 0
  }

  // eslint-disable-next-line @typescript-eslint/require-await
  private async created() {
    window.addEventListener('message', this.handleMessage)
    document.addEventListener('message', this.handleMessage)
  }

  private beforeDestroy() {
    window.removeEventListener('message', this.handleMessage)
    document.removeEventListener('message', this.handleMessage)
  }
}
