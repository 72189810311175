type TValue = string | number | undefined | null

const requiredRule = (val: TValue) => {
  return !val && val !== 0
}

const minRule = (val: TValue, min: number) => {
  const value = Number(val)
  return value < min
}

const maxRule = (val: TValue, max: number) => {
  const value = Number(val)
  return value > max
}

const emailRule = (val: string) => {
  const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
  return !emailPattern.test(val)
}

const zeroRule = (val: TValue) => {
  return val && Number(val) === 0
}

const integerRule = (val: TValue) => {
  const res = Number(val) - Math.round(Number(val))
  return res !== 0
}

export { requiredRule, minRule, maxRule, emailRule, zeroRule, integerRule }
