
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import WPButton from './WPButton.vue'
import WPButtonPlain from './WPButtonPlain.vue'
import WPBlockHint from './WPBlockHint.vue'

@Component({
  components: { WPButtonPlain, WPButton, WPBlockHint },
})
export default class WPCard extends Vue {
  @Prop({ default: '' })
  private title?: string

  @Prop({ default: '' })
  private buttonTitle?: string

  @Prop({ default: true })
  private cancelBtn?: boolean

  @Prop({ default: true })
  private addBtn?: boolean

  @Prop({ default: '' })
  private cancelBtnText?: string

  @Prop({ default: 'mdi-close' })
  private cancelBtnIcon?: string

  @Prop({ default: false })
  private flat?: boolean

  @Prop({ default: false })
  private modal?: boolean

  @Prop({ default: false })
  private disabled?: boolean

  @Prop({ default: false })
  private footer?: boolean

  @Prop({ default: true })
  private isForm?: boolean

  @Prop({ default: false })
  private fullHeight?: boolean

  @Prop({ default: false })
  private fullscreen?: boolean

  @Prop({ default: false })
  private isMobileView?: boolean

  @Prop({ default: '' })
  private width?: string

  @Prop({ default: '' })
  private hint?: string

  private valid = false

  private get getBtnText() {
    return this.buttonTitle ? this.buttonTitle : this.$t('common.addButton')
  }

  private get getCancelBtnText() {
    return this.cancelBtnText ? this.cancelBtnText : this.$t('common.back')
  }

  private get isEmptyCard() {
    return !Object.keys(this.$slots).length
  }

  private onButtonClick() {
    this.$emit('add')
  }

  @Watch('valid', { immediate: true })
  private onChangeValid(val: boolean) {
    this.$emit('valid', val)
  }
}
