import axiosInstance from '@/services/api'
import { IInitSettings } from '@/types'

import { eventBus, getError } from '@/helpers/eventBus'

export async function getInitSettings(): Promise<IInitSettings | null> {
  try {
    const response = await axiosInstance.get<IInitSettings>('/options/')

    if (response?.data) {
      return response?.data
    }

    return null
  } catch (error: unknown) {
    eventBus.$emit('showError', getError(error))
    return null
  }
}
