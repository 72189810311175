import { Component, Vue } from 'vue-property-decorator'

import { Field } from '@/store/field'

@Component
class WellTypesSearchMixin extends Vue {
  public selectLoadingTypes = false

  public searchTextTypes: string | null = null

  public get queryTypes() {
    return this.searchTextTypes ? { name: this.searchTextTypes } : undefined
  }

  public async onSearchTypes(search = '') {
    this.searchTextTypes = search
    this.selectLoadingTypes = true
    void Field.CLEAR_DATA('welltypes')
    await this.onLoadTypes()
    this.selectLoadingTypes = false
  }

  public async onLoadTypes() {
    await Field.GET_WELL_TYPES(this.queryTypes)
  }
}

export default WellTypesSearchMixin
