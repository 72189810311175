
import { Component, Mixins } from 'vue-property-decorator'
import { WPCard } from '@/components'
import { RouteParamsMixin } from '@/mixins'

import 'echarts-gl'
import { VisualMapComponent } from 'echarts/components'
import VChart from 'vue-echarts'
import { Wellbore } from '@/store/wellbore'
import { IProfileGraphData } from '@/types/wellbore'
import { use } from 'echarts/core'

use([VisualMapComponent])

type TGraphData = (string | number | boolean)[][]

@Component({
  components: {
    WPCard,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    VChart,
  },
})
export default class TheWellboreProfileGraph extends Mixins(RouteParamsMixin) {
  public wellboreSvg = ''

  private isMobileView = false

  private option: any = null

  private getTextStatus(status: number, actual: boolean, doubtful: boolean) {
    let result = ''
    if (status === 1) {
      result = actual
        ? String(this.$t('wellboreProfile.actual'))
        : String(this.$t('wellboreProfile.notactual'))
    } else if (status === 2) {
      result = doubtful
        ? String(this.$t('wellboreProfile.unreliable'))
        : String(this.$t('wellboreProfile.truthful'))
    }
    return `${result}\n`
  }

  private async prepareData() {
    let result: TGraphData = []
    const data =
      (await Wellbore.GET_PROFILE_GRAPH(Number(this.wellboreId))) || []

    // let min = 999999999
    data.map((item: IProfileGraphData) => {
      const typeText =
        item.status === 1
          ? String(this.$t('wellboreProfile.graph.future'))
          : item.status === 2
          ? String(this.$t('wellboreProfile.graph.fact'))
          : ''
      result.push([
        item.depth,
        item.zenith,
        item.azimuth,
        item.x,
        item.y,
        item.z,
        typeText,
        item.status,
        item.actual,
        item.doubtful,
      ])
      // if (item.z < min) min = item.z
    })
    this.option = {
      grid3D: {
        // boxWidth: 100,
        // boxDepth: 100,
        // boxHeight: 50,
      },
      xAxis3D: {
        name: '',
        // min: Math.round(min),
        // max: Math.round(min * -1),
        axisLabel: {
          show: false,
        },
        axisPointer: {
          label: {
            formatter: (x: any) => {
              // eslint-disable-next-line @typescript-eslint/no-unsafe-return
              return x
            },
          },
        },
      },
      yAxis3D: {
        name: '',
        // min: Math.round(min),
        // max: Math.round(min * -1),
        axisLabel: {
          show: false,
        },
        axisPointer: {
          label: {
            formatter: (x: any) => {
              // eslint-disable-next-line @typescript-eslint/no-unsafe-return
              return x
            },
          },
        },
      },
      zAxis3D: {
        name: this.$t('wellboreProfile.graph.depth'),
        axisPointer: {
          label: {
            formatter: (x: any) => {
              // eslint-disable-next-line @typescript-eslint/no-unsafe-return
              return x
            },
          },
        },
      },
      visualMap: {
        type: 'piecewise',
        categories: [
          this.$t('wellboreProfile.graph.future'),
          this.$t('wellboreProfile.graph.fact'),
        ],
        dimension: 6,
        orient: 'horizontal',
        top: 0,
        left: 'center',
        inRange: {
          color: ['#0094d9', '#009639'],
        },
        outOfRange: {
          color: '#ddd',
          opacity: 0.3,
        },
      },
      series: [
        {
          type: 'scatter3D',
          smooth: true,
          symbolSize: 5,
          data: result,
          dimensions: [
            'Depth',
            'Zenith',
            'Azimuth',
            'X',
            'Y',
            'Z',
            'Type',
            'Status',
            'Actual',
            'Doubtful',
          ],
          label: {
            formatter: (param: { data: TGraphData }) => {
              return [
                this.getTextStatus(
                  Number(param.data[7]),
                  !!param.data[8],
                  !!param.data[9]
                ),
                `${String(this.$t('wellboreProfile.depthMini'))}: ${String(
                  param.data[0]
                )} м\n`,
                `${String(this.$t('wellboreProfile.zenith'))}: ${String(
                  param.data[1]
                )} °\n`,
                `${String(this.$t('wellboreProfile.azimuth'))}: ${String(
                  param.data[2]
                )} °\n`,
              ].join('')
            },
            position: 'right',
            fontSize: 14,
            color: '#000',
            fontWeight: 'bold',
          },
          encode: {
            x: 'X',
            y: 'Y',
            z: 'Z',
          },
        },
        {
          type: 'line3D',
          data: result,
          connectNulls: true,
          dimensions: [
            'Depth',
            'Zenith',
            'Azimuth',
            'X',
            'Y',
            'Z',
            'Type',
            'Status',
            'Actual',
            'Doubtful',
          ],
          lineStyle: {
            width: 2,
          },
          encode: {
            x: 'X',
            y: 'Y',
            z: 'Z',
          },
        },
      ],
    }
  }

  private cancel() {
    void this.$router.go(-1)
  }

  private mounted() {
    if (this.$route.query?.viewtype === 'mobile') {
      this.isMobileView = true
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
      ;(window as any).ReactNativeWebView.postMessage(
        JSON.stringify({ isLoaded: true })
      )
    } else if (!this.$route.query?.viewtype) {
      this.isMobileView = false
    }

    void this.prepareData()
  }
}
