
import { Component, Prop, Vue } from 'vue-property-decorator'
import WPBaseSwitch from './base/WPBaseSwitch.vue'

@Component({
  components: { WPBaseSwitch },
})
export default class WPSwitch extends Vue {
  @Prop({ default: false })
  private readonly value?: boolean

  @Prop({ default: '' })
  private readonly left?: string

  @Prop({ default: '' })
  private readonly right?: string

  @Prop({ default: '' })
  private readonly name?: string

  @Prop({ default: false })
  private readonly disabled?: boolean

  @Prop({ default: false })
  private readonly lefttrue?: boolean

  @Prop({ default: false })
  private readonly borderless?: boolean
}
