
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class WPBaseRadio extends Vue {
  @Prop({ default: false })
  private disabled?: boolean

  @Prop({ default: '' })
  private readonly label?: string

  @Prop({ default: '' })
  private readonly id?: string

  @Prop({ default: '' })
  private readonly name?: string

  @Prop({ default: null })
  private readonly value?: string | number
}
