
import { Component, Prop, Mixins } from 'vue-property-decorator'
import WPBaseCombobox from './base/WPBaseCombobox.vue'
import { ValidationMixin } from '@/mixins'

@Component({
  components: { WPBaseCombobox },
})
export default class WPCombobox extends Mixins(ValidationMixin) {
  @Prop({ default: () => [] })
  private readonly items?: Record<string, any>[]

  @Prop({ default: '' })
  public readonly value?: string

  @Prop({ default: '' })
  private readonly label?: string

  @Prop({ default: '' })
  private readonly hint?: string

  @Prop({ default: '' })
  private readonly placeholder?: string

  @Prop({ default: false })
  private readonly white?: boolean

  @Prop({ default: '' })
  private readonly itemText?: string

  @Prop({ default: '' })
  private readonly itemValue?: string

  @Prop({ default: false })
  private readonly clearable?: boolean

  @Prop({ default: 'auto' })
  private readonly hideDetails?: string | boolean

  @Prop({ default: true })
  private readonly outlined?: boolean

  @Prop({ default: false })
  private readonly disabled?: boolean

  @Prop({ default: false })
  private readonly isMeasure?: boolean

  @Prop({ default: false })
  private readonly loading?: boolean

  @Prop({ default: true })
  private readonly returnObject?: boolean

  @Prop({ default: false })
  private readonly readonly?: boolean

  @Prop({ default: false })
  private readonly disableTyping?: boolean

  private get computedValue() {
    return this.value
  }

  private set computedValue(value) {
    this.$emit('input', value)
  }

  private onLoadItems() {
    this.$emit('onLoadItems')
  }
}
