import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule,
} from 'vuex-module-decorators'
import Vue from 'vue'

import store from '@/store'

import {
  calculateVolume,
  calculateStrength,
  getCalculations,
  getCalculation,
  getCalculators,
  getWedgegrips,
  getKzp,
  getConstructionInfo,
  getReportFile,
  getEquipInfo,
  toggleShared,
  getChart,
  getScheme,
  getCalculationsRepeat,
  getTechOperations,
  createWedgegrip,
  saveWedgegrip,
  getEquipmentsetInfo,
} from '@/services/calculators'

import { getPage } from '@/services/common'

import {
  TCalculatorParams,
  TCalculatorResults,
  TCalculators,
  TCalculatorLoadedResults,
  TCalculationVariant,
  TWedgegrips,
  ICalcStrengthInputDataForm,
  ICalcVolumeInputDataForm,
  TTechOperations,
  IWedgegrip,
  TBooleanKeys,
  TChartResults,
  TCalculationVariantShare,
} from '@/types'

import { Dictionary } from 'vue-router/types/router'

export interface ICalculatorState {
  selectedCalculator: string
  calculations: TCalculatorResults
  calculationsLoaded: TCalculatorLoadedResults
  calculators: TCalculators
  wedgegrips: TWedgegrips
}

interface ICounts {
  calculations: number | null
  wedgegrips: number | null
  calculators: number | null
}

type TCounts = 'calculations' | 'wedgegrips' | 'calculators'

@Module({ dynamic: true, store, name: 'calculator' })
class CalculatorState extends VuexModule implements ICalculatorState {
  public selectedCalculator = ''

  public calculations: TCalculatorResults = []

  public calculationsLoaded: TCalculatorLoadedResults = []

  public calculators: TCalculators = []

  public wedgegrips: TWedgegrips = []

  public techOperations: TTechOperations = []

  public chart: TChartResults = []

  public counts: ICounts = {
    calculations: null,
    wedgegrips: null,
    calculators: null,
  }

  public inputData:
    | ICalcStrengthInputDataForm
    | ICalcVolumeInputDataForm
    | null = null

  @Mutation
  public SetSelectedCalculator(calculator: string) {
    this.selectedCalculator = calculator
  }

  @Mutation
  public SetCalculations(data: TCalculatorResults) {
    data.map((item) => {
      this.calculations.push(item)
    })
  }

  @Mutation
  public SetWedgegrips(data: TWedgegrips) {
    data.map((item) => {
      this.wedgegrips.push(item)
    })
  }

  @Mutation
  public SetWedgegrip(data: IWedgegrip) {
    this.wedgegrips.push(data)
  }

  @Mutation
  public SaveWedgegrip(data: IWedgegrip) {
    const index = this.wedgegrips.findIndex((item) => item.id === data.id)
    Vue.set(this.wedgegrips, index, data)
  }

  @Mutation
  public SetLoadedCalculation(data: TCalculationVariant) {
    this.calculationsLoaded.push(data)
  }

  @Mutation
  public SaveLoadedCalculation(data: TCalculationVariant) {
    const index = this.calculationsLoaded.findIndex(
      (item) => item.id === data.id
    )
    Vue.set(this.calculationsLoaded, index, data)
  }

  @Mutation
  public SaveSharedCalculation(data: TCalculationVariantShare) {
    const index = this.calculations.findIndex((item) => item.id === data.id)
    Vue.set(this.calculations, index, {
      ...this.calculations[index],
      shared: data.shared,
    })
  }

  @Mutation
  public SetCalculators(data: TCalculators) {
    this.calculators = data
  }

  @Mutation
  public SetTechOperations(data: TTechOperations) {
    this.techOperations = data
  }

  @Mutation
  public SetInputData(
    data: ICalcStrengthInputDataForm | ICalcVolumeInputDataForm | null
  ) {
    this.inputData = data
  }

  @Mutation
  public SaveChart(result: TChartResults) {
    this.chart = result
  }

  @Mutation
  public SetCalcCount(data: { type: TCounts; count: number | null }) {
    this.counts[data.type] = data.count
  }

  @Mutation
  public ClearCalcData(data: TCounts) {
    this[data] = []
  }

  @Mutation
  public ClearCalcState() {
    this.calculations = []
    this.calculationsLoaded = []
    this.calculators = []
    this.wedgegrips = []
    this.counts.calculations = null
    this.counts.wedgegrips = null
  }

  @Action
  public SET_SELECTED_CALCULATOR(name: string) {
    this.SetSelectedCalculator(name)
  }

  @Action
  public async CALCULATE_VOLUME(data: {
    params: TCalculatorParams
    inputData: ICalcVolumeInputDataForm | null
  }) {
    const result = await calculateVolume(data)
    return result?.id
  }

  @Action
  public async CALCULATE_STRENGTH(data: {
    params: TCalculatorParams
    inputData: ICalcStrengthInputDataForm | null
    settings: TBooleanKeys
  }) {
    if (data.inputData) {
      const result = await calculateStrength(data)
      return result?.id
    }
    return 0
  }

  @Action
  public async GET_CALCULATORS(query?: Dictionary<string>) {
    if (!this.calculators.length) {
      const result = await getCalculators(query)
      this.SetCalculators(result)
    }
  }

  @Action
  public async GET_CALCULATIONS(data: {
    clear?: boolean
    query?: Dictionary<string>
  }) {
    const { clear, query } = data
    const page = clear
      ? 1
      : getPage(this.counts.calculations, this.calculations.length)

    if (page !== null) {
      const calculations = await getCalculations(page, query)
      if (clear) void this.CLEAR_DATA('calculations')
      this.SetCalcCount({ type: 'calculations', count: calculations.count })
      if (calculations.results) {
        this.SetCalculations(calculations.results)
      }
    }
  }

  @Action
  public async RELOAD_CALCULATIONS() {
    await this.GET_CALCULATIONS({ clear: true })
  }

  @Action
  public async GET_CALCULATIONS_REPEAT(query?: TCalculatorParams) {
    const result = await getCalculationsRepeat(query)
    return result
  }

  @Action
  public async GET_CALCULATION(id: string) {
    const loaded = this.calculationsLoaded.find(
      (item) => String(item.id) === id
    )
    if (loaded) {
      return loaded
    } else {
      const result = await getCalculation(id)
      if (result) this.SetLoadedCalculation(result)
      return result
    }
  }

  @Action
  public async GET_WEDGE_GRIPS(query?: Dictionary<string>) {
    const page = getPage(this.counts.wedgegrips, this.wedgegrips.length)

    if (page !== null) {
      const wedgegrips = await getWedgegrips(page, query)
      this.SetCalcCount({ type: 'wedgegrips', count: wedgegrips.count })
      if (wedgegrips.results) {
        this.SetWedgegrips(wedgegrips.results)
      }
    }
  }

  @Action
  public async CREATE_WEDGE_GRIP(data: IWedgegrip) {
    const result = await createWedgegrip(data)
    if (result) this.SetWedgegrip(result)
    return result
  }

  @Action
  public async SAVE_WEDGE_GRIP(data: IWedgegrip) {
    const result = await saveWedgegrip(data)
    if (result) this.SaveWedgegrip(result)
    return result
  }

  @Action
  public async GET_KZP(data: {
    base: string | null
    drilling_way: string | null
    profile: string | null
    drilling_conditions: string | null
  }) {
    return await getKzp(data)
  }

  @Action
  public async GET_REPORT_FILE(id: number) {
    const file = await getReportFile(id)
    return file
  }

  @Action
  public async GET_CONSTRUCTION_INFO(wellbore: number) {
    const result = await getConstructionInfo(wellbore)
    return result
  }

  @Action
  public async GET_EQUIPMENTSET_INFO(data: {
    wellbore: number
    equip: number
  }) {
    const result = await getEquipmentsetInfo(data.wellbore, data.equip)
    return result
  }

  @Action
  public async GET_EQUIPMENT_INFO(equip: number) {
    const result = await getEquipInfo(equip)
    return result
  }

  @Action
  public async TOGGLE_LOADED_SHARED(data: TCalculationVariant) {
    const result = await toggleShared(data)
    if (result) this.SaveLoadedCalculation({ ...data, ...result })
  }

  @Action
  public async TOGGLE_SHARED(data: TCalculationVariantShare) {
    const result = await toggleShared(data)
    if (result) this.SaveSharedCalculation({ ...data, ...result })
  }

  @Action
  public async GET_CHART(id: string) {
    const result = await getChart(id)
    return result
  }

  @Action
  public SAVE_CHART(result: TChartResults) {
    this.SaveChart(result)
  }

  @Action
  public async GET_SCHEME(id: string) {
    const result = await getScheme(id)
    return result
  }

  @Action
  public async GET_TECH_OPERATIONS() {
    const result = await getTechOperations()
    if (result) this.SetTechOperations(result)
  }

  @Action
  public SET_INPUT_DATA(
    data: ICalcStrengthInputDataForm | ICalcVolumeInputDataForm
  ) {
    this.SetInputData(data)
  }

  @Action
  public CLEAR_INPUT_DATA() {
    this.SetInputData(null)
  }

  @Action
  public CLEAR_CALC_STATES() {
    this.ClearCalcState()
  }

  @Action
  public CLEAR_DATA(data: TCounts) {
    this.SetCalcCount({ type: data, count: null })
    this.ClearCalcData(data)
  }
}

export const Calculator = getModule(CalculatorState)
