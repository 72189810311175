import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule,
} from 'vuex-module-decorators'
import store from '@/store'

import { IField, IWell, IWellbore } from '@/types'

export interface ICommonState {
  field: IField | null
  well: IWell | null
  wellbore: IWellbore | null
  downloadLink: string
  appLoaded: boolean
}

@Module({ dynamic: true, store, name: 'common' })
class CommonState extends VuexModule implements ICommonState {
  public field: IField | null = null

  public well: IWell | null = null

  public wellbore: IWellbore | null = null

  public downloadLink = ''

  public appLoaded = false

  @Mutation
  public SetCommonField(data: IField | null) {
    this.field = data
  }

  @Mutation
  public SetCommonWell(data: IWell | null) {
    this.well = data
  }

  @Mutation
  public SetCommonWellbore(data: IWellbore | null) {
    this.wellbore = data
  }

  @Mutation
  public SetDownloadLink(data: string) {
    this.downloadLink = data
  }

  @Mutation
  public SetAppLoaded(data: boolean) {
    this.appLoaded = data
  }

  @Mutation
  public ClearCommonState() {
    this.field = null
    this.well = null
    this.wellbore = null
  }

  @Action
  public SET_COMMON_FIELD(data: IField | null) {
    void this.SetCommonField(data)
  }

  @Action
  public SET_COMMON_WELL(data: IWell | null) {
    void this.SetCommonWell(data)
  }

  @Action
  public SET_COMMON_WELLBORE(data: IWellbore | null) {
    void this.SetCommonWellbore(data)
  }

  @Action
  public SET_DOWNLOAD_LINK(data: string) {
    void this.SetDownloadLink(data)
  }

  @Action
  public SET_APP_LOADED(data: boolean) {
    void this.SetAppLoaded(data)
  }

  @Action
  public CLEAR_COMMON_STATES() {
    this.ClearCommonState()
  }
}

export const Common = getModule(CommonState)
