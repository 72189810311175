
import { Component, Prop, Vue } from 'vue-property-decorator'
import WPBaseButton from './base/WPBaseButton.vue'

@Component({
  components: { WPBaseButton },
})
export default class WPButton extends Vue {
  @Prop({ default: false })
  private outlined?: boolean

  @Prop({ default: false })
  private disabled?: boolean

  @Prop({ default: false })
  private fab?: boolean

  @Prop({ default: false })
  private loading?: boolean

  private timer = 0

  private onButtonClick($event: MouseEvent) {
    this.timer = setTimeout(() => {
      this.$emit('click', $event)
    }, 0)
  }

  private beforeDestroy() {
    void clearTimeout(this.timer)
  }
}
