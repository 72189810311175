
import { Component, Vue, Prop } from 'vue-property-decorator'
import { WPTextInfo, WPBlockInfo, WPMiniHeader, WPDivider } from '@/components'

import { TItemColumnComposition } from '@/types'

@Component({
  components: {
    WPTextInfo,
    WPBlockInfo,
    WPMiniHeader,
    WPDivider,
  },
})
export default class ResultsCalculationElements extends Vue {
  @Prop({ type: Array, required: true })
  private elements?: TItemColumnComposition
}
