
import { Component, Vue, Watch } from 'vue-property-decorator'

import customProtocolCheck from '@/helpers/customProtocolCheck'

import { Auth } from '@/store/auth'
import { Unit } from '@/store/units'
import { Calculator } from '@/store/calculators'
import { User } from '@/store/user'
import { Common } from '@/store/common'
import { Init } from '@/store/init'

import {
  WPBreadcrumbs,
  WPAlert,
  WPLocaleSwitcher,
  WPHeader,
  WPDialog,
} from '@/components/'

import { eventBus } from '@/helpers/eventBus'
import { IErrorObj, IWellboreTypes } from './types'

const DEFAULT_SUCCESS_TEXT = 'saved'
const DEFAULT_FAIL_TEXT = 'fail'

interface IDialog {
  visible?: boolean
  title: string
  text: string
  btnDismissText: string
  btnAcceptText: string
}

@Component({
  name: 'App',
  components: {
    WPHeader,
    WPBreadcrumbs,
    WPAlert,
    WPLocaleSwitcher,
    WPDialog,
  },
})
export default class App extends Vue {
  private alert = {
    visible: false,
    type: '',
    text: '',
    status: 0,
  }

  private dialog: IDialog = {
    visible: false,
    title: '',
    text: '',
    btnDismissText: '',
    btnAcceptText: '',
  }

  private get selectedFieldName(): string {
    return this.selectedField ? this.selectedField.name : ''
  }

  private get selectedWellName(): string {
    return this.selectedWell ? this.selectedWell.name : ''
  }

  private get selectedWellboreName(): string {
    return this.selectedWellbore ? this.selectedWellbore.name : ''
  }

  private get selectedWellboreTypeName(): string {
    return this.selectedWellbore &&
      this.selectedWellbore.activate_integration &&
      this.selectedWellbore.status
      ? this.getWellboreTypeName(this.selectedWellbore.status)
      : ''
  }

  private get selectedField() {
    return Common.field
  }

  private get selectedWell() {
    return Common.well
  }

  private get selectedWellbore() {
    return Common.wellbore
  }

  private get selectedCalculator(): string {
    return Calculator.selectedCalculator
  }

  private get isCalculatorsPage(): boolean {
    return (
      this.$route.name !== 'Calculators' &&
      this.$route.name !== 'CalculatorsEmpty' &&
      !!this.$route.name?.includes('Calculators')
    )
  }

  private get isCalculatorsResultsPage(): boolean {
    return this.$route.name === 'ResultsCalculation'
  }

  private get getTheme(): string {
    return User && User.settings ? String(User.settings.theme) : ''
  }

  private get isRouteParamsPage(): boolean {
    const pages = [
      'TheField',
      'TheWell',
      'TheWellbore',
      'TheWellboreMain',
      'TheWellboreParams',
      'TheWellboreProfile',
      'TheWellboreIntervals',
      'TheWellboreEquip',
      'TheWellboreVisualization',
      'CalculatorsConstructionSpaceVolumes',
      'CalculatorsEquipmentStrengthService',
    ]
    return pages.includes(String(this.$route.name))
  }

  private get authorized() {
    return Auth.isAuthentication
  }

  private changeDialogVisible(value: boolean) {
    this.dialog.visible = value
  }

  private getWellboreTypeName(code: number) {
    return this.statuses.find((item) => item.code === code)?.display_name || ''
  }

  private get statuses(): IWellboreTypes[] {
    return [
      {
        code: 1,
        name: 'Static',
        display_name: String(this.$t('wellbore.activation.staticWellbore')),
      },
      {
        code: 2,
        name: 'Dynamic',
        display_name: String(this.$t('wellbore.activation.dynamicWellbore')),
      },
      {
        code: 3,
        name: 'Fact',
        display_name: String(this.$t('wellbore.activation.factWellbore')),
      },
    ]
  }

  private showDialog(value: IDialog) {
    this.dialog = {
      visible: true,
      ...value,
    }
  }

  private showAlert(value = DEFAULT_SUCCESS_TEXT, type = 'success') {
    let msg = ''
    const valSplitted = value.split(' | ')
    if (valSplitted.length > 1) {
      const key = valSplitted[0]
      const msgT = valSplitted[1]
      msg = String(this.$t(`alert.texts.${key}`, { msg: msgT }))
    } else {
      msg = String(this.$t(`alert.texts.${value}`))
    }
    this.alert = {
      visible: true,
      type: type,
      text: msg,
      status: 200,
    }
  }

  private showError(value: IErrorObj | null | string) {
    if (value) {
      let msg = DEFAULT_FAIL_TEXT
      let status = 0

      if (typeof value === 'string') {
        const valSplitted = value.split(' | ')

        if (valSplitted.length > 1) {
          const key = valSplitted[0]
          const msgT = valSplitted[1]

          msg = String(this.$t(`alert.errors.${key}`, { msg: msgT }))
        } else {
          msg = String(this.$t(`alert.errors.${value}`))
        }
      } else {
        if (value.message) msg = value.message
        if (value.data && typeof value.data !== 'string') {
          const errors = Object.values<any>(value.data)
          let res: string[] = []
          errors.forEach((error) => {
            if (typeof error === 'string') res.push(error)
            if (typeof error === 'object' && !Array.isArray(error))
              res.push((error as IErrorObj).message)
            if (typeof error === 'object' && Array.isArray(error)) {
              const msgObj = error
                .map((a: IErrorObj | string) => {
                  if (typeof a === 'object' && a.message) return a.message
                  else return a
                })
                .join(' ')
              res.push(msgObj)
            }
          })
          msg = res.join(' ')
        }
        if (value.status) status = value.status
      }

      this.alert = {
        visible: true,
        type: 'error',
        text: msg,
        status: status,
      }
    }
  }

  private toField() {
    if (this.$route.name !== 'TheField')
      void this.$router.push({
        name: 'TheField',
        params: {
          id: String(this.selectedField?.id),
        },
      })
  }

  private toWell() {
    if (this.$route.name !== 'TheWell')
      void this.$router.push({
        name: 'TheWell',
        params: {
          id: String(this.selectedField?.id),
          wellId: String(this.selectedWell?.id),
        },
      })
  }

  private toWellbore() {
    if (this.$route.name !== 'TheWellbore')
      void this.$router.push({
        name: 'TheWellbore',
        params: {
          id: String(this.selectedField?.id),
          wellId: String(this.selectedWell?.id),
          wellboreId: String(this.selectedWellbore?.id),
        },
      })
  }

  private closeAlert() {
    this.alert.visible = false
  }

  private logout(redirect?: string) {
    if (this.authorized) {
      Auth.LOGOUT()
      this.$i18n.locale = 'ru-ru'
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
      void this.$theme.set('blue')
      void this.$router.push({
        name: redirect ? 'Login' : 'Onboarding',
        query: redirect ? { redirect, ...this.$route.query } : {},
      })
    }
  }

  private goTo404() {
    void Common.CLEAR_COMMON_STATES()
    void this.$router.push({ name: 'PageNotFound' })
  }

  private goTo403() {
    if (!this.alert.visible) {
      const url = window.location.href
      eventBus.$emit('showError', `noPermission | ${url}`)
    }
    void Common.CLEAR_COMMON_STATES()
    void this.$router.push({ name: 'Fields' })
  }

  private changeTheme(val: string) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    this.$theme.set(val)
  }

  @Watch('isRouteParamsPage', {
    immediate: true,
  })
  private onChangeRoute(val: boolean) {
    if (val === false) Common.CLEAR_COMMON_STATES()
  }

  private created() {
    eventBus.$on('showAlert', (val: string) => this.showAlert(val))
    eventBus.$on('showInfo', (val: string) => this.showAlert(val, 'info'))
    eventBus.$on('showWarning', (val: string) => this.showAlert(val, 'warning'))
    eventBus.$on('showError', (val: IErrorObj | null | string) =>
      this.showError(val)
    )
    eventBus.$on('closeAlert', () => this.closeAlert())
    eventBus.$on('changeTheme', (val: string) => this.changeTheme(val))
    eventBus.$on('unauthorized', () => this.logout(this.$route.path))
    eventBus.$on('pageNotFound', () => this.goTo404())
    eventBus.$on('pageNoPermission', () => this.goTo403())
    eventBus.$on('showDialog', (val: IDialog) => this.showDialog(val))

    if (this.$route.query?.viewtype !== 'mobile') {
      if (
        /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        ) ||
        navigator.appVersion.includes('iPhone') ||
        navigator.appVersion.includes('iPad') ||
        navigator.appVersion.includes('Android')
      ) {
        const params =
          this.$route.name === 'ResultsCalculation'
            ? `/results/${this.$route.params?.calcId}`
            : String(this.$route.query?.redirect).includes('results')
            ? String(this.$route.query?.redirect)
            : ''

        const runlink = `wellpro:/${params}`
        const downloadlink = navigator.appVersion.includes('Android')
          ? 'https://play.google.com/'
          : 'https://www.apple.com/ru/app-store/'

        customProtocolCheck(
          runlink,
          () => {
            Common.SET_APP_LOADED(false)
            Common.SET_DOWNLOAD_LINK(downloadlink)
          },
          () => {
            Common.SET_APP_LOADED(true)
            Common.SET_DOWNLOAD_LINK(runlink)
          },
          2000,
          () => {
            Common.SET_APP_LOADED(true)
            Common.SET_DOWNLOAD_LINK(downloadlink)
          }
        )
      }
    }
  }

  private async mounted() {
    while (!this.$t) {}

    if (!this.$route.meta?.guest) {
      await Promise.all([
        Unit.GET_UNITS(true),
        Unit.GET_UNITTYPES(true),
        User.GET_SETTINGS(true),
        User.GET_USER_DATA(true),
        Init.GET_INIT_SETTINGS(),
      ])

      this.$i18n.locale = User.settings.language
        ? User.settings.language
        : 'ru-ru'
    } else {
      this.$i18n.locale = localStorage.getItem('lang') || 'ru-ru'
    }
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    this.$theme.set(User.settings.theme)
  }
}
