var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.correctType)?_c('div',{staticClass:"wp-info-block",class:{
    'wp-info-block_editing': _vm.editing,
  }},[_c('div',{staticClass:"wp-info-block__main",class:{
      'wp-info-block__main_group': _vm.slots,
      'wp-info-block__main_disabled': _vm.disabled,
    },on:{"click":_vm.onClick}},[(_vm.type === 'element' && _vm.color)?_c('div',{staticClass:"wp-info-block__symbols"},[(_vm.symbols)?_c('div',{key:_vm.symbolsKey,staticClass:"wp-info-block__symbol"},[_vm._v(" "+_vm._s(_vm.symbols.up)+" ")]):_vm._e(),_c('div',{staticClass:"wp-info-block__index wp-info-block__index_color"},[_c('span',{staticClass:"wp-info-block__color",style:({ background: _vm.color })}),_c('span',{staticClass:"wp-info-block__number wp-info-block__number_color"},[_vm._v(" "+_vm._s(_vm.index)+" ")])]),(_vm.symbols)?_c('div',{staticClass:"wp-info-block__symbol"},[_vm._v(" "+_vm._s(_vm.symbols.down)+" ")]):_vm._e()]):_c('div',{staticClass:"wp-info-block__index"},[_c('span',{staticClass:"wp-info-block__number"},[_vm._v(" "+_vm._s(_vm.index)+" ")])]),_c('div',{staticClass:"wp-info-block__content",class:{
        'wp-info-block__content_only-title': !(
          _vm.subtitle ||
          _vm.subinfoFirst ||
          _vm.subinfoSecond
        ),
      }},[_c('div',{staticClass:"wp-info-block__title"},[(_vm.icon)?_vm._t("icon"):_vm._e(),_c('span',[_vm._v(_vm._s(_vm.title))])],2),_c('div',{staticClass:"wp-info-block__sub-info"},[_c('span',[_vm._v(" "+_vm._s(_vm.subinfoFirst)+" ")]),_c('span',[_vm._v(" "+_vm._s(_vm.subinfoSecond)+" ")])]),(_vm.type === 'equip')?_c('div',{staticClass:"wp-info-block__subtitle"},[_vm._v(" "+_vm._s(_vm.subtitle)+" ")]):_vm._e(),(_vm.isCased)?_c('div',{staticClass:"wp-info-block__cased-wrapper"},[_c('span',{staticClass:"wp-info-block__cased"},[_vm._v(_vm._s(_vm.$t('intervals.cased')))])]):_vm._e(),(_vm.subinfoFooter && _vm.subinfoFooter.length)?_c('div',{staticClass:"wp-info-block__sub-info-footer"},_vm._l((_vm.subinfoFooter),function(item,index){return _c('div',{key:`subinfo-${index}`,staticClass:"wp-info-block__sub-info-footer-line"},[_c('span',[_vm._v(" "+_vm._s(item.text)+" ")]),_c('span',[_vm._v(" "+_vm._s(item.value)+" ")])])}),0):_vm._e()])]),(_vm.slots)?_c('div',{staticClass:"wp-info-block__slot"},[_vm._t("default")],2):_vm._e()]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }