
import { Component, Mixins, Prop } from 'vue-property-decorator'
import WPBaseSwitchItem from './base/WPBaseSwitchItem.vue'
import WPBaseMultiSwitchItem from './base/WPBaseMultiSwitchItem.vue'
import { ValidationMixin } from '@/mixins'

type TValueType = string | number | undefined

@Component({
  components: { WPBaseSwitchItem, WPBaseMultiSwitchItem },
})
export default class WPRadioSwitch extends Mixins(ValidationMixin) {
  @Prop({ default: null })
  public readonly value?: TValueType

  @Prop({ default: '' })
  public readonly label?: string

  @Prop({ default: '' })
  private readonly name?: string

  @Prop({ default: false })
  private readonly borderless?: boolean

  @Prop({ default: false })
  private readonly multiple?: boolean

  @Prop({ default: () => [] })
  private readonly items?: any[]

  private onInput(val: string | number) {
    this.$emit('input', val)
  }
}
