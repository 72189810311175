
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class WPButtonFileIcon extends Vue {
  @Prop({ default: '' })
  private readonly icon?: string

  @Prop({ default: false })
  private readonly disabled?: boolean

  @Prop({ default: false })
  private readonly xls?: boolean

  private onClick() {
    if (!this.disabled) this.$emit('click')
  }
}
